import {problemGenerators} from '../../services/problemGenerators';

// Returns the ProblemBlock, given the key
export const getProblemBlockByKey = (key, problemBlockStateSlice) => {
    const id = problemBlockStateSlice.keys_to_ids[key]
    return problemBlockStateSlice.problemBlocks[id]
}
// Returns the ProblemBlockInstance, given the key
export const getProblemBlockInstanceByKey = (key, problemBlockInstanceStateSlice) => {
    const id = problemBlockInstanceStateSlice.keys_to_ids[key]
    return problemBlockInstanceStateSlice.problemBlockInstances[id]
}

export const getMainGeneratorByInstanceKey = (key, problemBlockInstanceStateSlice) => {
    const id = problemBlockInstanceStateSlice.keys_to_ids[key]
    const generatorName = "main." + id
    const g = problemGenerators.retrieveGenerator(generatorName)
    return g
}

export const getSampleGeneratorByInstanceKey = (key, problemBlockInstanceStateSlice) => {
    const id = problemBlockInstanceStateSlice.keys_to_ids[key]
    const generatorName = "sampler." + id
    const g = problemGenerators.retrieveGenerator(generatorName)
    return g
}

export const getLayoutByInstanceKey = (key, problemBlockInstanceStateSlice) => {
    const id = problemBlockInstanceStateSlice.keys_to_ids[key]
    const blockInstance = problemBlockInstanceStateSlice.problemBlockInstances[id]
    return blockInstance.layout
}