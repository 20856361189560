import React from 'react';
import 'typeface-roboto';
import PropTypes from 'prop-types';
import {POSITION_IN_PARENT} from '../../constants/layoutconstants';
import {renderers} from '../../services/renderers';

const placeEquation = (id, eqX, eqY, itemHeight, itemWidth, renderer, layout) => {
	const eq_inner = renderer.svg;
	const {position_eq_in_parent, capGrowth, capGrowthMaximumFontPointSize} = layout;
	const renderer_fontSizeInPoints = renderer.layout_constants["fontSizeInPoints"];
    let eqW, eqH;
    
    const actual_h_w_ratio = renderer.hw_ratio;

	// I want to scale based on height if the equation looks narrower than the box it is in
	//   and scale based on the Width of my box if the equation is flatter than the box
	if (actual_h_w_ratio > itemHeight / itemWidth) {
		eqH = itemHeight * 0.75;
		eqW = eqH / actual_h_w_ratio;
		//eqH = eqH * 1.1; // Make the viewing box a little taller because otherwise the bottom of commas get cut off
		// Due to letters hanging down under the bottom of the SVG "line box" (e.g.: comma, q, g, p)
	} else {
		eqW = itemWidth * 0.75;
		eqH = actual_h_w_ratio * eqW //* 1.1;
	}

	// However, if they have capGrowth and capGrowthToFontPointSize set
	//   that means we limit the max scaling size of the object to try to cap it at 
	//   the point size indicated by the renderer
	if (capGrowth && typeof capGrowthMaximumFontPointSize === "number" 
		&& typeof renderer_fontSizeInPoints === "number") {
			const maxWidth = renderer.width * (capGrowthMaximumFontPointSize / renderer_fontSizeInPoints);
			if (eqW > maxWidth) {
				const squishBy = (maxWidth / eqW)
				eqW = eqW * squishBy;
				eqH = actual_h_w_ratio * eqW * 1.1;
			}
		}

	const extraX = itemWidth - eqW;
	const extraY = itemHeight - eqH;
	let nudgeX = 0;
	let nudgeY = 0;
	switch (position_eq_in_parent) {
		case POSITION_IN_PARENT.TOPLEFT:
			break;
		case POSITION_IN_PARENT.TOPMIDDLE:
			nudgeX = extraX / 2;
			break;
		case POSITION_IN_PARENT.TOPRIGHT:
			nudgeX = extraX;
			break;
		case POSITION_IN_PARENT.CENTERLEFT:
			nudgeY = extraY / 2;
			break;
		case POSITION_IN_PARENT.CENTERMIDDLE:
			nudgeY = extraY / 2;
			nudgeX = extraX / 2;
			break;
		case POSITION_IN_PARENT.CENTERRIGHT:
			nudgeY = extraY / 2;
			nudgeX = extraX;
			break;
		case POSITION_IN_PARENT.BOTTOMLEFT:
			nudgeY = extraY;
			break;
		case POSITION_IN_PARENT.BOTTOMMIDDLE:
			nudgeY = extraY;
			nudgeX = extraX / 2;
			break;
		case POSITION_IN_PARENT.BOTTOMRIGHT:
			nudgeY = extraY;
			nudgeX = extraX;
			break;
		default: 
			break;
	}
	//if (process.env.NODE_ENV === 'development') console.log("position_eq_in_parent ",position_eq_in_parent)
	//if (process.env.NODE_ENV === 'development') console.log("nudgeX: ",nudgeX)
	//if (process.env.NODE_ENV === 'development') console.log("nudgeY: ",nudgeY)
	//const nudgeY = 0;//(itemHeight - eqH) / 2;
	//const nudgeX = (itemWidth - eqW) / 2;

	const eq = (
		<svg
			key={'arszpk2-eq-' + id}
			x={eqX + nudgeX}
			y={eqY + nudgeY}
			width={eqW}
			height={eqH}
			style={{ marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0, padding: 0, display: 'block' }}
		>
			{eq_inner}
		</svg>
	);

	return eq;
};


const WorksheetComponent = (props) => {
//	if (process.env.NODE_ENV === 'development') console.log("Props to WorksheetComponent:  ",props)
	const {problem_renderer_name} = props;
	const {rows, columns, drawProblemBorders} = props.layout_data;
	//const drawProblemBorders = true; //*&*

	const {problems} = props;
	
	// Get the problem renderer for this block from its name
	if (!renderers.isRendererInitialized(problem_renderer_name))
		return <div/>

	const problem_renderer = renderers.getRenderer(problem_renderer_name)
	//console.log("Render class: ",theRendererClass)
	//const problem_renderer = theRendererClass()
	// We want to be as big as possible without any dimension exceeding these, in which case
	//   we want to pad the other dimension
//	const {containerAspectRatio_H_over_W } = props;
	//const {blockHeight, blockWidth} = props;

	if (!Array.isArray(problems) || problems.length !== rows*columns) {
		if (process.env.NODE_ENV === 'development') console.log("Problems: ",problems)
		if (process.env.NODE_ENV === 'development') console.log("Wrong number of problems: "+problems.length.toString(10)+" Expected: " + (rows*columns).toString(10))
		return null;
	}
	const {layout_data: layout} = props;
	const renderer = new problem_renderer();
	renderer.show_answers = layout["showAnswers"];

	//const layout_constants = renderer.layout_constants;
	// Height of entire block for this component
	const blockHeight = layout.heightFullPage || (!layout.vbHeight) ? props.blockHeight : layout.vbHeight;
	// Width of entire block for this component
	const blockWidth = layout.widthFullPage || (!layout.vbWidth) ? props.blockWidth : layout.vbWidth;
    //const standardized_hw_ratio = layout_constants.typicalHeight / layout_constants.typicalWidth;
	//const worksheetComponentWidth = layout_constants.typicalWidth;
	//const worksheetComponentHeight = standardized_hw_ratio * worksheetComponentWidth;

	//if (process.env.NODE_ENV === 'development') console.log("blockWidth, blockHeight, worksheetComponentWidth, worksheetComponentHeight: ", 
	//     blockWidth, blockHeight, worksheetComponentWidth, worksheetComponentHeight)

	let borderWidth = 0;
	if (layout["blockBackground"]) borderWidth = layout["blockBackgroundBorder"];

	const innerBlockHeight = blockHeight - (layout.padTop + layout.padBottom) 
	const innerBlockWidth = blockWidth - (layout.padLeft + layout.padRight)

	const itemHeight = (innerBlockHeight) / rows;
	const itemWidth = (innerBlockWidth) / columns;

	const placeX = (r, c) => {
		//if (process.env.NODE_ENV === 'development') console.log("itemWidth,r, c, padH, padW1 ", itemWidth,r, c, padH, padW1)
		return itemWidth * (c - 1) + layout.padLeft 
	};

	const placeY = (r, c) => {
		return itemHeight * (r - 1) + layout.padTop 
    };
    
	const makeRec = (row, col) => {
		const keyText = row.toString(10) + '-' + col.toString(10);
		const o = (
			<rect
				key={'arszpk-rect-' + keyText}
				id={'rect-' + keyText}
				data-row={row.toString(10)}
				data-col={col.toString(10)}
				x={placeX(row, col).toString(10)}
				y={placeY(row, col).toString(10)}
				width={itemWidth.toString(10)}
				height={itemHeight.toString(10)}
				style={{
					stroke: '#000000',
					fillOpacity: '0.0',
					strokeWidth: 1
				}}
			/>
		);
		return o;
	};

	const makeRecs = () => {
		
		let myGrid = [];
		for (let i = 1; i <= rows; i++) {
			for (let j = 1; j <= columns; j++) {
                const id= i.toString(10) + '-' + j.toString(10);
                // Make the next problem
                renderer.problem = problems[columns*(i-1) + j - 1]
				if (drawProblemBorders) myGrid.push(makeRec(i, j));
				myGrid.push(placeEquation(
                    id,
                    placeX(i,j), 
                    placeY(i,j), 
                    itemHeight,
                    itemWidth,
					renderer,
					layout
                ));
			}
		}
		return myGrid;
	};



	return (
		<svg
			//style={{ display: 'block', margin: 'auto' }}
			width="100%"
			viewBox={`0 0 ${blockWidth} ${blockHeight}`} // + (100 * containerAspectRatio_H_over_W).toString(10)}
		>
			{borderWidth>0  && 
				<rect x="0" y="0" width="100%" height="100%" ry="10" rx="10" style={{
						strokeWidth: borderWidth,
						stroke: layout["blockBackgroundBorderColor"],
						fill: layout["blockBackgroundColor"],
						fillOpacity: layout["blockBackgroundOpacity"]
					}}
				/>
			}
			
			{makeRecs()}
		</svg>
	);
		/*<rect x="0" y="0" width="100%" height="100%" fill="lightblue" fillOpacity="80%"/> */
};

WorksheetComponent.propTypes = {
        /**
         * Array of problems
         */
        problems: PropTypes.any.isRequired,
        /**
         * The renderer function for this type of problem
         */
        problem_renderer_name : PropTypes.string.isRequired,
	};



export default WorksheetComponent 
