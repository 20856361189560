import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';


// Prep move to Redux 
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider as ReactReduxProvider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

// My sagas and reducers
import rootSaga from '../../redux/sagas/rootSaga'
import rootReducer from '../../redux/reducers/index'

// For material-ui
import { MuiThemeProvider } from '@material-ui/core/styles';

// Snackbar
import { SnackbarProvider } from 'notistack';

// Styled Components
import styled from 'styled-components';


// Delayer
import Delay from '../utils/Delay';

import ChangePasswordPage from '../AuthPages/changepassword'
import ResetPasswordPage from '../AuthPages/resetpassword'
import TermsOfServicePage from '../TermsOfService'
import AuthPage from '../AuthPages/authpage'


//import ArithmeticAdd from '../MathPages/ArithmeticAdd'
import Addition from '../MathPages/Addition'
import Subtraction from '../MathPages/Subtraction'
import Multiplication from '../MathPages/Multiplication'
import Workshop from '../Workshop/Workshop';

//import Navigate from '../Navigate';  // new navigation

// /comp/AuthPages/authpage';

import HomePage from '../Home';
import About from '../Home/About';

import * as routes from '../../constants/routes';
import GA from '../../GoogleAnalytics';

// SETTING UP REDUX
import { connect } from 'react-redux'
import { getTheme, getUiHello, getUiGoodbye } from '../../redux/selectors/uiSelectors'
import { getAuthStatus } from '../../redux/selectors/authSelectors'

// Set up sagas
const sagaMiddleware = createSagaMiddleware()
const enhancers = composeWithDevTools(
  applyMiddleware(sagaMiddleware)
)
const store = createStore(rootReducer, enhancers)
sagaMiddleware.run(rootSaga)



class AppChild extends React.Component {


  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  render() {

    const backgroundColor = this.props.ui_theme.palette.grey[100];


    const StyledAppDiv = styled.div`
      background: ${backgroundColor};
      min-height: 250px;
      min-width: 250px;
      height: '100%'
      width: '100%'
      position: absolute;
      top: 0;
      left: 0;
      overflow:auto;`

    // if (this.props.authStatus===FETCH_STATUS.UNINITIALIZED || this.props.authStatus===FETCH_STATUS.PENDING ) {
    if (this.props.uiHello === 0 && this.props.uiGoodbye === 0) {
      return (
        <Delay wait={100}>
          <p>Loading...</p>
        </Delay>
      )
    }

    return (
      <MuiThemeProvider theme={this.props.ui_theme}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <StyledAppDiv>
              {GA.init() && <GA.RouteTracker />}
              <Switch>
                <Route exact path={routes.SIGN_IN} component={() => <AuthPage />} />
                <Route exact path={routes.HOME} component={() => <HomePage />} />
                <Route exact path={routes.ABOUT} component={() => <About />} />
                <Route exact path={routes.TERMS_OF_SERVICE} component={() => <TermsOfServicePage />} />
                <Route exact path={routes.CHANGE_PASSWORD} component={() => <ChangePasswordPage />} />
                <Route exact path={routes.PASSWORD_FORGET} component={() => <ResetPasswordPage />} />
                <Route exact path={routes.ARITHMETIC_ADD} component={() => <Addition />} />
                <Route exact path={routes.ARITHMETIC_SUBTRACT} component={() => <Subtraction />} />
                <Route exact path={routes.ARITHMETIC_MULTIPLY} component={() => <Multiplication />} />
                <Route exact path={routes.WORKSHOP} component={() => <Workshop />} />
                <Redirect from="/" to={routes.HOME} exact />
              </Switch>
            </StyledAppDiv>

          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  uiHello: getUiHello(state),
  uiGoodbye: getUiGoodbye(state),
  ui_theme: getTheme(state),
  authStatus: getAuthStatus(state),
})

const mapDispatchToProps = {}

AppChild =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppChild);


export const App = () => {
  return (
    <ReactReduxProvider store={store}>
      <AppChild />
    </ReactReduxProvider>
  );
};
