import React, { Component } from 'react';
import { connect } from 'react-redux'
import { login } from '../../redux/actions'
import { FETCH_STATUS } from '../../constants/store'

import 'typeface-roboto';
//import styled from 'styled-components';
import Button from '@material-ui/core/Button';
//import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGooglePlusSquare } from '@fortawesome/free-brands-svg-icons'


class GoogleButton extends Component {
	onSignInWithGoogle = () => {
                this.props.login()
        }
        
        button_disabled = () => {
                return (this.props.auth.status === FETCH_STATUS.PENDING || this.props.auth.data.loggedIn)
        }

	render() {
		return (
                        <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        onClick={this.onSignInWithGoogle}
                        disabled={this.button_disabled()}
                        >
                                <FontAwesomeIcon icon={faGooglePlusSquare} size='2x' style={{color:"#DB4437" }} />
                                &nbsp; &nbsp;
                                <Typography variant="button" gutterBottom={false}>Log In With Google</Typography>
                        </Button>
		)
	}
}


const mapStateToProps = (state) => ({
        auth : state.auth
    })
    
const mapDispatchToProps = { login }

export default 
        connect(
                mapStateToProps,
                mapDispatchToProps
)(GoogleButton);

