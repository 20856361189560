import produce from 'immer'
import { types } from '../actions'
import { FETCH_STATUS } from '../../constants/store'

const initialState = {
    status: FETCH_STATUS.UNINITIALIZED, 
    error : null,
    data: {
        renderers : {},  // Dict of initialized renderers, by KEY
    }
}

const reducer = (state = initialState , action) => {
  let newState;
  switch (action.type) {
    case types.INITIALIZE_RENDERER.REQUEST:
        return {
            ...state,
            status: FETCH_STATUS.PENDING, 
        } 
    case types.INITIALIZE_RENDERER.SUCCESS:
        /* 
            This manages:
                - Add a totally new ProblemBlock
                - Update an existing ProblemBlock
                - Manage key/id dictionary for ProblemBlocks
                - Validates all ProblemBlock object updates against schema            
        */
         newState = produce(state, draft => {
             draft.data.renderers[action.payload.key] = {
                isInitialized : true,
                ...action.payload
            }
            draft.status = FETCH_STATUS.READY
            draft.error = null
        })
        //console.log("   newState after update: ",JSON.stringify(newState))
        return newState;
    case types.USER_GOODBYE:
        return initialState
    default:
        return state;
  }
}

export default reducer;

