import React from 'react';
import 'typeface-roboto';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormTextField from './JSONFormFields/JSONFormTextField'

// For context, because this object get remounted by its parents
//import hash from 'object-hash'
import { withRouter } from 'react-router-dom';


export const styles = (theme) => ({
    mainCard: {
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //color: 'white',
        padding: 0,
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
    },
    root: {
        flexGrow: 1,
    },
    rootMobile: {
        flexGrow: 1, //height: '100%',
    },
    textFieldRoot: {
        //marginTop: 0,
        padTop: 0,
       // padLeft: 20,
       // padRight: 20,
//        marginBottom: 30,
        marginTop: 10,
        marginBottom: 10,
        //width: '100%',
        minWidth: 100
    },
    fieldDivRoot: {
        marginLeft: 20,
        marginRight: 20
    },
    paperRoot: {
        padding: 16,
    },
    paperCardRoot: {
        maxWidth: 400,
    },
    paperCardMain: {
        padding:0
    },
    labelRoot: {
        fontWeight: "bold",
        color: "black",
        width: '100%',
        shrink: false
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    actions: {
        display: 'flex',
        //backgroundColor : theme.palette.grey["200"],
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0
        }
      }
})


const SettingsForm = props => {
    //console.log("Settings Form props: ", props);
    const {
        classes,
        //values: { name, email, password, confirmPassword },
        //errors,
        //touched,
        //handleChange,
        isValid,
        isValidating,
        isSubmitting,
        dirty,
        //setFieldTouched
    } = props;

    const { formData } = props;

    /*
    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };*/

    const toggleDetailSettingsVisible = (e) => {
        if (typeof props.toggleDetailSettingsVisible) {
            props.toggleDetailSettingsVisible()
        } else
        {
            console.log("No toggleDetailSettingsVisible available")
        }
        e.preventDefault()
    }

    let expanded = (typeof props.detailSettingsVisible==="boolean") ? props.detailSettingsVisible : true

//    console.log("LOCATION: ",props.location.key)
//    console.log("   KEY: ",props.key)
//    console.log("   PROPS: ",props)
//    console.log("   KEY or Location: ",props.key || props.location.pathname)
/*
    const contextHash = hash({
        location: props.location.pathname,
        formData: props.formData
    })
    */
//    console.log("contextHash: ",contextHash)

    return (
        <form onSubmit={props.handleSubmit} onBlur={props.handleBlur} >
        <Card elevation={0} classes={{ root: classes.mainCard}}> 
            
            <CardContent className={classes.cardcontent}>
                    <Grid container spacing={2} direction="row" alignItems="flex-start" justify="flex-start"  >
                        {
                            formData.questions.map(q => (
                                <FormTextField id={"main_question_"+q.name} key={q.name} q={q} {...props} />
                            ))
                        }

                        {
                            expanded && formData.moreQuestions && (
                                formData.moreQuestions.map(q => (
                                    <FormTextField id={q.name} key={q.name} q={q} {...props} />
                                ))
                            )
                        }
                    </Grid>
                </CardContent>
                { (formData.moreQuestions || dirty) &&
                    <CardActions className={classes.actions} disableSpacing>
                        {dirty &&
                            <Button 
                                aria-label="Apply new settings"
                                fullWidth 
                                size="medium" 
                                variant="contained" 
                                color="primary" 
                                type="submit" 
                                disabled={!dirty || isSubmitting || isValidating || !isValid || (props.readonly)}
                                style={{marginRight: 15}}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />  &nbsp; Apply New Settings
                            </Button>
                        }
                        { formData.moreQuestions &&
                            <Tooltip title={expanded ? "Show fewer options" : "Show more options"} aria-label="More options">
                                <IconButton
                                    key="expandButton"
                                    id="expandButton"
                                    className={classnames(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}
                                    onClick={toggleDetailSettingsVisible}
                                    aria-expanded={expanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </CardActions>
                }
               
            
        </Card>
        </form>

    );
};

export default withRouter(withStyles(styles)(SettingsForm));


// ProblemPage = withRouter(withStyles(styles)(SettingsForm));

/*

        <form onSubmit={props.handleSubmit} onBlur={props.handleBlur} >
            <Grid container spacing={2} direction="row" alignItems="flex-start" justify="flex-start"  >
                {
                    formData.questions.map(q => (
                        <FormTextField id={q.name} key={q.name} q={q} {...props} />
                    ))
                }
                {
                    formData.moreQuestions && (
                        <ExpansionPanel id="more-questions">
                            <ExpansionPanelSummary id="more-questions-summary" expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>Advanced options</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Grid container spacing={2} direction="row" alignItems="flex-start" justify="flex-start" id="grid-in-more-questions" >
                            {
                                formData.moreQuestions.map(q => (
                                     <FormTextField id={q.name} key={q.name} q={q} {...props} />
                                ))
                            }
                            </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                }


                {dirty &&
                    <Grid item xs={12} >
                        <Button fullWidth size="medium" variant="contained" color="primary" type="submit" disabled={!dirty || isSubmitting || isValidating || !isValid}>
                            <FontAwesomeIcon icon={faPaperPlane} />  &nbsp; Apply New Settings
                                </Button>
                    </Grid>
                }
            </Grid>
        </form>

    */

