const prodConfig = {
    apiKey: "AIzaSyDQbrlOp1pZ88YboNpywv-nnIySd_0GOjY",
    authDomain: "worksheet-workshop.firebaseapp.com",
    databaseURL: "https://worksheet-workshop.firebaseio.com",
    projectId: "worksheet-workshop",
    storageBucket: "worksheet-workshop.appspot.com",
    messagingSenderId: "301079141325"
  };
  
  
  /*
  // Configure Firebase.
  const devConfig = {
    apiKey: process.env.REACT_APP_DEV_APIKEY,
    authDomain: process.env.REACT_APP_DEV_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASEURL,
    projectId: process.env.REACT_APP_DEV_PROJECTID,
    storageBucket: process.env.REACT_APP_DEV_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGINGSENDERID,
  };

export const config = process.env.NODE_ENV === 'production'
  ? prodConfig
: devConfig;

*/

export const config = prodConfig