// Credit: https://github.com/richardhealy/react-redux-saga-firebase/blob/master/src/reducers/index.js
import { types } from '../actions'
//import { strings } from '../strings';
import { FETCH_STATUS } from '../../constants/store'
import { defaultPrimaryColors, defaultSecondaryColors } from '../../logic/uiLogic';

const initialState = {
    status: FETCH_STATUS.UNINITIALIZED, 
    error : null,
    session : {
        hello: 0,
        goodbye: 0,
    },
    data: {
        uid: null,
        primaryColors: defaultPrimaryColors, 
        secondaryColors: defaultSecondaryColors,
        defaultPageLayout : null,
    }
}

const uiReducer = (state = initialState , action) => {
  switch (action.type) {
    case types.UI_HELLO:
            return {
                ...state,
                status: FETCH_STATUS.READY,
                error: null,
                session: {
                    ...state.session,
                    hello: state.session.hello + 1
                }
            }
    case types.SYNC_UI.SUCCESS:
        return {
            status: FETCH_STATUS.READY,
            error: null,
            session: state.session,
            data: action.payload
        }
    case types.USER_GOODBYE:
        return {
            ...initialState,
            status: FETCH_STATUS.READY, 
            session : {
                ...state.session,
                goodbye: state.session.goodbye + 1,
            }
        }
    case types.RESET_UI:
        return initialState
    default:
        return state
  }
}

export default uiReducer
