import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: theme.palette.grey[500],
    },
    img: {
        maxWidth: "100%",
        height: "auto"
    },
    LowerCardContent : {
        backgroundColor : theme.palette.grey["200"],
    }
});

class HelpCard extends React.Component {
    state = { expanded: false };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { classes } = this.props;
        const { description, icon, image, details, expansionDetails } = this.props;

        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="Info" className={classes.avatar}>
                            {icon}
                        </Avatar>
                    }
                    action={
                        <IconButton
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="More info"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    }
                    title={description}
                />

                {(details || expansionDetails || image) &&
                    <React.Fragment>
                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        {details &&
                                <CardContent className={classes.LowerCardContent}>
                                    <Typography component="p">
                                        {details}
                                    </Typography>
                                </CardContent>
                            }
                            {image &&
                                <CardContent>
                                    {image}
                                </CardContent>
                            }

                            {expansionDetails &&
                                <CardContent >
                                <div style={{backgroundColor: 'yellow'}} >
                                    <Typography paragraph>{expansionDetails}</Typography>
                                    </div>
                                </CardContent>
                            }
                        </Collapse>
                    </React.Fragment>
                }
            </Card>
        );
    }
}

HelpCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpCard);

/*
        <CardMedia
          className={classes.media}
          image={image}
          title={imageTitle}
        />
        */