// Credit to https://github.com/richardhealy/react-redux-saga-firebase/blob/master/src/sagas/index.js

//import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import { call, put, take } from 'redux-saga/effects';
//import { push } from 'react-router-redux';

import {
  //types,
  loginSuccess,
  loginFailure,
  loginWithEmailSuccess,
  loginWithEmailFailure,
  logoutSuccess,
  logoutFailure,
  syncUser,
  registerWithEmailSuccess,
  registerWithEmailFailure,
  passwordChangeSuccess,
  passwordChangeFailure,
  passwordForgetSuccess,
  passwordForgetFailure,
  userGoodbye,
  userHello,
} from '../actions';

//import * as routes from '../constants/routes';

import rsf from '../../firebase/rsf';

const authProvider = new firebase.auth.GoogleAuthProvider()

export function *logoutSaga () {
  try {
    const data = yield call(rsf.auth.signOut);
    yield put(logoutSuccess(data));
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

export function *loginSaga () {
  try {
    const data = yield call(rsf.auth.signInWithPopup, authProvider)
    yield put(loginSuccess(data));
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export function *loginWithEmailSaga ({payload}) {
  try {
    const data = yield call(rsf.auth.signInWithEmailAndPassword, payload.email, payload.password);
    yield put(loginWithEmailSuccess(data));
  } catch (error) {
    yield put(loginWithEmailFailure(error));
  }
}

export function *registerWithEmailSaga ({payload}) {
  try {
    const data = yield call(rsf.auth.createUserWithEmailAndPassword, payload.email, payload.password);
    yield put(registerWithEmailSuccess(data));
  } catch (error) {
    yield put(registerWithEmailFailure(error));
  }
}

export function* passwordChangeSaga({payload}) {
  const password = payload.password
  try {
    console.log("update password. Password: ",password)
    yield call(rsf.auth.updatePassword, password);
    yield put(passwordChangeSuccess());
  }
  catch(error) {
    console.log("Password change error: ",error)
    yield put(passwordChangeFailure(error));
  }
}

export function* passwordForgetSaga({payload}) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, payload.email);
    yield put(passwordForgetSuccess());
  }
  catch(error) {
    yield put(passwordForgetFailure(error));
  }
}

export function *syncUserSaga () {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { user } = yield take(channel);

    if (user) {
      yield put(syncUser(user)); 
      yield put(userHello());
    } else {
      yield put(syncUser(null));
      yield put(userGoodbye());
    } 
  }
}


