import { select, take, put } from 'redux-saga/effects';
import { FETCH_STATUS } from '../../constants/store'
import { getNewKey } from '../../firebase/getNewKey'
import { problemGenerators } from '../../services/problemGenerators';

import {
    types,
    setProblemBlockSuccess,
    setProblemBlockReady,
    setProblemBlockInstanceSuccess,
    setProblemBlockInstance,
} from '../actions';


//   import rsf from '../../firebase/rsf';


export function* setProblemBlockSaga(action) {
    // Trigger: types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST
    // The reducer also takes this action and sets FETCH_STATUS to pending

    // Async process can be placed here

    // Push updates
    yield put(setProblemBlockSuccess(action.payload))

    // Now we should also refresh all ProblemBlockInstances
    const blockData = yield select(state => (state.problemBlocks.data))
    const blockId = blockData.keys_to_ids[action.payload.key]
    const thisBlock = blockData.problemBlocks[blockId]

    // If there are instances
    if (thisBlock["instances"] && thisBlock["instances"].length > 0) {
        // Loop through instances
        for (let item of thisBlock["instances"]) {
            // const instanceTask = yield put(setProblemBlockInstance({ id: item }))
            yield put(setProblemBlockInstance({ id: item }))
        }
    }

    // Block child updates are complete - mark FETCH_STATUS as READY
    yield put(setProblemBlockReady())
}


// Update an existing ProblemBlockInstance
export function* updateProblemBlockInstanceSaga(action) {
    //console.log("updateProblemBlockInstanceSaga action: ", action)

    // Wait for any ProblemBlock transactions to finish up before initializing a new instance
    while (yield select(state => (state.problemBlocks.status !== FETCH_STATUS.READY))) {
        yield take(types.PROBLEM_BLOCK_SET.READY)
    }

    // Initialize a flag to trigger generating new problems
    let needNewProblems = false
    let needNewSample = false

    // Get the id for the instance
    let id = action.payload.id;
    if (!id && action.payload.key) {
        id = yield select(state => (state.problemBlockInstances.data.keys_to_ids[action.payload.key]))
    }
    if (!id) {
        console.log("Action yielding error: ", action)
        throw new Error("No id or valid key provided")
    }

    // Initialize data for the success action creator
    let successData = { id: id }
    if (action.payload.key)
        successData["key"] = action.payload.key;

    // Get the instance and the block
    const instance = yield select(state => (state.problemBlockInstances.data.problemBlockInstances[id]))
    const block = yield select(state => (state.problemBlocks.data.problemBlocks[instance.problemBlockId]))

    // Have the generator settings changed? Compare between the block that owns this instance and the
    //   relevant generator in the generators singleton
    if (!problemGenerators.generatorSettingsMatchTheseSettings("main." + id, block.generator_settings)) {
        //console.log("NEW generator settings")
        problemGenerators.newGenerator("main." + id, block.problem_type, block.generator_settings);
        problemGenerators.newGenerator("sampler." + id, block.problem_type, block.generator_settings);
        needNewProblems = true
        needNewSample = true
    }

    // Determine the new problemCount
    const problemCount = (typeof action.payload.problemCount === "number") ? action.payload.problemCount : instance.problemCount

    // If the problem count changed we'll need to regenerate our problems and add the new info to the action creator
    if (problemCount !== instance.problemCount) {
        needNewProblems = true;
        successData["problemCount"] = problemCount
    }

    // Regenerate problems if needed
    if (needNewProblems) {
        let mainGenerator = problemGenerators.retrieveGenerator("main." + id, block.problem_type)
        successData["problems"] = newProblems(mainGenerator, problemCount)
    } else {
        // Check if metadata flags request that we randomize the problem set
        if (action.metadata && action.metadata.randomizeProblems) {
            let mainGenerator = problemGenerators.retrieveGenerator("main." + id, block.problem_type)
            successData["problems"] = newProblems(mainGenerator, problemCount)
        }
    }

    // Regenerate sample if needed
    if (needNewSample) {
        let sampleGenerator = problemGenerators.retrieveGenerator("sampler." + id, block.problem_type)
        successData["sample"] = sampleGenerator.next()
    } else {
        // Check if metadata flags request new sample problem
        if (action.metadata && action.metadata.newSampleProblem) {
            let sampleGenerator = problemGenerators.retrieveGenerator("sampler." + id, block.problem_type)
            successData["sample"] = sampleGenerator.next()
        }
    }

    // If a new layout object is provided, merge it with the old one, then add it to the action creator JSON
    if (typeof action.payload.layout === "object")
        successData["layout"] = Object.assign({}, instance.layout, action.payload.layout)

    // console.log("successData in updateProblemBlockInstanceSaga: ",successData)

    yield put(setProblemBlockInstanceSuccess(successData))
}


// Initialize a ProblemBlockInstance
export function* initializeProblemBlockInstanceSaga(action) {

    // Wait for any ProblemBlock transactions to finish up before initializing a new instance
    while (yield select(state => (state.problemBlocks.status !== FETCH_STATUS.READY))) {
        yield take(types.PROBLEM_BLOCK_SET.READY)
    }

    // Get the problemBlockInstances slice from state
    const pbi = yield select(state => state.problemBlockInstances)
    // If there's already a PBI with this key, QUIT
    if (pbi.data.keys_to_ids[action.metadata.key]) {
        //console.log("PBI already initialized")
        return;
    }

    // Find the ProblemBlock based on Key or Id provided in the action
    const pb = yield select(state => state.problemBlocks)
    const blockId = action.metadata.problemBlockId ?
        action.metadata.problemBlockId :
        pb.data.keys_to_ids[action.metadata.problemBlockKey]
    const thisPb = pb.data.problemBlocks[blockId]
    if (!thisPb) {
        throw new Error ("problemBlockSagas - no ProblemBlock object found for new Instance")
    }

    // Create a key for the Instance
    let theId = getNewKey();

    // Create problem generators for the Instance: a main generator and a sampler
    let gen = problemGenerators.getGenerator("main." + theId, thisPb.problem_type, thisPb.generator_settings)
    let genSampler = problemGenerators.getGenerator("sampler." + theId, thisPb.problem_type, thisPb.generator_settings)
    // Generate new problems 
    const problems = newProblems(gen, action.payload.problemCount)
    // Generate the first sample problem
    const sampleProblem = genSampler.next()

    yield put(
        setProblemBlockInstanceSuccess(
            {
                id: theId,
                problems: problems,
                problemBlockId: thisPb.id,
                key: action.metadata.key,
                sample: sampleProblem,
                layout: action.payload.layout,
                problemCount: action.payload.problemCount
            }
        ))
}

// Make a new set of problems
const newProblems = (generator, count) => {
    if (!generator) throw new Error('No generator passed to newProblems');
    let a = [];
    for (let i = 1; i <= count; i++) {
        a.push(generator.next());
    }
    return a;
};

