import isEqual from 'lodash.isequal';
import Addition from '../MathStuff/math_generators/basic_addition';
import Subtraction from '../MathStuff/math_generators/basic_subtraction';
import Multiplication from '../MathStuff/math_generators/basic_multiplication';

class ProblemGenerator {
    constructor() {
        this._generators = {}
        this._errorHandlers = {}
        this._errors = {}
        this._generator_settings = {}
    }

    reset = () => {
        this._generators = {}
        this._errorHandlers = {}
        this._errors = {}
        this._generator_settings = {}
    }

    generatorSettingsMatchTheseSettings = (key, new_generator_settings) => {
        return (this._generators[key] && isEqual(this._generator_settings[key], new_generator_settings))
    }

    // Rebuilds and returns generator even if one with that key already exists
    //   unless the existing generator has the same settings as
    //   the one that's getting passed in
    newGenerator = (key, problem_type, generator_settings) => {
        if (this._generators[key] && !isEqual(this._generator_settings[key], generator_settings)) {
            delete this._generators[key]
        }
        return this.getGenerator(key, problem_type, generator_settings)
    }

    // Retrieve, but don't initialize if it does not exist
    retrieveGenerator  = (key) => {
        if(!this._generators[key]) {
            console.log("Generator not found. Generators: ",this._generators)
        }
        return this._generators[key]
    }

    // Get generator, initializing it if it does not exist
    getGenerator = (key, problem_type, initial_settings) => {
        let g = this._generators[key]
        if (g) {
            //console.log("Return existing generator")
            return g
        }

        //console.log("CREATING generator: ",initial_settings)
        //console.log("   key: ",key)

        this._generator_settings[key] = initial_settings
        const h = (error_data) => {
            return this.handleProblemGeneratorError(key, error_data)
        }
        this._errors[key] = null
        this._errorHandlers[key] = h
        switch (problem_type) {
			case 'ADDITION':
				g = new Addition(initial_settings, h);
				break;
			case 'SUBTRACTION':
				g = new Subtraction(initial_settings, h);
				break;
			case 'MULTIPLICATION' :
				g = new Multiplication(initial_settings, h);
				break;
			default:
				throw new Error('Unknown problem type: ' + (problem_type || 'undefined'))
		}
        this._generators[key] = g
        return this._generators[key];
    }

    handleProblemGeneratorError = (key, error_data) => {
		this._errors[key] = error_data;
		if (process.env.NODE_ENV === 'development') console.log(`Logged errors for problem generator ${key}: `, error_data);
	};
}
export let problemGenerators = new ProblemGenerator();

