import produce from 'immer'
import { types } from '../actions'
import { FETCH_STATUS } from '../../constants/store'
import { getNewKey } from '../../firebase/getNewKey'

import { ProblemBlockInstanceSchemav2 } from '../schemas';

/* 
    ProblemBlocks are metadata that can be used to 
    generate a block of problems. 
*/

const initialState = {
    status: FETCH_STATUS.UNINITIALIZED, 
    error : null,
    data: {
        problemBlockInstances : {},  // Dict of blocks instances, by ID
        keys_to_ids : {},  // Get the ID from the key
    }
}

const reducer = (state = initialState , action) => {
  let theId;  
  let thisObject;
  let theKey;
  switch (action.type) {
    case types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST:
        return {
            ...state,
            status: FETCH_STATUS.PENDING, 
        } 
    case types.PROBLEM_BLOCK_INSTANCE_SET.PROBLEM_BLOCK_INSTANCE_INITIALIZE:
            return {
                ...state,
                status: FETCH_STATUS.PENDING, 
            } 
    case types.PROBLEM_BLOCK_INSTANCE_SET.SUCCESS:
        /* 
            This manages:
                - Add a totally new ProblemBlock
                - Update an existing ProblemBlock
                - Manage key/id dictionary for ProblemBlocks
                - Validates all ProblemBlock object updates against schema            
        */
        const newState = produce(state, draft => {
            //console.log("Success reducer. Action: ",action)
            //console.log("  Draft: ",JSON.stringify(draft))

            // If a key is provided in the action, see if there's an ID for it already
            if (action.payload.key) {
                theKey = action.payload.key;
                theId = state.data.keys_to_ids[action.payload.key]
            }

            // If no ID yet, see if there's an ID provided in the action
            if (theId === undefined && action.payload.id) { 
                theId = action.payload.id
            } 

            // If still no ID, create on
            if (!theId) { 
                theId = getNewKey() 
                thisObject = {}
            } else {
                // If there already an object in Redux with that ID, get it
                //   Otherwise, make a skeleton object to merge in
                thisObject = state.data.problemBlockInstances[theId] || {};
            }

            // Use Object.assign instead of _merge because we want to overwrite after top level 
            //   e.g., the array of problems
            const newBlock = Object.assign({},thisObject,action.payload,{id : theId})
            
            // Validate against object schema
            ProblemBlockInstanceSchemav2(newBlock)
            // Save object to draft
            draft.data.problemBlockInstances[theId] = newBlock 
            // If there's a key, set the key/id dict
            if (theKey)
                draft.data.keys_to_ids[theKey] = theId
            draft.status = FETCH_STATUS.READY
            draft.error = null
        })
        return newState;
    case types.USER_GOODBYE:
        return initialState
    case types.PROBLEM_BLOCK_INSTANCE_CLEAR_DATA:
        return {
            ...initialState,
            status: FETCH_STATUS.READY, 
        }
    default:
        return state;
  }
}

export default reducer;

