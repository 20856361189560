import { buildYup } from 'json-schema-to-yup'

export const validationPatternNames = {
    SIMPLE_NUMERIC_RANGE: "SIMPLE_NUMERIC_RANGE",
    COMPLEX_NUMERIC_RANGE: "COMPLEX_NUMERIC_RANGE",
}

export const validationPatterns = {
    COMPLEX_NUMERIC_RANGE:  /(?!^,)^((^|,)([0-9]+|(?:[0-9]+-[0-9]+)))+$/,
    SIMPLE_NUMERIC_RANGE: /^(([0-9]+|(?:[0-9]+-[0-9]+)))$/,
  }

/*
export const validationStringFormats = {
    EMAIL: 'email'
}
*/

// Reshape the schema
const shapeSchema = (schema) => {
    let retVal = Object.assign({}, schema)
    Object.keys(schema.properties).forEach(item => {
        // If there's a "patternName", add a "pattern" property and set it to a built-in RegEx pattern
        if ((schema.properties[item]).hasOwnProperty("patternName")) {
            const patternName = schema.properties[item]["patternName"]
            if (!validationPatterns.hasOwnProperty(patternName)) 
                throw new Error("No built in validation pattern exists with the name " + patternName)
            retVal.properties[item]["pattern"] = validationPatterns[patternName]
        }
    });
    return retVal
}

export const getConfig = (schema) => {
    let config = {
        errMessages : {}
    }
    Object.keys(schema.properties).forEach(item => {
        if ((schema.properties[item]).hasOwnProperty("errorMessages")) {
            config.errMessages[item]=schema.properties[item]["errorMessages"]
        }
    });
    return config
}

// Takes the schema and returns the Yup
export const getYup = (schema) => {
    const s = shapeSchema(schema)
    const config = getConfig(s)
    config["log"]=true
    return buildYup(s, config)
}

// visibleGroups is an optional array of Strings
// If the visibleGroups parameter has a length > 0, questions are filtered out if they contain a layoutGroup
//   and that layoutGroup isn't the visibleGroups array
export const formPropsToArray = (schema, visibleGroups = []) => {
    let questions = []
    let count = 1
    Object.keys(schema.properties).forEach(item => {
        let qIn = schema.properties[item];
        if (
            !Array.isArray(visibleGroups) ||
            visibleGroups.length === 0 || 
            !qIn.hasOwnProperty("layoutGroup") || 
            visibleGroups.includes(qIn["layoutGroup"])
        ) {
            let q = {}
            q["name"] = item
            q["label"] =        qIn.hasOwnProperty("description") ?     qIn["description"]  : item
            q["sortOrder"] =    qIn.hasOwnProperty("sortOrder") ?       qIn["sortOrder"]    : count
            if (qIn.hasOwnProperty("layoutOptions"))        q["layoutOptions"] =        qIn["layoutOptions"]
            if (qIn.hasOwnProperty("helperText"))           q["helperText"] =           qIn["helperText"]
            if (qIn.hasOwnProperty("endAdornment"))         q["endAdornment"] =         qIn["endAdornment"]
            if (qIn.hasOwnProperty("numberFormatOptions"))  q["numberFormatOptions"] =  qIn["numberFormatOptions"]
            if (qIn.hasOwnProperty("layoutGroup"))          q["layoutGroup"] =  qIn["layoutGroup"]
            questions.push(q)
            count = count + 1
        }
    });
    questions.sort(function(a,b) {return a.sortOrder - b.sortOrder} )
    return questions
}

export const formDefaultValues = (schema) => {
    const keys = Object.keys(schema.properties)
    let defaultValues = {}
    keys.forEach(k => defaultValues[k] = schema.properties[k].hasOwnProperty("default") ? schema.properties[k]["default"] : null)
    return defaultValues
}
