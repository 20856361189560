import React from 'react'
import 'typeface-roboto'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import SignInPage from './signin';
import GoogleButton from './googlebutton'

import {StyledAuthPageDiv} from './styles'
import InHeader from '../App/Header/InHeader';
   
class AuthPage extends React.Component {

    goTo(route) {
        this.props.history.replace(`/${route}`);
    }

    render() {
        return (
            <InHeader>
            <StyledAuthPageDiv>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            container
                            alignItems={"center"}
                            direction={"column"}
                            justify={"center"}
                        >
                            <Grid item>
                            <Typography variant="h5" gutterBottom={false}><b>C'mon in!</b></Typography>
                            </Grid>
                            <Grid item>
                            <div style={{minWidth:"250px", paddingBottom:"13px", paddingTop:"10px"}}>
                            <GoogleButton />
                            </div>
                            </Grid>
                            <Grid item>
                            <Typography variant="subtitle1" gutterBottom={false}>or</Typography>
                            </Grid>
                            <Grid item>
                            <div style={{minWidth:"250px"}}>
                            <SignInPage />
                            </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledAuthPageDiv>
            </InHeader>
        )}
}

export default AuthPage