import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const StyledAuthPageDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
    background: #eff3f9;
    margin: 0 auto;
    width: 270px;
    `

export const StyledInputDiv = styled.div`
    padding-left: 12px;
    padding-right: 12px;
    background: #eff3f9;
    margin: 0 auto;
    min-width: 250px;
  `;

export const StyledErrorText = styled(Typography)`&&{
    color: red;
    padding-top: 4px;
  }`;