import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import 'typeface-roboto';
import { PALETTE } from '../../../styles/theme';

const primaryColors = PALETTE.primary;

export default function FormFieldCardParent(props) {
    const styles = {
        card: {
          maxWidth: 400
        },
        media: {
          height: 194,
        },
        expand: {
          transform: 'rotate(0deg)',
         // transition: theme.transitions.create('transform', {
         //   duration: theme.transitions.duration.shortest,
         // }),
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        },
        avatar: {
          backgroundColor: primaryColors[500],
        },
        flexGrow: {
          flex: '1 1 auto',
        },
      }

//    if (process.env.NODE_ENV === 'development') console.log(props)
    return (
        <div style={{ marginLeft: 20, marginRight:20, marginBottom: 30 }}>
            <Paper elevation={0} style={styles.card}>
                <div style={{ marginLeft: 0, marginRight:0, marginBottom: 5 }}>
                <Typography variant="caption"><strong>{props.cardTitle}</strong></Typography>
                </div>
                <div style={{ marginLeft: 0, marginRight:0, marginBottom: 5 }}>
                {props.children}
                </div>
                <Typography variant="caption">{props.detailText}</Typography>
            </Paper>
        </div>
    )
}
