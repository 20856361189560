import { validationPatternNames } from '../../services/formHelpers'


export const multiplication = {
    title: "Multiplication Options",
    description: "Multiplication Options",
    type: "object",
    metadata: {
        layoutGroups : {
            whenDetailSettingsVisible : ["basic", "advanced"],
            whenDetailSettingsNotVisible : ["basic"],
            detailSettingsVisibleByDefault: false
        }
    },
    properties: {
        batch_size: {
            sort_order: -1,
            default: 60,
            type: 'number',
            layoutGroup: "invisible",
        },
        xRange: {
            sortOrder : 1,
            description: "First Number (x)",
            type: 'string',
            default: '0-10',
            required: true,
            patternName : validationPatternNames.COMPLEX_NUMERIC_RANGE,
            helperText: 'Possible values for x',
            layoutOptions: { xs: 12, sm: 6 },
            layoutGroup: "basic",
            errorMessages: {
                required: "Required",
                pattern: 'Examples: "2" or "1-5" or "3,5,7" or "2,6-9"'
            }
        },
        yRange: {
            sortOrder : 2,
            description: "Second Number (y)",
            type: 'string',
            default: '0-10',
            required: true,
            patternName : validationPatternNames.COMPLEX_NUMERIC_RANGE,
            helperText: 'Possible values for y',
            layoutOptions: { xs: 12, sm: 6 },
            layoutGroup: "basic",
            errorMessages: {
                required: "Required",
                pattern: 'Examples: "2" or "1-5" or "3,5,7" or "2,6-9"'
            }
        },
        xNegativePct: {
            sortOrder : 3,
            description: "% Negative (x)",
            type: 'number',
            default: 0,
            required: true,
            integer: true,
            min: 0,
            max: 100,
            endAdornment: '%',
            numberFormatOptions: {
                decimalScale: 0,
                allowNegative : false,                
            },
            helperText: 'Example: 25%',
            layoutOptions: { xs: 12, sm: 6 },
            layoutGroup: "advanced",
            errorMessages: {
                required: "Required",
                min: 'Must be a number, 0-100',
                max: 'Must be a number, 0-100',
                integer: 'Must be a whole number, 0-100',
            }
        },
        yNegativePct: {
            sortOrder : 4,
            description: "% Negative (y)",
            type: 'number',
            default: 0,
            required: true,
            integer: true,
            min: 0,
            max: 100,
            endAdornment: '%',
            numberFormatOptions: {
                decimalScale: 0,
                allowNegative : false,                
            },
            helperText: 'Example: 25%',
            layoutOptions: { xs: 12, sm: 6 },
            layoutGroup: "advanced",
            errorMessages: {
                required: "Required",
                min: 'Must be a number, 0-100',
                max: 'Must be a number, 0-100',
                integer: 'Must be a whole number, 0-100',
            }
        },
        xDecimals: {
            sortOrder : 5,
            description: "Decimal places (x)",
            type: 'string',
            default: [0,0],
            required: true,
            patternName : validationPatternNames.SIMPLE_NUMERIC_RANGE,
            helperText: 'Examples: 1 or 1-2 or 3-4',
            layoutOptions: { xs: 12, sm: 6 },
            layoutGroup: "advanced",
            errorMessages: {
                required: "Required",
                pattern: 'Must be a number or a range'
            }
        },
        yDecimals: {
            sortOrder : 6,
            description: "Decimal places (y)",
            type: 'string',
            default: [0,0],
            required: true,
            patternName : validationPatternNames.SIMPLE_NUMERIC_RANGE,
            helperText: 'Examples: 1 or 1-2 or 3-4',
            layoutOptions: { xs: 12, sm: 6 },
            layoutGroup: "advanced",
            errorMessages: {
                required: "Required",
                pattern: 'Must be a number or a range'
            }
        },
    }
}
