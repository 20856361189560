import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import {
	setProblemBlock,
	initializeProblemBlockInstance,
	randomizeProblemBlockInstance,
	logger,
	newSampleFromProblemBlockInstance,
	initializeRenderer,
	initializeWorksheetLayout,
	updateLayout_for_ProblemBlockInstanceByKey,
	worksheetLayoutSetCustomLayout
} from '../../redux/actions'
import { getAuthIsReady, getIsLoggedIn } from '../../redux/selectors/authSelectors'
import {
	getProblemBlocks,
	getProblemBlockInstances,
	checkProblemBlocksReady,
	checkProblemBlockInstancesReady
} from '../../redux/selectors/problemBlockSelectors'
import {
	getProblemBlockByKey,
	getProblemBlockInstanceByKey,
	getSampleGeneratorByInstanceKey,
	//	getLayoutByInstanceKey
} from '../../redux/transformers/problemBlockTransformers'
import { getWorksheetLayouts, checkWorksheetLayoutsReady } from '../../redux/selectors/worksheetLayoutSelectors'
import {
	getWorksheetLayoutByKey
} from '../../redux/transformers/worksheetLayoutTransformers'
import { renderers } from '../../services/renderers';


import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'typeface-roboto';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import InHeader from '../App/Header/InHeader';
import InfoPopup from '../BiggerBits/InfoPopup';
import PrintableWorksheet from './PrintableWorksheet';
import WorksheetBasic from './WorksheetBasic';
import Page from '../BiggerBits/Page';
import PageContainer from '../BiggerBits/PageContainer';
import { styles } from './mathPageStyles';
import { DEFAULT_PROBLEM_BLOCK_MATRIX_LAYOUT } from '../../constants/layoutconstants';
import BlockLayoutSettings from './BlockLayoutSettings';
import Delay from '../../components/utils/Delay'
import PDFButton from '../ConnectedBits/PDFButton';



class ProblemPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			openAlert: null,
			counter: 0,
			tabIndex: 0
		};
	}

	namespace = `ui.${this.props.problemType}.route=${this.props.location.pathname}`;
	keySample = this.namespace + '-SampleEq';
	keyWorksheet = this.namespace + '-Worksheet';
	keyLayout = this.namespace + '-MatrixLayout';
	initialProblemBlockData = {
		problem_type: this.props.problemType,
		generator_settings: this.props.generatorInitialSettings,
		//problem_count: this.props.defaultLayout['rows'] * this.props.defaultLayout['columns'],
		key: this.namespace
	}
	initialProblemBlockDatav2 = {
		problem_type: this.props.problemType,
		generator_settings: this.props.generatorInitialSettings,
		key: this.namespace
		//problem_count: this.props.defaultLayout['rows'] * this.props.defaultLayout['columns']		
	}
	initial_layout_data = Object.assign(
		{},
		DEFAULT_PROBLEM_BLOCK_MATRIX_LAYOUT,
		this.props.defaultLayout || {})


	componentDidMount() {
		this.initializeProblemBlockData()

		//if (process.env.NODE_ENV === 'development') console.log('Mounted');
		this._isMounted = true;
	}

	componentWillUnmount() {
		//if (process.env.NODE_ENV === 'development') console.log('Unmounted');
		this._isMounted = false;
	}

	initializeProblemBlockData = () => {
		// 1. Create the ProblemBlock and give it a key for this page
		//this.props.logger("Set Problem Block")
		//console.log("SET PROBLEM BLOCK in initializeProblemBlockData: ",this.namespace)
		this.props.setProblemBlock(this.initialProblemBlockDatav2)

		// 2. Create an instance for the ProblemBlock
		this.props.initializeProblemBlockInstance(
			this.keyWorksheet, this.namespace,
			this.initial_layout_data,
			this.props.defaultLayout['rows'] * this.props.defaultLayout['columns']);

		// 3. Set up the ProblemRender
		this.props.initializeRenderer(this.props.problemRendererName)

		// Initialize the Worksheet Layout
		this.props.initializeWorksheetLayout({ key: "all" })
	}

	handleSettingsChange = (settings) => {
		this.setProblemBlock(settings);
	};

	describeRendererProblem = (renderer) => {
		const val = JSON.stringify(renderer.problem, null, 4);
		return `${val}\n\n`;
	};

	handleClickSample = () => {
		this.props.newSampleFromProblemBlockInstance(this.keyWorksheet);
	};

	handleClickRandomizeWorksheet = () => {
		this.props.randomizeProblemBlockInstance(this.keyWorksheet);
	};

	triggerButtonOuter = () => {
		return (
				<FontAwesomeIcon icon={faPrint} className={this.props.classes.faIcon} />
		);
	};


	setProblemBlock = (settings = undefined, worksheetDimensionValues = undefined) => {
		let data = {
			problem_type: this.props.problemType,
			key: this.namespace
		};
		if (settings !== undefined) data['generator_settings'] = settings;
		if (worksheetDimensionValues !== undefined)
			data['generator_settings'] = worksheetDimensionValues[0] * worksheetDimensionValues[1];

		this.props.setProblemBlock(data)
	};


	// Closes any open modal
	handleCloseMoreInfo = () => {
		this.setState({ openAlert: null });
	};

	// Sets a particular modal to open
	handleOpenMoreInfo = (topic) => {
		this.setState({ openAlert: topic });
	};

	// Set tab index for the page
	handleSetTabIndex = (index) => {
		if (process.env.NODE_ENV === 'development') console.log('Setting tab index: ', index);
		if (this.props.isMobile) {
			this.setState({ tabIndex: index });
		}
	};

	// Widget for Problem Settings
	ProblemSettings = this.props.SettingsForm;

	worksheetLayoutSettings = {};
	TheWorksheet = (props) => {
		const problemBlockInstance = getProblemBlockInstanceByKey(this.keyWorksheet, this.props.problemBlockInstances)
		const layout = problemBlockInstance.layout

		return (
			<WorksheetBasic
				layout={layout}
				layoutKey={this.keyLayout}
				pageLayout={this.worksheetLayoutSettings}
				initialLayoutData={this.initial_layout_data}
				problemRendererName={this.props.problemRendererName}
				problemBlockInstanceKey={this.keyWorksheet}
				worksheetKey={this.keyWorksheet}
				problems={problemBlockInstance.problems}
			/>
		);
	}

	MyCard = (props) => {
		const mobile = this.props.isMobile;
		//		const { classes, theme } = this.props;
		const { classes } = this.props;
		if (!mobile) return <Card {...props}>{props.children}</Card>;
		return (
			<Paper elevation={0} className={classes.rootPaperCard}>
				{props.children}
			</Paper>
		);
	};

	MyCardActions = (props) => {
		//			if (process.env.NODE_ENV === 'development') console.log('Mobile? ', mobile);
		const mobile = this.props.isMobile;
		if (!mobile) return <CardActions {...props}>{props.children}</CardActions>;
		return null;
	};

	CardActionPrint = (props) => {
		return (
			<React.Fragment>
				<Tooltip title="Print" placement="top">
					<IconButton aria-label="Print">
						<PrintableWorksheet trigger={this.triggerButtonOuter} content={this.TheWorksheet} />
					</IconButton>
				</Tooltip>
			</React.Fragment>
		);
	};

	/*
	<FontAwesomeIcon icon={faPrint} className={this.props.classes.faIcon} />
	*/

	CardActionPdf = (props) => {
		const html = renderToString(<this.TheWorksheet />)
		const {isLoggedIn} = props
		return (
			<PDFButton innerHtml={html} disabled={!isLoggedIn} 
				disabledReason={!isLoggedIn ? "Download PDF (sign in to enable)" : undefined} />
		)
	};


	CardActionRandomizeWorksheet = (props) => {
		//const { classes, theme } = this.props;
		const { classes } = this.props;
		return (
			<Tooltip title="Randomize the Worksheet" placement="top">
				<IconButton aria-label="Randomize the Worksheet" onClick={this.handleClickRandomizeWorksheet} style={{ marginLeft: 'auto' }}>
					<FontAwesomeIcon icon={faRandom} className={classes.faIcon} />
				</IconButton>
			</Tooltip>
		);
	};

	CardActionMoreInfo = (props) => {
		//		const { classes, theme } = this.props;
		const { classes } = this.props;
		return (
			<Tooltip title="More Info" placement="top">
				<div>
					<IconButton
						aria-label="More Info:"
						onClick={() => this.handleOpenMoreInfo('Info')}
					>
						<FontAwesomeIcon icon={faInfoCircle} className={classes.faIcon} />
					</IconButton>
				</div>
			</Tooltip>
		);
	};

	CardActionNewProblem = (props) => {
		//		const { classes, theme } = this.props;
		const { classes } = this.props;
		return (
			<Tooltip title="New Problem" placement="top">
				<IconButton
					aria-label="New Random Problem"
					style={{ marginLeft: 'auto' }}
					onClick={this.handleClickSample}
				>
					<FontAwesomeIcon icon={faRandom} className={classes.faIcon} />
				</IconButton>
			</Tooltip>
		);
	};

	render() {
		// Make sure: 
		//	(1) auth is ready
		//	(2) ProblemBlocks is ready
		//	(3) the requisite ProblemBlock has been initialized
		//	(4) the requisite renderer is ready
		//  (5) worksheetLayouts is ready
		if (!this.props.authIsReady ||
			!this.props.problemBlocksReady ||
			!getProblemBlockByKey(this.namespace, this.props.problemBlocks) ||
			!renderers.isRendererInitialized(this.props.problemRendererName) ||
			!this.props.worksheetLayoutsReady ||
			!this.props.problemBlockInstancesReady
		)
			return (
				<Delay wait={200}>
					<p>Loading...</p>
				</Delay>
			)
		const problemBlock = getProblemBlockByKey(this.namespace, this.props.problemBlocks)
		const problemBlockInstance = getProblemBlockInstanceByKey(this.keyWorksheet, this.props.problemBlockInstances)
		this.worksheetLayoutSettings = getWorksheetLayoutByKey("all", this.props.worksheetLayouts)

		//		const { classes, theme } = this.props;
		const { classes } = this.props;

		// Get a problem generator for sample problems
		const sampleGenerator = getSampleGeneratorByInstanceKey(this.keyWorksheet, this.props.problemBlockInstances)
		const more_info = sampleGenerator ? sampleGenerator.info() : null;

		// Make a renderer for sample problems
		const sampleRenderer = new (renderers.getRenderer(this.props.problemRendererName))()

		// Start with a sample problem in the renderer
		sampleRenderer.problem = problemBlockInstance.sample;
		sampleRenderer.show_answers = problemBlockInstance.layout.showAnswers;





		// Used to pass initial settings to the settings form
		// const generator_settings = this.problemBlock.generator_settings;

		const generator_settings = problemBlock.generator_settings;
		const eq = sampleRenderer ? sampleRenderer.svg : null;

		const mobile = this.props.isMobile;
		const title = (mobile && this.props.mobileTitle) ? this.props.mobileTitle : (this.props.title || 'Math Page!');
		const headerTabs = ['1. Problem', '2. Layout', '3. Worksheet'];

		return (
			<InHeader
				title={mobile ? title : null}
				suppressPadding={mobile}
				headerTabs={mobile ? headerTabs : null}
				headerTabsCallback={mobile ? this.handleSetTabIndex : null}
				headerTabsValue={this.state.tabIndex}
			>
				<div className={mobile ? classes.rootMobile : classes.root}>
					<InfoPopup
						title="Info"
						contentText={
							more_info + '\n\n Sample Problem: \n' + this.describeRendererProblem(sampleRenderer)
						}
						buttonText="Okay"
						isOpen={this.state.openAlert && this.state.openAlert === 'Info' ? true : false}
						closeHandler={this.handleCloseMoreInfo}
					/>

					<PageContainer
						key="page-container"
						title={title}
						isMobile={mobile}
						tabIndex={this.state.tabIndex}
						setTabIndexCallback={mobile ? this.handleSetTabIndex : null}
					>
						<Page title={headerTabs[0]} isMobile={mobile} key="page0">
							<this.MyCard key="card0" style={{ height: 'inherit', width: '375px' }}>
								{!mobile && <CardHeader title="The Problems" />}
								<CardContent>
									<this.ProblemSettings
										key="problem-settings"
										onChange={this.handleSettingsChange}
										initSettings={generator_settings}
									/>
								</CardContent>
								<CardContent>
									<Paper elevation={0} className={classes.problem_paper}>
										{eq && <svg id="equation1">{eq}</svg>}
									</Paper>
								</CardContent>

								<this.MyCardActions className={classes.actions} disableSpacing>
									<this.CardActionMoreInfo />
									<this.CardActionNewProblem />
								</this.MyCardActions>
							</this.MyCard>
						</Page>

						<Page title={headerTabs[1]} isMobile={mobile} key="page1">
							<this.MyCard key="card1" style={{ height: 'inherit', width: '375px' }}>
								{!mobile && <CardHeader title="The Worksheet" />}
								<CardContent>
									<BlockLayoutSettings
										blockKey={this.namespace}
										blockInstanceKey={this.keyWorksheet}
										layoutKey={this.keyLayout}
										defaultLayout={this.props.defaultLayout}
										updateLayout_for_ProblemBlockInstanceByKey={this.props.updateLayout_for_ProblemBlockInstanceByKey}
										blockInstanceLayout={problemBlockInstance.layout}
										worksheetLayout={this.worksheetLayoutSettings}
										isLoggedIn={this.props.isLoggedIn}
										worksheetLayoutSetCustomLayout={this.props.worksheetLayoutSetCustomLayout}
									/>
								</CardContent>
							</this.MyCard>
						</Page>

						<Page title={headerTabs[2]} isMobile={mobile} key="page2">
							<this.MyCard key="card2" style={{ height: 'inherit', width: '375px' }}>
								<CardContent>
									<Paper elevation={0} className={classes.problem_paper}>
										<this.TheWorksheet />
									</Paper>
								</CardContent>

								<this.MyCardActions className={classes.actions} disableSpacing>
									<this.CardActionPrint />
									<this.CardActionPdf  isLoggedIn={this.props.isLoggedIn} />
									<this.CardActionRandomizeWorksheet />
								</this.MyCardActions>
							</this.MyCard>


						</Page>

					</PageContainer>
				</div>
				{mobile &&
					this.state.tabIndex === 0 && (
						<AppBar className={classes.stickToBottom} color="default">
							<Toolbar>
								<this.CardActionMoreInfo />
								<this.CardActionNewProblem />
							</Toolbar>
						</AppBar>
					)}

				{mobile &&
					this.state.tabIndex === 2 && (
						<AppBar className={classes.stickToBottom} color="default">
							<Toolbar>
								<this.CardActionPrint />
								<this.CardActionPdf isLoggedIn={this.props.isLoggedIn} />
								<this.CardActionRandomizeWorksheet />
							</Toolbar>
						</AppBar>
					)}
			</InHeader>

		);
	}
}

ProblemPage.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.any.isRequired
};

// Be responsive
const mapSizesToProps = ({ width, height }) => ({
	isMobile: (width < 480 || height < 480)
});
ProblemPage = withSizes(mapSizesToProps)(ProblemPage);
ProblemPage = withRouter(withStyles(styles, { withTheme: true })(ProblemPage));

const mapStateToProps = (state, ownProps) => ({
	authIsReady: getAuthIsReady(state),
	problemBlocks: getProblemBlocks(state),
	problemBlockInstances: getProblemBlockInstances(state),
	problemBlocksReady: checkProblemBlocksReady(state),
	worksheetLayouts: getWorksheetLayouts(state),
	worksheetLayoutsReady: checkWorksheetLayoutsReady(state),
	problemBlockInstancesReady: checkProblemBlockInstancesReady(state),
	isLoggedIn: getIsLoggedIn(state),
})

const mapDispatchToProps = {
	setProblemBlock,
	initializeProblemBlockInstance,
	randomizeProblemBlockInstance,
	logger,
	newSampleFromProblemBlockInstance,
	initializeRenderer,
	initializeWorksheetLayout,
	updateLayout_for_ProblemBlockInstanceByKey,
	worksheetLayoutSetCustomLayout
}

export default
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProblemPage);
