import React from 'react';

export default class ThreeLineArithmeticProblem {
    constructor(problem, settings = {}) {
        this.problem = problem || undefined;
        this._settings = settings;
      }

      _settings = {}
      _problem = undefined;
      _svg = undefined;
      _show_answers = false;

      get settings() {
        return {}
      }

      // Layout constants for this renderer
      get layout_constants() {
        return {
          isFixedHeight : true,
          isFixedWidth : false,
          isFixedHeight_GivenDisplayProperties : true,
          isFixedWidth_GivenDisplayProperties: true,
          typicalHeight : Math.trunc(100 * 85 / 65),
          typicalWidth : 100,
          fontSizeInPoints : 36,
          canSetAspectRatio : false,
          /*
            minAspectRatio : null,
            maxAspectRatio : null,
            minWidth : null,
            minHeight : null,
            maxWidth : null,
            maxHeight : null,
          */
        }
      }

      // Height of *this* problem
      get height() {
        return EQHEIGHT;
      }

      // Width of *this* problem
      get width() {
        //const maxDigits = this.problem.display_factors.problemSet_maxDigits;
        const allText = textValues(this.problem, this.show_answers);
        return eqWidth(allText.displayLength);
      }

      get hw_ratio() {
        return this.height / this.width;
      }

      regenerate = () => {
          if (this.problem)
            this._svg = Equation(this.problem, this.show_answers)
      }

      set problem(value) {
        //if (process.env.NODE_ENV === 'development') console.log("Setting problem: ",value)
        this._problem = value;
        this._svg = undefined;
        this._hw_ratio = undefined;
        this.regenerate();
      }
    
      get problem() {
        return this._problem;
      }

      get show_answers() { return this._show_answers}
      set show_answers(v) {
          if (v !== this.show_answers) {
            this._show_answers = v;
            this.regenerate();
          }
      }

      get svg() {
        return this._svg;
      }
}

const FONTPT = 36;    // Font size in Points
const CPI = (120/FONTPT);  // approx characters per inch
const FONTPX = FONTPT * (15.9378 / 12)    // Font size in pixels
const ROWHEIGHT = FONTPX;
const LINEOFFSET = 10;
const LINESTROKEWIDTH = 3;
const PADBOTTOM = 8;
const EQHEIGHT = ROWHEIGHT *3 + LINEOFFSET + PADBOTTOM; //+ Math.floor(rowHeight/2)
const EXTRAWIDTHFORANSWERTOBEWIDER = 9;  // e.g., because 55 + 50 = 105 -- an extra digit appears, always a 1
const SYMBOLS = {
  "ADDITION": "+",
  'SUBTRACTION' : "\u2212",
  'MULTIPLICATION': "\xD7",
  'DIVISION': "\xF7"
}

/*
// Max value of an addend, based on the max number of digits
const maxValue = (worksheet_maxDigits) => {
  return 10**(worksheet_maxDigits)-1;
}*/

/*
// Digit count: Length including commas, plus 1 for the symbol, and 1/2 for space between symbol and number
const digitCount = (worksheet_maxDigits) => {
  return maxValue(worksheet_maxDigits).toLocaleString().length + 1.5;
}
*/



const eqWidth = (worksheet_maxDigits) => {
  //if (process.env.NODE_ENV === 'development') console.log("Calculating eqWidth based on worksheet_maxDigits, CPI, digitCount(worksheet_maxDigits),origin ",worksheet_maxDigits, CPI, digitCount(worksheet_maxDigits), origin)
  return ((worksheet_maxDigits+1.5) / CPI)*96 + EXTRAWIDTHFORANSWERTOBEWIDER 
  // The 1.5 extra is for the symbol and the space.
}

const textValues = (problem, show_answers) => {
  const dataX = problem.data.x;
  const dataY = problem.data.y;
  const dataAnswer = show_answers ? problem.answer_data["answer"] : undefined;  

  const xDecimalPlaces = problem.display_factors.xDecimalPlaces || 0;
  const yDecimalPlaces = problem.display_factors.yDecimalPlaces || 0;
  const answerDecimalPlaces = xDecimalPlaces + yDecimalPlaces;

  const answerText = (undefined===dataAnswer || dataAnswer==="") ? "" : dataAnswer.toLocaleString(undefined, { minimumFractionDigits: answerDecimalPlaces });
  const xText = dataX.toLocaleString(undefined, { minimumFractionDigits: xDecimalPlaces });
  const yText = dataY.toLocaleString(undefined, { minimumFractionDigits: yDecimalPlaces });

  let displayLength;
  if (problem.type === 'MULTIPLICATION') {
    const {totalPossibleCharacterLengthX, totalPossibleCharacterLengthY, totalPossibleCharacterLengthAnswer} = problem.display_factors;
    displayLength = Math.max(totalPossibleCharacterLengthX, totalPossibleCharacterLengthY, totalPossibleCharacterLengthAnswer-1.5)
  } else
  {
    displayLength = problem.display_factors.problemSet_maxDigits + 
      (problem.display_factors.problemSet_maxDigits-1) / 4
//      Math.floor((problem.display_factors.problemSet_maxDigits-1) / 4)
  }
  

  return {
    xText : xText,
    yText : yText,
    answerText: answerText,
    //maxLength: Math.max(xText.length, yText.length, (answerText.length - 1.5))
    displayLength : displayLength,
  }
}


const Equation = (problem, show_answers) => {
        const allText = textValues(problem, show_answers);
        //console.log("allText: ",allText);  // *&*
        const {xText, yText, answerText, displayLength} = allText;


        const charCount = displayLength; //maxValue(worksheet_maxDigits).toString(10).length;

        // Set fixed length for x,y and answer based on approx. characters per inch  (96 DIP per inch)
        //    Note that padding text to the left doesn't work, so instead we scale for the number of characters
        //    First set xyTextLength to the length we want the longest possible text to be (longest number, including commas)
        const xyTextLength = ((charCount)/CPI)*96;
        // Now get the scaling factor for each number based on the number of digits we need to display, as pct of the max
        const xLen = xyTextLength * (xText.length/(charCount));
        const yLen = xyTextLength * (yText.length/(charCount));
        const answerLen = xyTextLength * (answerText.length/(charCount));

        //if (process.env.NODE_ENV === 'development') console.log("xyTextLength, charCount, xText, yText, answerText: ",xyTextLength, charCount, xText, yText, answerText)

        // Get the character for plus, minus, etc.
        const symbol = SYMBOLS[problem.type] || "?";

        let textX = <text x="100%" y={ROWHEIGHT} fill="black" textAnchor="end"  textLength={xLen} fontSize={FONTPX}>{xText}</text>;
        let textY = <text x="100%" y={ROWHEIGHT * 2} fill="black" textAnchor="end" textLength={yLen} fontSize={FONTPX}>{yText}</text>;
        let textSymbol = <text x="0%" y={ROWHEIGHT * 2} fill="black" textAnchor="start" fontSize={FONTPX}>{symbol}</text>;
        let textAnswer = <text x="100%" y={ROWHEIGHT * 3 + LINEOFFSET} fill="gray" textAnchor="end" textLength={answerLen} fontSize={FONTPX}>{answerText}</text>;
        let eq = 
            <svg width="100%" viewBox={"0 0 "+eqWidth(charCount).toString(10)+" " + EQHEIGHT.toString(10)} 
              style={{"fontFamily": 'Lucida Console", Monaco, "Courier New", Courier, monospace',
              display:'block'
              }}>
                {textX}
                {textSymbol}
                {textY}
                <line x1="0%" y1={ROWHEIGHT * 2 + LINEOFFSET} x2="100%" y2={ROWHEIGHT * 2 + LINEOFFSET} strokeWidth={LINESTROKEWIDTH} stroke="black"/>
                {textAnswer}
            </svg>
        
        //console.log("The EQ: ",eq)
        return eq
}   
