import {parseNumericRange, getRandomInt} from './utils'
import ProblemGeneratorClass from './generator_wrapper';

export default class Multiplication extends ProblemGeneratorClass {
  constructor(settings, errorHandler=undefined) {
    const initialSettings = settings || {
      xRange : '0-12',
      yRange : '0-12',
      xNegativePct:0,
      yNegativePct:0,
      xDecimals: [0,0],
      yDecimals: [0,0],
      batch_size: 12
    } ;  
    const problemType = "MULTIPLICATION";
    super(problemType, initialSettings, _makeBatch, errorHandler); 
  }
}

function _makeBatch(settings) { 
  let {xRange, yRange, xDecimals, yDecimals, batch_size} = settings;

 // console.log("settings ",settings)
//  const xNegative_ratio = parseRatio(settings.xNegativeRatio);
//  const yNegative_ratio = parseRatio(settings.yNegativeRatio);

/*
  function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
  }*/

  // Digit length of a number, returned as a negative numbe if the number was negative
  function digitLength(number) {
      return (number === 0 ? 1 : Math.floor(Math.log10(Math.abs(number)))+1) * (number < 0 ? -1 : 1)
  }

  function possibleDigits(ranges) {
      let possibleDigits = new Set();
      ranges.forEach(r => {
        const leftDigits = digitLength(r[0]); // e.g., 87654 gives 5, b/c it's 5 digits long
        const rightDigits = digitLength(r[1]); 
        for (let i=leftDigits; i<=rightDigits; i++) {
            if (i===0) continue;
            possibleDigits.add(i);
        }
      })
      return possibleDigits;
  }

  // Get the number of possible values
  function numberOfPossibleValues (ranges, maxDecimals) {
      let rez = 0;
      ranges.forEach(arr => {
          const left = arr[0] * (10**maxDecimals) // e.g., if they can have up to 3 decimals, multiple by 1000, so (12.567, 18) -> (12567,18000)
          const right = arr[1] * (10**maxDecimals) 
          rez = rez + right - left + 1;
      })
      return rez;
  }

  // Get the value based on sequential order
  const sequentialValue = (itemNumber, ranges, decimals) => {
    //console.log("sequentialValue: itemNumber, ranges, decimals", itemNumber, ranges, decimals )
      if (itemNumber <= 0) {
        new Error("Bad item index sent to sequentialValue: " + itemNumber.toString())
      }
      let rez2 = 0;
      for (let i=0; i<ranges.length; i++) {
          const arr = ranges[i];
          const left = arr[0] * (10**decimals[1]) // e.g., if they can have up to 3 decimals, multiple by 1000, so (12.567, 18) -> (12567,18000)
          const right = arr[1] * (10**decimals[1]) 
          let rezAfter = rez2 + right - left + 1;
          // If the itemNumber we are looking for is in this range, find the data and return it
          if (itemNumber <= rezAfter) {
              const result9 = ((itemNumber - rez2) + left-1)/(10**decimals[1]);
              return result9;
          }
          rez2 = rezAfter;
      }
      console.log("Item not found: itemNumber, ranges, decimals: " + itemNumber, ranges, decimals)
      throw new Error("Item not found in multiplication generator")
  }

// Produces a range of numbers in an array, from left to right, inclusive
const inclusiveRange = (left, right) => {
    //console.log("left, right: ", left, right)
    return Array.from(Array(right-left+1).keys()).map(v => v + left)
}

//  Expand from a list of ranges e.g. ([2,5],[9,10]) to a list of all the possible values constained within those ranges, inclusive of the ends.
//  Use the maxDecimals to step by, say, .1 or .01 (for decimals 1 or 2 respectively)
const expandRange = (ranges, maxDecimals) => {
    let allValues = []
    //console.log("Expanding ranges, maxDecimals: ",ranges, maxDecimals)
    ranges.forEach(r => {
        // _.range expands the range to create an array including everything in the range
        // Here we also multiply by the decimals so we can just operate in integer values, avoiding floating point issues in the result
        allValues.push.apply( allValues, inclusiveRange(r[0]* (10**maxDecimals) , (r[1])* (10**maxDecimals)))
    })
    // Shift the decimal place back
    return allValues.map(v => v / Math.pow(10,maxDecimals))
}


  // Functions to create a cartesian product of arrays
  // Cartesian: https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript
  const _fCart = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
  const cartesian = (a, b, ...c) => (b ? cartesian(_fCart(a, b), ...c) : a);



  // Given the set of possible digits returned by possibleDigits(), and the unique allowed ranges for X, get
  //   the overlapping ranges for each of the possibleDigits, and place results in an object
  // NOTE: returns [-c, 0] where numbers up to 0 can be included in a range
  function digitGroupsWithOverlappingRanges(possibleDigits, ranges) {
      let result = {};
      for (let d of possibleDigits) {
          // Get ranges like 10-99 for "2" digits, or -999 to -100 for "-3" digits
          let ranger1 = (10 ** Math.abs(d)-1) * (d < 0 ? -1 : 1)
          let ranger2 = (10 ** (Math.abs(d)-1)) * (d < 0 ? -1 : 1)
          let ranger = [Math.min(ranger1, ranger2), Math.max(ranger1, ranger2)]
          if (ranger[0]<0 && ranger[1]===-1) ranger[1]=0;
          if (ranger[0]===1) ranger[0]=0;
          let overlap = [];
          ranges.forEach(r => {
              if (!(r[1] < ranger[0] || r[0] > ranger[1])) {
                  overlap.push([Math.max(ranger[0],r[0]),Math.min(ranger[1],r[1])])
              }
          })
          result[d] = overlap;
      }
      return result;
  }

  // We want to balance the random selection of problems based on the digits, 
  //    rather than the discrete possible values. That way if the user wants like 1-5 digit numbers
  //    we end up with a nice balance, instead of almost entirely 5 and 4 digit numbers (b/c there are a lot more 
  //    values between 10000-99999 than 1-9)
  const xr = parseNumericRange(xRange);
  const yr = parseNumericRange(yRange);
  const possibleDigitsX = possibleDigits(xr);
  const possibleDigitsY = possibleDigits(yr);
  const maxIntDigitsX = Math.max(...possibleDigitsX);
  const maxIntDigitsY = Math.max(...possibleDigitsY);

  const negativePossibleX = settings.xNegativePct > 0;
  const negativePossibleY = settings.yNegativePct > 0;
  const totalPossibleCharacterLengthX = maxIntDigitsX 
        + Math.floor((maxIntDigitsX-1)/3)    // commas
        + (negativePossibleX ? 1 : 0)   // 1 for the minus sign
        + xDecimals[1]
        + (xDecimals[1] > 0 ? 1 : 0)   // 1 for the Decimal point
  const totalPossibleCharacterLengthY = maxIntDigitsY
        + Math.floor((maxIntDigitsY-1)/3)    // commas
        + (negativePossibleY ? 1 : 0)   // 1 for the minus sign
        + yDecimals[1]
        + (yDecimals[1] > 0 ? 1 : 0)   // 1 for the Decimal point

  const widestPossibleAnswer = (10**(maxIntDigitsX+xDecimals[1])-1) * (10**(maxIntDigitsY+yDecimals[1])-1)
  // Note if both are ALWAYS negative, a negative answer is not possible
  const answerCanBeNegative = ((negativePossibleX || negativePossibleY) && !(settings.xNegativePct === 100 && settings.yNegativePct === 100))
  const answerHasDecimalPoint = Math.max(xDecimals[1], yDecimals[1]) > 0
  const totalPossibleCharacterLengthAnswer = 
    widestPossibleAnswer.toLocaleString().length 
    + (answerHasDecimalPoint ? 1 : 0) 
    + (answerCanBeNegative ? 1 : 0);
 
  // Get subset of ranges for each of the PossibleDigits 
  const digitGroupsWithOverlappingRangesX = digitGroupsWithOverlappingRanges(possibleDigitsX, xr)
  const digitGroupsWithOverlappingRangesY = digitGroupsWithOverlappingRanges(possibleDigitsY, yr)

  // Now get the total possible number of problems
  let possibleXValues = numberOfPossibleValues (xr, xDecimals[1]);
  let possibleYValues = numberOfPossibleValues (yr, yDecimals[1]);
  const totalPossibleValues = possibleXValues * possibleYValues;
  //console.log("totalPossibleValues: ",totalPossibleValues, possibleXValues,possibleYValues)  //*&*
  let myProblemData = [];



  // if there are less than or equal to 169 problems (i.e., 0x0 to 12x12),
  //   use the batch size and produce unique numbers
  if (totalPossibleValues <= 169) {
    //if (1===0) {
      // Get all the possible values for x and for y (ignoring negative possibilities - we deal with that by just randomizing)
      //    because the point here is not to duplicate the integer values
      let allValuesX = expandRange(xr, xDecimals[1]);
      let allValuesY = expandRange(yr, yDecimals[1]);
      // All possible problems in the set
      let allProblems = cartesian(allValuesX, allValuesY);

      //console.log("allProblems: ",allProblems)

      /*
      // If it's shorter than the batch size, multiply as needed   
      const extraCopies = Math.ceil(batch_size / allProblems.length)-1
      console.log("extraCopies: ",extraCopies)
      if (extraCopies > 0) {
        let allProblems0 = allProblems
        for (let i=0; i < extraCopies; i++) {
            console.log("extra copy #",i)
            allProblems0.map(p => allProblems.push(p))
        }
      }
      */

      // Shuffle them up
      let shuffledProblems = allProblems.sort((a,b) => 0.5 - Math.random());  
      //console.log("shuffledProblems: ",shuffledProblems)

      for (let i=0; i < batch_size; i++) {
          // If the problem batch size is larger than all the problems, 
          //    we're just gunna repeat it. One could argue for re-scrambling the order
          let problemIndex = i
          if ((i+1) > shuffledProblems.length) {
              problemIndex = i % shuffledProblems.length;
          }

          let xValue = shuffledProblems[problemIndex][0]
          let yValue = shuffledProblems[problemIndex][1]
          //console.log("xValue, yValue: ", xValue, yValue)

          let xNegative = (settings.xNegativePct >= getRandomInt(1,100))
          let yNegative = (settings.yNegativePct >= getRandomInt(1,100))
          // No idea why, but javascript will print "-0" instead of "0" after you
          //  multiply 0 by -1, depending on context. At least on Chrome.
          if (xNegative) {xValue = (xValue === 0) ? 0 : xValue * -1};
          if (yNegative) {yValue = (yValue === 0) ? 0 : yValue * -1};
          myProblemData.push({
                  data: {
                    x: xValue, 
                    y: yValue, 
                  }, 
                  answer_data : {
                    answer : xValue * yValue === 0 ? 0 : xValue * yValue
                  },
                  ai_factors : {
                    maxDigits: Math.max(xValue.toString(10).length, yValue.toString(10).length),
                    hasNegative: (xValue < 0 || yValue < 0),
                    bothNegative : (xValue < 0 && yValue < 0),
                    hasDecimals : (xValue !== Math.floor(xValue) || yValue !== Math.floor(yValue))
                  },
                  display_factors : 
                    {
                      maxDecimalPlacesX : xDecimals[1],
                      maxDecimalPlacesY : yDecimals[1],
                      maxIntDigitsX : maxIntDigitsX,
                      maxIntDigitsY : maxIntDigitsY,
                      negativePossibleX : negativePossibleX,
                      negativePossibleY : negativePossibleY,
                      totalPossibleCharacterLengthX,
                      totalPossibleCharacterLengthY,
                      totalPossibleCharacterLengthAnswer
                    },
                }
          )
      }
  } else {
      // The alternative randomization method is first to balance
      //     using the number of digits
      const pdx = [...possibleDigitsX];
      const pdy = [...possibleDigitsY];
      for (let i = 1; i <= batch_size; i++) {
          const xLength = pdx[getRandomInt(0,pdx.length-1)];
          const yLength = pdy[getRandomInt(0,pdy.length-1)];
          const xRangesForThisProblem = digitGroupsWithOverlappingRangesX[xLength];
          const yRangesForThisProblem = digitGroupsWithOverlappingRangesY[yLength];
          const numberOfPossibleXValuesForThisProblem = numberOfPossibleValues(xRangesForThisProblem,0);
          const numberOfPossibleYValuesForThisProblem = numberOfPossibleValues(yRangesForThisProblem,0);
          const totalPossibleValuesForThisProblem = numberOfPossibleXValuesForThisProblem * numberOfPossibleYValuesForThisProblem;
          const mySeq = getRandomInt(1,totalPossibleValuesForThisProblem);
          let ySeq = Math.ceil(mySeq / numberOfPossibleXValuesForThisProblem)
          let xSeq = mySeq - ((ySeq-1) * numberOfPossibleXValuesForThisProblem)
          let xValue = sequentialValue (xSeq, xRangesForThisProblem, [0,0])
          let yValue = sequentialValue (ySeq, yRangesForThisProblem, [0,0])
          //console.log("ySeq, yRangesForThisProblem: ",ySeq, yRangesForThisProblem)
          //console.log("xValue, yValue: ",xValue, yValue)

    //      let xSeq = Math.ceil(q / possibleYValues);
    //      let ySeq = q - (xSeq-1)*possibleYValues;

          // Decimal?
          const decimalValue = (decimalRangeArray) => {
              let decimalPlaces;
              const decimalOptions = decimalRangeArray[1]-decimalRangeArray[0]+1 ; // possible places
              if (decimalOptions === 1) {
                  decimalPlaces = decimalRangeArray[0]
              } else {
                  decimalPlaces = decimalRangeArray[0] + getRandomInt(1,decimalOptions) - 1
              }
              return {
                  places: decimalPlaces,
                  value: decimalPlaces===0 ? 0 : getRandomInt(10**(decimalPlaces-1), 10**decimalPlaces-1) / (10**decimalPlaces)
              };
          }

          const {places : xDecimalPlaces, value : xDecimal} = decimalValue(xDecimals);
          const {places : yDecimalPlaces, value : yDecimal} = decimalValue(yDecimals);
          xValue = xValue + xDecimal;
          yValue = yValue + yDecimal;

          // Negative?
          let xNegative = settings.xNegativePct >= getRandomInt(1,100)
          let yNegative = settings.yNegativePct >= getRandomInt(1,100)
          // No idea why, but javascript will print "-0" instead of "0" after you
          //  multiply 0 by -1, depending on context. At least on Chrome.
          if (xNegative) xValue = xValue === 0 ? 0 : xValue * -1;
          if (yNegative) yValue = yValue === 0 ? 0 : yValue * -1;
          //console.log("xNegative, yNegative, settings.xNegativePct, settings.yNegativePct: ",xNegative, yNegative, settings.xNegativePct, settings.yNegativePct)

          myProblemData.push({
              data: {
                x: xValue, 
                y: yValue, 
              }, 
              answer_data : {
                answer : xValue * yValue === 0 ? 0 : xValue * yValue
              },
              ai_factors : {
                maxDigits: Math.max(xValue.toString(10).length, yValue.toString(10).length),
                hasNegative: (xValue < 0 || yValue < 0),
                bothNegative : (xValue < 0 && yValue < 0),
                hasDecimals : (xValue !== Math.floor(xValue) || yValue !== Math.floor(yValue))
              },
              display_factors : 
                {
                  xDecimalPlaces : xDecimalPlaces,
                  yDecimalPlaces : yDecimalPlaces,
                  maxDecimalPlacesX : xDecimals[1],
                  maxDecimalPlacesY : yDecimals[1],
                  maxIntDigitsX : maxIntDigitsX,
                  maxIntDigitsY : maxIntDigitsY,
                  negativePossibleX : negativePossibleX,
                  negativePossibleY : negativePossibleY,
                  totalPossibleCharacterLengthX,
                  totalPossibleCharacterLengthY,
                  totalPossibleCharacterLengthAnswer
                },
            }
      )
      } 

  }
  return myProblemData;
}



/*

import Multiplication from './src/MathStuff/math_generators/basic_multiplication';

 /*
const b = _makeBatch(
    {
        xRange : '0-12', //'-35-2,3-12,14-29',
        yRange : '0-12',
        xDecimals: [2,3],
        yDecimals: [2,2],
        xNegativeRatio : '0/1', 
        yNegativeRatio : '1/2',
        batch_size: 6
       }
)
*/
/*
  
const M = new Multiplication({
  xRange : '0-12', //'-35-2,3-12,14-29',
  yRange : '0-12',
  xDecimals: [2,3],
  yDecimals: [2,2],
  xNegativeRatio : '0/1', 
  yNegativeRatio : '1/2',
  batch_size: 12
})
console.log("hi");

console.log(M.next());
*/
