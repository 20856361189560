export const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	rootMobile: {
		flexGrow: 1, //height: '100%',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	problem_paper : {
		backgroundColor: theme.palette.grey[50],
	},
	rootPaper: {
		//minHeight: "400px",
		//...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	rootPaperHidden: {
		backgroundColor: "transparent",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		height: '100%'
	},
	rootPaperCard: {
		backgroundColor: "transparent",
		paddingTop: 0, 
		paddingBottom: 0,
		height: '100%'
	},
	appBarBottom: {
		position: "absolute",
		top: 'auto',
		bottom: 0,
		left: 0,
		right: 0,
	  },
	  stickToBottomOLD: {
		position: "sticky",
		right: 0,
		bottom: 0,
		left: 0,
		textAlign: "center",
		padding: 0,
		margin: 0,
		width:'100%'
	  },
	stickToBottom: {
		position: "sticky",
		right: 0,
		bottom: 0,
		left: 0,
		textAlign: "center",
		padding: 0,
		margin: 0,
		width:'100%'
	  },
	toolbarBottom: {
		alignItems: 'center',
		justifyContent: 'space-between',
	  },
	textField: {
		marginLeft: 0,
		marginRight: 0
	},
	actions: {
		display: 'flex',
		minHeight: "60px" 
	},
	faIcon: {
		fontSize: 18,
		margin: 0,
		padding: 0
		// padding if needed (e.g., theme.spacing(2))
		// margin if needed
	},
	cardAny : {},
	cardMobileTop: {
		background: "tomato",
		minHeight: "100",
	},
	cardMobileSpacer: {
		flex: 2,
		background: "gold",
		overflow: "auto",
	},
	cardMobileBottom: {
		background: "lightgreen",
		minHeight: "60px" 
	},
//	cardNotMobile: { height: 'inherit', minWidth: '350px', maxWidth: '400px' },
//	cardMobile: { height: 'inherit', minWidth: '350px', maxWidth: '400px' },
	cardNotMobile: { height: 'inherit', width: '400px' },
	cardMobile: { height: 'inherit', width: '400px' },
});