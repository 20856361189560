import React from 'react';
import Header from './Header';



const InHeader = (props) => {
	const {title, suppressPadding, headerTabs, headerTabsCallback, headerTabsValue, children} = props;
	
	//if (process.env.NODE_ENV === 'development') console.log("Title: ",title)

	return (
			<Header headerText={title || "Worksheet Workshop"} suppressPadding={suppressPadding || false}  headerTabs={headerTabs} headerTabsCallback={headerTabsCallback} headerTabsValue={headerTabsValue}>
				{children}
			</Header>
	);
};

export default InHeader;
