import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import 'typeface-roboto';
import PropTypes from 'prop-types';



import { withStyles } from '@material-ui/core/styles';
import 'rc-slider/assets/index.css';
import { Range } from 'rc-slider';

/*
    Until they fix it, use:

            this.setState({}, function () {
            if(_this3.handlesRefs[nextHandle])   // ADD THIS LINE to node_modules\rc-slider\es\Range.js
                _this3.handlesRefs[nextHandle].focus();
            });

    https://github.com/react-component/slider/pull/510/commits/4392fe8eccd41385abaa69196b851fcf84c148a4
*/

/*
handleStyle={{
    borderColor: 'blue',
    height: 28,
    width: 28,
    marginLeft: -14,
    marginTop: -9,
    backgroundColor: 'black',
  }}
*/



// Really I'm just using withStyles to pass in the theme for use with the Range
const styles = theme => ({ });



class RangeSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.values || [2,3],
            min: 1,
            max: 6,
          };
    }

    // See: https://react-component.github.io/slider/examples/slider.html
    handleStyle = [
        { 
            backgroundColor: this.props.theme.palette.primary[500], 
            height: 16,
            width: 16,
            marginLeft: -8,
            marginTop: -6,
         }, 
        { 
            backgroundColor : this.props.theme.palette.primary[200],
            height: 16,
            width: 16,
            marginLeft: -8,
            marginTop: -6,
        }
    ];

    updateValue = (e) => {
        this.setState({
          values: e,
        });
        return ((typeof this.props.onValuesUpdated === "function") ? this.props.onValuesUpdated(e) : null)
      }

    render() {
        //if (process.env.NODE_ENV === 'development') console.log("Theme: ",this.props.theme);
        return (
            <Grid container spacing={3} style={{alignContent:"bottom"}}>
                <Grid item xs={1}>
                    <div style={{ textAlign: 'left', verticalAlign: 'bottom' }}>
                        <Typography variant="body1">{this.state.values[0]}</Typography>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div style={{ marginTop: 4 }}>
                    <Range defaultValue={this.state.values} min={this.state.min} max={this.state.max}
                        onChange={this.updateValue}
                        trackStyle={[{ backgroundColor: this.props.theme.palette.primary[500] }, { backgroundColor: this.props.theme.palette.primary[500] }]}
//                        handleStyle={[{ backgroundColor: this.props.theme.palette.primary[500] }, { backgroundColor : this.props.theme.palette.primary[200] }]}
                        handleStyle={this.handleStyle}
                        railStyle={{ backgroundColor: this.props.theme.palette.primary[100] }}
                        dotStyle = {{backgroundColor: this.props.theme.palette.primary[500] }}
                        activeDotStyle= {{ backgroundColor: this.props.theme.palette.primary[500] }}
                    />

                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ textAlign: 'right', verticalAlign: 'center' }}>
                        <Typography variant="body1">{this.state.values[1]}</Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }
}


RangeSlider.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
export default withStyles(styles, { withTheme: true })(RangeSlider);



                    