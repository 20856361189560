import { struct } from 'superstruct';

// The GeneratedProblemSchema is just used to validate problems after they are 
//   generated by what could be a custom generator function. The only difference is, 
//   id and type are optional.
export const GeneratedProblemSchema = struct({
	id: 'string?',
	type: 'string?',
	data: 'object',
	answer_data: 'object',
	ai_factors: 'object?',
	display_factors: 'object?'
});

export const ProblemSchema = struct({
	id: 'string',
	type: 'string',
	data: 'object',
	answer_data: 'object',
	ai_factors: 'object?',
	display_factors: 'object?'
});

export const ProblemBlockSchema = struct({
	id: 'string',
	key: 'string?',
	problem_type: 'string',
	generator_settings: 'object',
	problem_count: 'number?',
	instances: 'array?',
});

export const ProblemBlockSchemav2 = struct({
	id: 'string',
	key: 'string?',
	problem_type: 'string',
	generator_settings: 'object',
	instances: 'array'
});

export const ProblemBlockInstanceSchemav2 = struct({
	id: 'string',
	key: 'string?',
	problemBlockId: 'string',
	problems: 'array',
	sample: 'object',
	layout : 'object',
	problemCount : 'number'
});


export const ProblemBlockInstanceSchema = struct({
	id: 'string',
	problemBlock: ProblemBlockSchema,
	problems: 'array',
});


export const UiSettingsSchema = struct({
	uid: 'string',
	primaryColors: 'object',
	secondaryColors: 'object',
	theme: 'string',
	defaultPageLayout: 'string?',
})

export const PageLayoutSchema = struct({
	id: 'string?',
	key : 'string?',
	name : 'string?',
	default_for : 'string?',
	is_published : 'boolean?',
	uid : 'string?',
	dimensions: {
		height: 'number',
		width: 'number'
	},
	page_margins: {
		left: 'number',
		right: 'number',
		top: 'number',
		bottom: 'number'
	},
	page_header : {
		height: 'number',
		default_svg_settings : {
			title: 'string',
			title_yPadding : 'number',
			name_date_yPadding: 'number',
			name_date_outerPadding: 'number',
			nameText: 'string',
			dateText: 'string',
			includeNameText: 'boolean',
			includeDateText: 'boolean',
		},
		customSvg: 'string | null?',
	},
})


export const WorksheetTemplate = struct({
	id: 'string?',
	is_built_in : 'boolean?',
	owner_uid : 'string?',
	publication : {
		is_published: 'boolean',
		date_published : 'date | null?',
		original_publisher_uid : 'string | null?',
		publication_permission_tags: struct.union(['null',['string?']]),
	},
	dimensions: {
		height: 'number',
		width: 'number'
	},
	page_margins: {
		left: 'number',
		right: 'number',
		top: 'number',
		bottom: 'number'
	},
	context: 'object?',
	page_header : {
		height: 'number',
		default_svg_settings : {
			title: 'string',
			title_yPadding : 'number',
			name_date_yPadding: 'number',
			name_date_outerPadding: 'number',
			nameText: 'string',
			dateText: 'string',
			includeNameText: 'boolean',
			includeDateText: 'boolean',
		},
		customSvg: 'string | null?',
	},
	blocks: ['object?'],
	version: 'string',
})