export const POSITION_IN_PARENT = {
    TOPLEFT: 1,
    TOPMIDDLE: 2,
    TOPRIGHT: 3,
    CENTERLEFT:4,
    CENTERMIDDLE:5,
    CENTERRIGHT:6,
    BOTTOMLEFT:7,
    BOTTOMMIDDLE:8,
    BOTTOMRIGHT :9,
}

export const DEFAULT_PROBLEM_BLOCK_MATRIX_LAYOUT = {
    x: 0, // Location in parent object
    y: 0,
    problem_offset: 0, // 0 means, start with the 1st problem, no offset
    rows: 5,
    columns: 5,
    vbWidth: null,
    vbHeight: null,
    widthFullPage: true,
    heightFullPage: true,
    headerHeight: 0,
    headerRenderer: null,
    headerOptions: null,
    drawProblemBorders: false,
    showAnswers: false,
    padTop:25,
    padLeft:25,
    padRight:25,
    padBottom:25,
    blockBackground : false,
    blockBackgroundBorder: 10,
    blockBackgroundBorderColor: "blue",
    blockBackgroundColor: "white",
    blockBackgroundOpacity: "25%",
    position_eq_in_parent: POSITION_IN_PARENT.CENTERMIDDLE,
    capGrowth : true,
    capGrowthMaximumFontPointSize : 30,
}


export const DEFAULT_PAGE_HEIGHT  = 900;
export const DEFAULT_PAGE_WIDTH = 650;
export const DEFAULT_HEADER_HEIGHT = 150;

export const DEFAULT_PAGE_LAYOUT = {
    dimensions: {
        height: DEFAULT_PAGE_HEIGHT,
        width: DEFAULT_PAGE_WIDTH
    },
    page_margins: {
        left: 100,
        right: 100,
        top: 100,
        bottom: 100
    },
    page_header : {
        height: DEFAULT_HEADER_HEIGHT,
        default_svg_settings : {
            title: "Worksheet Workshop",
            //title_yPadding : 40,
            //name_date_yPadding: -85,
            title_yPadding : -10,
            name_date_yPadding: 0,
            name_date_outerPadding: 0,
            nameText: "Name:",
            dateText: "Date:",
            includeNameText: true,
            includeDateText: true,
        },
        customSvg: null,
    },

};


export const DEFAULT_PAGE_LAYOUTS = 
{
    all : {
        dimensions: {
            height: DEFAULT_PAGE_HEIGHT,
            width: DEFAULT_PAGE_WIDTH
        },
        page_margins: {
            left: 100,
            right: 100,
            top: 100,
            bottom: 100
        },
        page_header : {
            height: DEFAULT_HEADER_HEIGHT,
            default_svg_settings : {
                title: "Worksheet Workshop",
                //title_yPadding : 40,
                //name_date_yPadding: -85,
                title_yPadding : -10,
                name_date_yPadding: 0,
                name_date_outerPadding: 0,
                nameText: "Name:",
                dateText: "Date:",
                includeNameText: true,
                includeDateText: true,
            },
            customSvg: null,
        },

    },
}
