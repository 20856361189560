import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

// Icons
import IconButton from '@material-ui/core/IconButton';
import { firebaseApp as firebase } from '../../firebase/rsf'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
//import SvgPdfIcon from '../Icons/PdfIcon2'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
//import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { styles } from '../MathPages/mathPageStyles';
// faFilePdf


// String back to ArrayBuffer
var str2ab = require('string-to-arraybuffer')

// A button that sends html to the renderPDF firebase function 
//   utilizes the Snackbar to message users
//   and saves the PDF download
// Required prop: innerHtml (as string)
const PDFButton = (props) => {
    const { classes } = props
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    let disableMe = false
    const innerHtml = (typeof props.innerHtml === "string" && props.innerHtml.length > 0) ? props.innerHtml : "<html><body><p>Content not yet loaded</p></body></html>"
    if (typeof props.innerHtml !== "string" || props.innerHtml.length === 0)
        disableMe=true

    if (typeof innerHtml !== "string" || innerHtml.length === 0)
        throw new Error("PDFButton requires a string prop: innerHtml")

    const handleRenderTest = () => {
        if (props.disabled) return
        setLoading(true)
        const refMsg = enqueueSnackbar("Generating PDF...", {
            variant: 'info',
            persist: true,
        });
        const renderPDF = firebase.functions().httpsCallable('renderPDF');
        renderPDF({ innerHtml: innerHtml }).then(function (result) {
            closeSnackbar(refMsg)
            setLoading(false)
            var pdf = result.data.pdf;
            //console.log("Got result: ", pdf)
            const blob = new Blob([str2ab(pdf)], { type: 'application/pdf' })
            enqueueSnackbar("Success: PDF saved", {
				variant: 'success',
				autoHideDuration: 3000,
            });
            saveAs(blob, "worksheet.pdf");
        }).catch(function (error) {
            closeSnackbar(refMsg)
            setLoading(false)
            console.log("Error generating PDF: ", error.message)
            enqueueSnackbar("Sorry, we were unable to generate a PDF", {
				variant: 'warning',
				autoHideDuration: 3000,
            });
        });
    }

    if (!loading && !disableMe)
        return (
                <Tooltip title="Download PDF" placement="top">
                    <IconButton 
                        aria-label="Download PDF" 
                        disabled={loading || disableMe} 
                        onClick={handleRenderTest} 
                    > 
                        <FontAwesomeIcon icon={faFilePdf} className={classes.faIcon} />
                    </IconButton>
                </Tooltip>
        )
        return (
                <IconButton 
                    aria-label="Download PDF" 
                    disabled={true}
                > 
                    <FontAwesomeIcon icon={faFilePdf} className={classes.faIcon} />
                </IconButton>
        )
}

/*
// <FontAwesomeIcon icon={faFilePdf} className={classes.faIcon} />
const styles = theme => ({
    faIcon: {
        fontSize: 18,
        margin: 0,
        padding: 0
        // padding if needed (e.g.,  2)
        // margin if needed
    },
    imageIcon: {
        height: '100%'
      },
      iconRoot: {
        textAlign: 'center'
      }
})
*/

export default withStyles(styles)(PDFButton);
