import {  select, fork, take, cancel, put, call } from 'redux-saga/effects';
//import { push } from 'react-router-redux';
import {randomPalette} from '../../logic/uiLogic';

import {
    types,
    syncUiSuccess,
    syncUiFailure,
    setUiData,
  } from '../actions';
  
  import rsf from '../../firebase/rsf';
  

export function* setRandomColorThemeSaga() {
    yield put(setUiData({
        primaryColors: randomPalette(),
        secondaryColors: randomPalette()
    }));
}

// Record updates to the UI doc
export function* updateUiSaga(action) {
    const theUser = yield select(state => state.auth.data.user)
    const theUiData = yield select(state => state.ui.data)
    //console.log("CUrrent UI data: ",theUiData)
    //console.log("UPDATE UI DOC WITH: ",action.payload)
    const newUiDoc = Object.assign(
        {},
        theUiData,
        action.payload,
        {uid: (theUser && theUser.uid) ? theUser.uid : null}
    ) 
    //console.log("newUiDoc: ", newUiDoc)
    if (theUser && theUser.uid) {
        yield call(
            rsf.firestore.setDocument,
            'ui/'+theUser.uid,
            newUiDoc
        );
    } else {
        //console.log("Setting the UI doc")
        // If the user is logged in we just push the data to Firebase
        //    and syncUiSaga will fire the action to the Redux reducer
        // BUT here we are not logged in - just humming along in the 
        //    browser. So we push to Redux directly
        yield put(syncUiSuccess(newUiDoc))
    }
  }

// Subscribe to the UI channel and send updates
export function* syncUiSaga() {
    while (true) {
        // Wait til we are logged in
        let isLoggedIn = yield select(state => state.auth.data.loggedIn)
        while (!isLoggedIn) {
            yield take(types.USER_HELLO)  // Wait for a new UI sync signal
            isLoggedIn = yield select(state => state.auth.data.loggedIn)
        }

        const uid = yield select(state => state.auth.data.user.uid)
    
        const task = yield fork(
            rsf.firestore.syncDocument,
            'ui/'+ uid,
            { 
                successActionCreator: syncUiSuccess,
                failureActionCreator: syncUiFailure
            }
        )

        //console.log("SYNC UI TASK: ",task)

        yield take(types.USER_GOODBYE)

        //console.log("USER_GOODBYE: no longer syncing UI")

        yield cancel(task)
    }
}


