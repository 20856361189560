import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  //if (process.env.NODE_ENV === 'development') console.log("Theme: ",theme)
  //if (process.env.NODE_ENV === 'development') console.log("Using: ",theme && theme.palette && theme.palette.text ? theme.palette.text.hint : "gray")
  return ({
  dialogText: {
    color: theme && theme.palette && theme.palette.text ? theme.palette.text.hint : "gray"
  }
})}

const AlertDialog = (props) => {
  const {title, contentText, buttonText, isOpen, closeHandler} = props;
  const { classes } = props;


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        style={{zIndex:"90000"}}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>     
          <div style={{midWidth:400}}>
          <Input className={classes.dialogText}
								id="dialog-info-text"
                multiline
                fullWidth
                disableUnderline
								inputProps={{
									readOnly: true
								}}
								value={contentText}
							/>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} color="primary" autoFocus>
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(AlertDialog);