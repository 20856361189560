import React from 'react';
import MultiplicationSetup from '../ProblemTypeSetupClasses/Multiplication';
import ProblemPage from './ProblemPage';
import {POSITION_IN_PARENT} from '../../constants/layoutconstants';
import { multiplication } from '../ProblemTypeSetupClasses/problemTypeSettingsFixture'
import { formDefaultValues } from '../../services/formHelpers'

const MathPage = (props) => {

    const defaultLayout = {
        rows : 5,
        columns: 5,
        showAnswers: false,
        padTop: 50,
        position_eq_in_parent: POSITION_IN_PARENT.TOPMIDDLE
    }
    const ProblemSettings = props => <MultiplicationSetup {...props}>{props.children}</MultiplicationSetup>

    return <ProblemPage 
        key="MULTIPLICATION"
        problemType = 'MULTIPLICATION'
        title="Multiplication: Good times!"
        mobileTitle="Multiplication"
        generatorInitialSettings = {formDefaultValues(multiplication)}
        defaultLayout =  {defaultLayout}
        problemRendererName= "ww.ThreeLineArithmetic"
        SettingsForm = {ProblemSettings}
    />
}  

export default MathPage;
