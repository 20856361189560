import { all, fork, takeEvery, put } from 'redux-saga/effects'
import { watchHello, watchGoodbye } from './firestoreUserDataSaga'
import {  types, logger } from '../actions'
import {
  syncUserSaga,
  logoutSaga,
  loginSaga,
  loginWithEmailSaga,
  registerWithEmailSaga,
  passwordChangeSaga,
  passwordForgetSaga,
} from './authSagas'
import { logAction } from './logSaga'
import {setRandomColorThemeSaga, updateUiSaga, syncUiSaga} from './uiSagas'
import {
  setProblemBlockSaga, 
  initializeProblemBlockInstanceSaga,
  updateProblemBlockInstanceSaga
} from './problemBlockSagas'
import {
  initializeRendererSaga
} from './rendererSagas';
import {
  initializeWorksheetLayoutSaga,
  updateWorksheetLayoutSaga,
  syncWorksheetLayoutSaga
} from './worksheetLayoutSagas'

export default function* rootSaga() {
  yield put(logger("LOGGER RUNNING"))
  yield fork(syncUserSaga);
  yield fork(syncUiSaga);
  yield fork(syncWorksheetLayoutSaga);
  

  yield all([
    takeEvery(types.LOGIN.REQUEST, loginSaga),
    takeEvery(types.LOGIN_WITH_EMAIL.REQUEST, loginWithEmailSaga),
    takeEvery(types.REGISTER_WITH_EMAIL.REQUEST, registerWithEmailSaga),
    takeEvery(types.LOGOUT.REQUEST, logoutSaga),
    takeEvery(types.PASSWORD_CHANGE.REQUEST, passwordChangeSaga),
    takeEvery(types.PASSWORD_FORGET.REQUEST, passwordForgetSaga),
    takeEvery(types.UI_SET_RANDOM_COLOR_THEME, setRandomColorThemeSaga),
    takeEvery(types.UI_SET_DATA, updateUiSaga),
    takeEvery(types.PROBLEM_BLOCK_SET.REQUEST, setProblemBlockSaga),
    takeEvery(types.PROBLEM_BLOCK_INSTANCE_INITIALIZE, initializeProblemBlockInstanceSaga),
    takeEvery(types.INITIALIZE_RENDERER.REQUEST, initializeRendererSaga),
    takeEvery(types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST, updateProblemBlockInstanceSaga),
    takeEvery(types.WORKSHEET_LAYOUT_INITIALIZE, initializeWorksheetLayoutSaga),
    takeEvery(types.WORKSHEET_LAYOUT_SET.REQUEST, updateWorksheetLayoutSaga),
    takeEvery(types.SYNC_UI.FAILURE, logAction),
    takeEvery(types.PASSWORD_CHANGE.FAILURE, logAction),
    takeEvery(types.LOGIN.FAILURE, logAction),
    takeEvery(types.LOGIN_WITH_EMAIL.FAILURE, logAction),
    takeEvery(types.REGISTER_WITH_EMAIL.FAILURE, logAction),
    takeEvery(types.CREATE_USER.FAILURE, logAction),
    takeEvery(types.LOGOUT.FAILURE, logAction),
    watchHello(),
    watchGoodbye(),
  ])
}
