import { createSelector } from 'reselect'
//import memoize from 'lodash.memoize'
import {FETCH_STATUS} from '../../constants/store';

const allWorksheetLayouts = state => state.worksheetLayouts.data

export const getWorksheetLayouts = createSelector(
  [allWorksheetLayouts],
  (allWorksheetLayouts) => {
      return allWorksheetLayouts
  })
  
export const checkWorksheetLayoutsReady = state => (state.worksheetLayouts.status === FETCH_STATUS.READY)
