import { createSelector } from 'reselect'
//import memoize from 'lodash.memoize'
import {FETCH_STATUS} from '../../constants/store';

const allProblemBlocks = state => state.problemBlocks.data
const allProblemBlockInstances = state => state.problemBlockInstances.data

export const getProblemBlocks = createSelector(
  [allProblemBlocks],
  (allProblemBlocks) => {
      return allProblemBlocks
  })
  
export const getProblemBlockInstances = createSelector(
  [allProblemBlockInstances],
  (allProblemBlockInstances) => {
      return allProblemBlockInstances
  })

export const checkProblemBlocksReady = state => (state.problemBlocks.status === FETCH_STATUS.READY)

export const checkProblemBlockInstancesReady = state => (state.problemBlockInstances.status === FETCH_STATUS.READY)
