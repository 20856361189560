// Credit: https://github.com/richardhealy/react-redux-saga-firebase/blob/master/src/reducers/index.js
import { types } from '../actions'
//import { strings } from '../strings';
import { FETCH_STATUS } from '../../constants/store'


const initialState = {
    status: FETCH_STATUS.UNINITIALIZED, 
    error : null,
    data: {
        // get user data
    }
}

const userReducer = (state = initialState , action) => {
  switch (action.type) {
      case types.LOAD_USER.SUCCESS:
        return {
            ...state,
            status:FETCH_STATUS.READY,
            data : {
                user : {name: "User data over here"}  // *&*
            }
        }
    case types.USER_GOODBYE:
            return {
                ...initialState,
                status: FETCH_STATUS.READY, 
            }
      case types.CLEAR_USER:
          return initialState
    default:
        return state
  }
}

export default userReducer;

