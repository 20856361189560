import React from 'react';
import 'typeface-roboto';
import JSONFormContainer from '../BiggerBits/JSONFormContainer';
import * as yup from 'yup';


const formDataHeader = {
    questions: [
        {
            name: 'page_header___default_svg_settings___title',
            label: "Title",
            helperText: 'Worksheet Title',
            layoutOptions: { xs: 12, sm:12 },
        },
        {
            name: 'page_header___default_svg_settings___nameText',
            label: "Name Label",
            helperText: 'Example: Nombre',
            layoutOptions: { xs: 12 },
        },
        {
            name: 'page_header___default_svg_settings___dateText',
            label: "Date Label",
            helperText: 'Example: Fecha',
            layoutOptions: { xs: 12 },
        },
    ],
}

const validationSchemaHeader = yup.object().shape({

        page_header___default_svg_settings___title: yup.string()
                    .required( 'Required: Title' ),
        page_header___default_svg_settings___dateText: yup.string()
                    .required( 'Required: Date Text' ),
        page_header___default_svg_settings___nameText: yup.string()
                    .required( 'Required: Name Text' ),
             
    });


const SettingsWidget = (props) => {
    return (
        <JSONFormContainer 
            formData = {formDataHeader}
            validationSchema = {validationSchemaHeader}
            {...props} 
        />
    )
}

export default SettingsWidget;

