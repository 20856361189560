import React from 'react';
import Grid from '@material-ui/core/Grid';
import 'typeface-roboto';

import FormFieldCardParent from './FormFieldCard_Parent';
import RangeSlider from './RangeSlider';

class FormFieldCardRangeSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: this.props.values || [1,2] ,
            cardTitle: this.props.cardTitle || "Pass cardTitle prop",
            onValuesUpdated: this.props.onValuesUpdated,
            detailText: this.props.detailText 
          };
    }

    updateValue = (e) => {
        if (process.env.NODE_ENV === 'development') console.log("New values: ",e)
        this.setState({
          values: e,
        });
        return ((typeof this.state.onValuesUpdated === "function") ? this.state.onValuesUpdated(e) : null)
      }
    
    // detailText can be a string, but may also be a function that gets passed the current values
    resolveDetailToString = () => {
        //if (process.env.NODE_ENV === 'development') console.log(this.state.detailText)
        if (typeof this.state.detailText === "function") 
            return this.state.detailText(this.state.values);
        if (typeof this.state.detailText === "string") 
            return this.state.detailText;
        if (typeof this.state.detailText === "number") 
            return this.state.detailText.toString(10);
        if (typeof this.state.detailText !== "undefined")
            return  this.state.detailText.toString();
        return null;
    }


    render() {
        return (
            <FormFieldCardParent cardTitle={this.state.cardTitle} detailText={this.resolveDetailToString()} >
                <div style={{ marginLeft: 0, marginRight: 0, marginTop:0, marginBottom:0, flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div style={{ margin: 0 }}>
                            <RangeSlider
                                {...this.props}
                                values={this.state.values} 
                                onValuesUpdated={this.updateValue}
                            />
                        </div>
                    </Grid>
                </Grid>
                </div>
            </FormFieldCardParent>
        )
    }
}

export default FormFieldCardRangeSlider;
