export const USER_LOGIN_AND_LOAD_FINISHED = 'USER_LOGIN_AND_LOAD_FINISHED'

export const USER_LOGGING_IN = 'USER_LOGGING_IN'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED"

export const MEMBER_FETCHING = "MEMBER_FETCHING"
export const MEMBER_LOADED = "MEMBER_LOADED"
export const MEMBER_OUT = "MEMBER_OUT"
export const MEMBER_LOAD_FAILED = "MEMBER_LOAD_FAILED"

export const ALWAYS_NEVER_MIX = {
    ALWAYS: 'ALWAYS',
    NEVER: 'NEVER',
    MIX: 'MIX'
}

export const EQUATION_TYPES = {
    ADDITION: 'ADDITION',
    SUBTRACTION: 'SUBTRACTION',
    MULTIPLICATION: 'MULTIPLICATION',
    DIVISION: 'DIVISION'
  }
  
/*
export const ACTIVITIES_FETCHING = "ACTIVITIES_FETCHING"
export const ACTIVITIES_LOADED = "ACTIVITIES_LOADED"
export const ACTIVITIES_OUT = "ACTIVITIES_OUT"
export const ACTIVITIES_LOAD_FAILED = "ACTIVITIES_LOAD_FAILED"

export const MEMBER_ACTIVITIES_FETCHING = "MEMBER_ACTIVITIES_FETCHING"
export const MEMBER_ACTIVITIES_LOADED = "MEMBER_ACTIVITIES_LOADED"
export const MEMBER_ACTIVITIES_OUT = "MEMBER_ACTIVITIES_OUT"
export const MEMBER_ACTIVITIES_LOAD_FAILED = "MEMBER_ACTIVITIES_LOAD_FAILED"
*/