import React from 'react';
// MUI
import { withStyles } from '@material-ui/core/styles';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
//import IconButton from '@material-ui/core/IconButton';
//import Paper from '@material-ui/core/Paper';
//import Fab from '@material-ui/core/Fab';
// MUI Cards
//import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardHeader from '@material-ui/core/CardHeader';
/*
// MUI Lists
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
*/
// Icons
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
//import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
/*
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
*/

const styles = theme => ({
    faIcon: {
        fontSize: 18,
        margin: 0,
        padding: 0
        // padding if needed (e.g.,  2)
        // margin if needed
    },
    actions: {
		display: 'flex',
		minHeight: "60px" 
	},
    cardButtonRight: {
        marginLeft: 'auto',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
      },
    grow: {
        flexGrow: 1,
      },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
      },
})

const ElementDetails = (props) => {
    const {item, classes} = props;

    return (
    <React.Fragment>
        <CardActions disableSpacing>
            <Typography variant="h6">{"Element: " + item.value.toString()}</Typography>
            <div className={classes.grow} />
            <IconButton className={classes.cardButtonRight} aria-label="Delete selected element">
                    <FontAwesomeIcon   icon={faTrash} className={classes.faIcon} />
            </IconButton>
            <IconButton className={classes.cardButtonRight}  aria-label="Edit selected element">
                    <FontAwesomeIcon icon={faEdit} className={classes.faIcon} />
            </IconButton>
        </CardActions>

        <CardContent>
            <p>This item is</p>
            <p>{item.value}</p>
        </CardContent>

</React.Fragment>
)
}

export default withStyles(styles)(ElementDetails)

/*
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="Open drawer">
            <MenuIcon />
            </IconButton>
            <Fab color="secondary" aria-label="Add" className={classes.fabButton}>
            <AddIcon />
            </Fab>
            <div className={classes.grow} />
            <IconButton color="inherit">
            <SearchIcon />
            </IconButton>
            <IconButton edge="end" color="inherit">
            <MoreIcon />
            </IconButton>
            </Toolbar>
        </AppBar>
        */