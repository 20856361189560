import mainColor from '@material-ui/core/colors/blue'; // '@material-ui/core/colors/teal';
import accentColor from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';


// https://www.materialpalette.com/colors  (deep purple and yellow)
export const PALETTE = {
    primary: mainColor,
    accent: {
      ...accentColor,
      A400: '#f7ee8f',
    },
    error: red
  }


