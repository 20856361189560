import {put } from 'redux-saga/effects';
//import { push } from 'react-router-redux';

import {
  //types,
  logger
} from '../actions';

export function* logIt (data) {
    yield put(logger(data));
}

export function* logAction (data) {
    console.log("logAction data",data)
    yield put(logger(data));
}