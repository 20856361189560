import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux';
import { passwordChange } from '../../redux/actions'
import { FETCH_STATUS } from '../../constants/store'

import { withRouter, Redirect } from 'react-router-dom';
import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl  from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InHeader from '../App/Header/InHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'

import * as routes from '../../constants/routes';

import { StyledAuthPageDiv, StyledInputDiv, StyledErrorText } from './styles';

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null,
	showPassword: false,
	redirect: ''
};

class ChangePasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	passwordUpdate = (password) => {
		this.props.passwordChange(password)
	};

	onSubmit = (event) => {
		const { password } = this.state;
		this.passwordUpdate(password);
		event.preventDefault();
	};

	handleChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value });
	};

	handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	handleClickShowPasssword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};

	render() {
		// Not logged in? Redirect to Signin page
		if (!this.props.auth.data.loggedIn) {
			return <Redirect push to={routes.SIGN_IN} />;
		}

		return (
			<InHeader title="Change Password">
			<StyledAuthPageDiv>
				<Grid container>
					<Grid item xs={12}>
						<Grid container alignItems={'center'} direction={'column'} justify={'center'}>
							<Grid item>
								<Typography variant="h5" gutterBottom={false}>
									<b>Change Password</b>
								</Typography>
							</Grid>
							<StyledInputDiv>
								<div>
										<Grid container flexgrow={1}>
											<Grid item xs={12}>
												<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
													<FormControl style={{ paddingBottom: 14 }}>
														<InputLabel htmlFor="password">Password</InputLabel>
														<Input
															fullWidth
															id="adornment-password"
															type={this.state.showPassword ? 'text' : 'password'}
															value={this.state.password}
															onChange={this.handleChange('password')}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		onClick={this.handleClickShowPasssword}
																		onMouseDown={this.handleMouseDownPassword}
																	>
																		{this.state.showPassword ? (
																			<VisibilityOff />
																		) : (
																			<Visibility />
																		)}
																	</IconButton>
																</InputAdornment>
															}
														/>
													</FormControl>
												</Grid>
											</Grid>

											<Grid item xs={12}>
												<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
													<FormControl fullWidth style={{ paddingBottom: 24 }}>
														<InputLabel htmlFor="password2" fullWidth>
															Confirm Password
														</InputLabel>
														<Input
															fullWidth
															id="adornment-password2"
															type={this.state.showPassword ? 'text' : 'password'}
															value={this.state.password2}
															onChange={this.handleChange('password2')}
														/>
													</FormControl>
												</Grid>
											</Grid>

											<Grid item xs={12}>
												<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
													<FormControl
														fullWidth
														style={{ paddingTop: 22, paddingBottom: 14 }}
													>
														<Button
															fullWidth
															onClick={this.onSubmit}
															size="medium"
															variant="contained"
															color="primary"
															disabled={
																this.props.auth.status === FETCH_STATUS.PENDING ||
																!this.props.auth.data.loggedIn
															}
														>
															<FontAwesomeIcon icon={faKey} /> &nbsp; Change Password
														</Button>
													</FormControl>
												</Grid>
											</Grid>

											{this.props.auth.status === FETCH_STATUS.ERROR && (
												<Grid item xs={12}>
													<Grid container direction={'column'} justify={'center'} spacing={1}>
														<StyledErrorText style={{ paddingBottom: 12 }}>
															{this.props.auth.error}
														</StyledErrorText>
													</Grid>
												</Grid>
											)}
										</Grid>
								</div>
							</StyledInputDiv>
						</Grid>
					</Grid>
				</Grid>
			</StyledAuthPageDiv>
			</InHeader>
		);
	}
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

const mapDispatchToProps = { passwordChange }

export default compose(
	withRouter,
	connect(
	  mapStateToProps,
	  mapDispatchToProps
	)
  )(ChangePasswordForm);
