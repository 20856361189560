import React from 'react';
import 'typeface-roboto';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    const formatOptions = props["data-q"]["numberFormatOptions"];
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        {...formatOptions}
      />
    );
  }

function NumberFormatCustom(props) {
    //console.log("NFC props",props )
    const { inputRef, onChange, ...other } = props;
    const formatOptions = props["data-q"]["numberFormatOptions"];

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            {...formatOptions}
        />
    );
}

const FormTextField = (props) => {
    const {
        classes,
        errors,
        touched,
        handleChange,
    } = props;
    const readonly = props.readonly || false;
    const { q } = props;
    const gridXS = (q.layoutOptions && q.layoutOptions.xs) ? q.layoutOptions.xs : 12;
    const gridSM = (q.layoutOptions && q.layoutOptions.sm) ? q.layoutOptions.sm : gridXS;
    const isError = touched[q.name] && Boolean(errors[q.name]);
    const helperText = isError ? errors[q.name] : (Boolean(q.helperText) ? q.helperText : "")
    let inputProps = {};
    if (q.endAdornment) {
        inputProps["endAdornment"] = (
            <InputAdornment position="end">
                {q.endAdornment}
            </InputAdornment>)
    }
    if (q.numberFormatOptions) {
        inputProps["inputComponent"] = NumberFormatCustom;

    }
    if (q.textFormatOptions) {
        inputProps["inputComponent"] = TextMaskCustom;
    }
    if (readonly) {
        inputProps["readOnly"] = true;
    }
    //inputProps["data-q"] = q;
    return (
        <Grid item xs={gridXS} sm={gridSM} id={q.name} key={q.name} >
            <TextField
                data-q={q}
                id={"id-" + q.name}
                key={q.name}
                name={q.name}
                className={classes.textFieldRoot}
                label={q.label}
                helperText={helperText}
                onChange={handleChange(q.name)}
                margin="normal"
                value={props.values[q.name]}
                error={isError}
                type={q.type ? q.type : 'text'}
                inputProps={{ 'data-q': q }}   // Send data to the input component
                // eslint-disable-next-line
                InputProps={Object.keys(inputProps).length > 0 ? inputProps : undefined}
                /*
                InputLabelProps={{
                    FormLabelClasses: {
                        root: classes.labelRoot
                    }
                }}
                */

            />
        </Grid>
    )
}


export default FormTextField;