import React from 'react';
import 'typeface-roboto';
import Paper from '@material-ui/core/Paper';
import ItemWrapperMiddleTop from '../WeeBits/ItemWrapperMiddleTop';
import Grid from '@material-ui/core/Grid';
//import { styles } from '../MathPages/mathPageStyles';
import { withStyles } from '@material-ui/core/styles';

export const styles = (theme) => ({
    rootPaperMobile: {
		backgroundColor: "white",
		height: '100%'
	},
	rootPaperHidden: {
		backgroundColor: "transparent",
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		height: '100%'
	},
	cardNotMobile: { height: 'inherit', minWidth: '350px', maxWidth: '400px' },
	cardMobile:  { minWidth: '350px', height: '100%', width: '100%'}
});

const Page = (props) => {
    const {isMobile, classes} = props;
    if (!isMobile) {
        return (
            <Grid item xs>
                <Paper className={classes.rootPaperHidden} elevation={0}>
                    {ItemWrapperMiddleTop(props.children)}
                </Paper>
            </Grid>
    )} else {
        return (
            <Paper className={classes.rootPaperMobile} elevation={0}>
                {props.children}
            </Paper>
        )
    }
};

export default withStyles(styles)(Page)