import { put } from 'redux-saga/effects';
//import { FETCH_STATUS } from '../../constants/store'
//import { getNewKey } from '../../firebase/getNewKey'
import ThreeLineArithmetic from '../../components/ProblemDisplayClasses/ThreeLineArithmetic';
import { renderers } from '../../services/renderers';

import {
    //types,
    initializeRendererSuccess
  } from '../actions';
 
  
export function* initializeRendererSaga(action) {
      let theClass;

      // First see if it's a built-in renderer
      switch (action.payload.key) {
        case "ww.ThreeLineArithmetic":
            theClass = ThreeLineArithmetic   // This is the class itself
            break
        default:
            throw new Error("Unknown renderer: ",action.payload.key)
      }

      if (!renderers.isRendererInitialized(action.payload.key)) {
        // HERE Is where we look for the requested custom render class, 
        //   sanitize it, and return it.

        // Got a renderer class?
        if (typeof theClass === "undefined") {
            if (process.env.NODE_ENV === 'development') console.log("Unknown problem renderer: ",action.payload.key);
            return null
          }
          renderers.initializeRenderer(action.payload.key, theClass)
      }
      const renderer = renderers.getRenderer(action.payload.key)
      yield put(initializeRendererSuccess({
          key: action.payload.key,
          isBuiltIn : true,
          isEditable : false,
      }))
      return renderer
  }
  