import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { randomPalette } from '../constants/colors';


export const createTheme = (primaryColors = lightBlue, secondaryColors = deepPurple) => {
    return createMuiTheme({
        typography: {
          useNextVariants: true,
        },
        palette:  {
          primary: primaryColors,
          secondary: secondaryColors}
      });
}

export {
    lightBlue as defaultPrimaryColors,
    deepPurple as defaultSecondaryColors,
    randomPalette
}


export const createRandomTheme = () => {
    return createMuiTheme({
        typography: {
          useNextVariants: true,
        },
        palette:  {
          primary: randomPalette(),
          secondary: randomPalette()
        }
      });
}