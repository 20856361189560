import {  takeLatest, put, call } from 'redux-saga/effects';
import {problemGenerators} from '../../services/problemGenerators'
import {renderers} from '../../services/renderers'

import {
  types,
  logger,
  uiHello,
} from '../actions';

//import rsf from '../../firebase/rsf';

export function* watchHello() {
    yield takeLatest(types.USER_HELLO, syncUserDataSaga)
  }

export function* watchGoodbye() {
    yield takeLatest(types.USER_GOODBYE, clearUserDataSaga)
}

export function* syncUserDataSaga(action) {
    yield put(logger("NEW USER HELLO"))
    yield put(uiHello())
    /*
    const theUser = yield select(state => state.auth.data.user)
    console.log("HELLO NEW user: ",theUser.uid)

    // Start the sync saga
    let uiTask = yield fork(syncUiSaga,theUser.uid)

    yield take(types.USER_GOODBYE)  // Wait for user to log out
    console.log("Goodbye user's UI data")
    yield cancel(uiTask)           // Cancel the sync task
    yield put(resetUiData())       // Clear UI data
    */
}

export function* clearUserDataSaga(action) {
    // Reset services data (stored in singletons)
    yield call(problemGenerators.reset)
    yield call(renderers.reset)
}
