import React from 'react';
import * as routes from '../../constants/routes';
import { connect } from 'react-redux'
import { setRandomColorTheme } from '../../redux/actions'

import { withRouter, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import burger_menu from '../../resources/help/burger_menu.JPG';
import problem_setup from '../../resources/help/problem_setup.JPG';
import { faHandPeace } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button';

import preview_worksheet from '../../resources/help/preview_worksheet.JPG';
import print_worksheet from '../../resources/help/print_worksheet.JPG';
import random_problem from '../../resources/help/random_problem.JPG';
import randomize_worksheet from '../../resources/help/randomize_worksheet.JPG';
import show_answers from '../../resources/help/show_answers.JPG';
import worksheet_setup from '../../resources/help/worksheet_setup.JPG';
import login from '../../resources/help/login.JPG';


import HelpCard from './HelpCard';


const INITIAL_STATE = {
	redirect: ''
};


const styles = theme => ({
	fab: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	faIcon: {
		fontSize: 18,
		margin: 0,
		padding: 0
		// padding if needed (e.g., theme.spacing(2))
		// margin if needed
	},
	img: {
		maxWidth: "100%",
		height: "auto"
	}
});

class Intro extends React.Component {

	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	tryIt = [
		{
			description: "Logging in",
			icon: <FontAwesomeIcon icon={faKey} className={this.props.classes.faKey} fixedWidth />,
			image: <img width="350" height="auto" src={login} alt="Logging in" />,
			details: "Log in to change the color theme and if you want to save any customizations you make. You " +
				"don't need to log in if you just want to make a one time worksheet."
		},
		{
			description: "Open up the main menu",
			icon: <FontAwesomeIcon icon={faBars} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={burger_menu} alt="Opening the main menu" />,
			details: "Use the menu button at the top left to explore the site.",
			//expansionDetails: "The menu provides..."
		},
		{
			description: "Set up your problems",
			icon: <FontAwesomeIcon icon={faCalculator} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={problem_setup} alt="Setting up the problems" />,
			details:
				'Navigate to a Worksheet Designer from the main menu ("Addition", for example). ' +
				'Next, set up the problems you want on your Worksheet. For Addition problems (just as an example) you may want to ' +
				'add 3-4 digit numbers and you may or may not want those problems ' +
				'to feature Carrying (i.e, 9 + 5 features Carrying because you have to "carry the 1" to ' +
				'get 14.)',
		},
		{
			description: 'Randomize the Sample Problem',
			icon: <FontAwesomeIcon icon={faRandom} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={random_problem} alt="Randomize the sample problem" />,
			details: "Press the 'Randomize Sample Problem' shuffle button in the problem setup area " +
				"to generate a new sample problem."
		},
		{
			description: "Set up your Worksheet",
			icon: <FontAwesomeIcon icon={faFile} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={worksheet_setup} alt="Worksheet setup" />,
			details: "Set up the worksheet dimensions (how many rows and columns of problems " +
				"to display) and any other Worksheet settings that are available on the Worksheet Designer " +
				"page you select."
		},
		{
			description: "Toggle to display Answers",
			icon: <FontAwesomeIcon icon={faCheck} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={show_answers} alt="Show answers" />,
			details: "Use the 'Show Answers' switch to display answers on both your " +
				'worksheet and the sample problem. You can print your worksheet without answers ' +
				'then turn on Show Answers and print it again.'
		},
		{
			description: "Preview your worksheet",
			icon: <FontAwesomeIcon icon={faEye} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={preview_worksheet} alt="Preview worksheet" />,
			details: "The Worksheet preview area displays what your worksheet will look like and " +
				'features action buttons to Randomize the worksheet and to Print it out.'
		},
		{
			description: "Randomize your Worksheet",
			icon: <FontAwesomeIcon icon={faRandom} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={randomize_worksheet} alt="Randomize worksheet" />,
			details: "Press the 'Randomize Worksheet' shuffle button under the Worksheet preview area " +
				"to generate new problems."
		},
		{
			description: "Print your Worksheet",
			icon: <FontAwesomeIcon icon={faPrint} className={this.props.classes.faIcon} fixedWidth />,
			image: <img width="350" height="auto" src={print_worksheet} alt="Print worksheet" />,
			details: "To print to PDF, just select the native Print to PDF option from your browser. " +
				"Once you print, you can toggle 'Show Anwers' and print again. The Worksheet will not " +
				"randomize the problems unless you change your problem settings or press Randomize Worksheet."
		},
	]


	myHelpItem = (item) => {
		return (
			<div key={item.description} style={{ marginLeft: 10 }}>
				<HelpCard key={item.description} {...item} />
			</div>
		)
	}

	doRedirect = (path) => {
		this.setState({ redirect: path });
	}

	handleNewColor = () => {
		//this.uiStore.randomColor();
		this.props.setRandomColorTheme();
	}

	render() {
		const { classes } = this.props;

		// If state says I should redirect somewhere...
		if (typeof this.state.redirect === 'string' && this.state.redirect.length > 0) {
			return <Redirect push to={this.state.redirect} />;
		}

		return (
			<div>
				<h4>Shortcuts</h4>
				<div>
					{(!this.props.auth.data.loggedIn) &&
						<Fab onClick={() => this.doRedirect(routes.SIGN_IN)} color="secondary" aria-label="Log in" className={classes.fab}>
							<FontAwesomeIcon icon={faKey} className={classes.faIcon} fixedWidth />
						</Fab>
					}
					<Fab onClick={() => this.doRedirect(routes.ARITHMETIC_ADD)} color="primary" aria-label="Addition" className={classes.fab}>
						<FontAwesomeIcon icon={faPlus} className={classes.faIcon} fixedWidth />
					</Fab>
					<Fab onClick={() => this.doRedirect(routes.ARITHMETIC_SUBTRACT)} color="primary" aria-label="Addition" className={classes.fab}>
						<FontAwesomeIcon icon={faMinus} className={classes.faIcon} fixedWidth />
					</Fab>
					<Fab onClick={() => this.doRedirect(routes.ARITHMETIC_MULTIPLY)} color="primary" aria-label="Addition" className={classes.fab}>
						<FontAwesomeIcon icon={faTimes} className={classes.faIcon} fixedWidth />
					</Fab>
				</div>



				<h4>Help Topics</h4>
				<div>
					{this.tryIt.map((item) => this.myHelpItem(item))}
				</div>
				<br />
				<Button fullWidth size="medium" variant="contained" color="primary"
					onClick={this.handleNewColor}
				>
					<FontAwesomeIcon icon={faHandPeace} />  &nbsp; New Color Theme
							</Button>
			</div>
		)
	}
}
Intro = withRouter(Intro);
Intro = withStyles(styles)(Intro);

const mapStateToProps = (state) => ({
	auth : state.auth,
	ui : state.ui
})

const mapDispatchToProps = { setRandomColorTheme }

export default 
	connect(
			mapStateToProps,
			mapDispatchToProps
)(Intro);

