import Grid from '@material-ui/core/Grid';
import React from 'react';

export default function FormItemWrapper(formItem) {
	return (
		<Grid container spacing={0} direction="column" alignItems="center" justify="flex-start">
			<Grid item xs={12}>
				<div>{formItem}</div>
			</Grid>
		</Grid>
	);
}
