import {getNewKey} from '../../firebase/getNewKey';
import {GeneratedProblemSchema, ProblemSchema} from '../../redux/schemas';
import { StructError } from 'superstruct'

export default class ProblemGeneratorClass {
  _gen = undefined;
  
  _settings = {  } 
  _problem_type = "UNKNOWN";
  _batch_maker = undefined;
  _errorHandler = undefined;
  _error_data = null;

  constructor(problem_type, initial_settings, batch_maker, error_handler) {
    this._problem_type = problem_type;
    this._batch_maker = batch_maker;
    this._errorHandler = error_handler;
    this.settings = initial_settings || {};
  }

  set settings(values) {
    Object.assign(this._settings, values);
    /* We could remove metadata settings for the generator. But ... why?
    const keys = Object.keys(schema)
    const keepKeys = keys.filter(k => k[0]!=="_")
    let finalSettings = {}
    keepKeys.forEach(k => finalSettings[k]=schema[k])
    console.log("About to generate problems with settings: ",this._settings)
    */
    this._gen = generateProblems(this.settings, this._batch_maker, this.problem_type, this.handleProblemErrors);
  }

  get settings() {
    return this._settings;
  }

  info() {
    let info = "Problem Type: "+ this.problem_type + "\n" +
      JSON.stringify(this.settings, null, 4);
    return info;
  }

  get problem_type() {
    return this._problem_type;
  }

  next() {
    if (!this._gen) {
      this._gen = generateProblems(this.settings, this._batch_maker, this.problem_type, this.handleProblemErrors);
    }
    if (this.isError) return null; 
    return this._gen.next().value;
  }

  get isError() {
      return (this._error_data && this._error_data.isError)
  }

  get isWarning() {
    return (this._error_data && this._error_data.isWarning)
}

  get error_data() {
      return this._error_data;
  } 

  // Callback to provide feedback on problem generator warnning(s) and error(s)
  handleProblemErrors = (error_data) => {
      if (process.env.NODE_ENV === 'development') console.log("Generated problems have warnings or errors: ",error_data);
      this._error_data = error_data;
      if (typeof this._errorHandler === 'function') this._errorHandler(error_data);
  }
}

function * generateProblems(settings, batch_maker, problem_type, handleErrors) {
  let {batch_size} = settings;
  if (batch_size > 250) {
    batch_size = 250;
    settings.batch_size = 250;
  }
  
  let i = 0;
  let thisBatch;
  while (true) {
    if (i % batch_size === 0) {
      const tempBatch = batch_maker(settings); // _polishBatch(_makeBatch(settings));
      const test =_checkBatch(tempBatch, problem_type);
      if (test.isError || test.isWarning) {
          handleErrors(test);
          if (test.isError) yield null;
      };
      if (!test.isError)
      thisBatch = _polishBatch(tempBatch, problem_type)
      i = 0;
    }
    const testOne = ProblemSchema.validate(thisBatch[i]);
    if (testOne instanceof StructError) {
        // NOTE: we only call the error handler when the whole batch is tested
        if (process.env.NODE_ENV === 'development') console.log(`${problem_type} generator error: a generated problem is not formatted right. Details: ` +
            testOne.toString());
        yield null;
      } 
    yield thisBatch[i];
    i++;
  }
}

function _checkBatch(problems, problem_type) {
  let warnings = [];
  let errors = [];
  problems.forEach(function(problem) {
    if (typeof problem !== "object")
      errors.push(`${problem_type} generator error: a generated problem is "${typeof problem}" but should be a JSON object.`)
    if (problem.hasOwnProperty("id") && problem["id"])
      warnings.push(`${problem_type} generator warning: a generated problem has data in an "id" field which will be overwritten.`)
      if (problem.hasOwnProperty("problem_type") && problem["problem_type"] !== problem_type)
      warnings.push(`${problem_type} generator warning: a generated problem has data in an "problem_type" field which will be overwritten to "${problem_type}".`)

      const valid = GeneratedProblemSchema.validate(problem);
      if (valid instanceof StructError) {
        errors.push(`${problem_type} generator error: a generated problem is not formatted right. Details: ` +
            valid.toString())
      } 
  })
  return ({
      warnings: warnings,
      errors : errors,
      isWarning : warnings.length > 0,
      isError : errors.length > 0,
  })

}


function _polishBatch(problems, problem_type) {
  let results = [];
  problems.forEach(function(problem) {
      results.push(Object.assign({}, problem, {
          type: problem_type,
          id : getNewKey(), 
      }))
  })
  const reordered = results.sort((a, b) => (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0)
  return reordered;
}