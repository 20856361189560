import { createSelector } from 'reselect'
import { createTheme } from '../../logic/uiLogic'

const getPrimaryColors = state => state.ui.data.primaryColors;
const getSecondaryColors = state => state.ui.data.secondaryColors;

export const getTheme = createSelector(
  [getPrimaryColors, getSecondaryColors],
  (primaryColors, secondaryColors) => {
      return createTheme(primaryColors, secondaryColors)
  })

 //export const getUiHello = state => state.ui.data.secondaryColors;


export const getUiHello = state => state.ui.session.hello;

export const getUiGoodbye = state => state.ui.session.goodbye;