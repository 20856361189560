import {   select, fork, take, cancel, put, call } from 'redux-saga/effects';
//import { FETCH_STATUS } from '../../constants/store'
import { getNewKey } from '../../firebase/getNewKey'
import {DEFAULT_PAGE_LAYOUTS} from '../../constants/layoutconstants';
import {merge} from 'lodash';
import {PageLayoutSchema} from '../schemas';
import rsf, {firebaseApp as firebase} from '../../firebase/rsf';
  
import {
    types,
    worksheetLayoutSuccess,
    syncWorksheetLayoutSuccess,
    syncWorksheetLayoutCollectionSuccess,
    apiError
  } from '../actions';
 

// Action payload at least needs to have the "key"
// 
export function* initializeWorksheetLayoutSaga(action) {
      let theObject;

      if (typeof action.payload.key !== "string") 
        throw new Error("initializeWorksheetLayout needs a Worksheet Layout key")

      // If a worksheet with this key is already initialized, set READY and take no action
      let id = yield select(state => (state.worksheetLayouts.data.keys_to_ids[action.payload.key]))
      if (typeof id === "string") {
        yield put({type: types.WORKSHEET_LAYOUT_READY})
        return;
      }
      
      // First see if it's a built-in Page Layout Key
      if (typeof DEFAULT_PAGE_LAYOUTS[action.payload.key] === "object") {
        // If so, we merge the payload values on top of the default values
        theObject = merge({},DEFAULT_PAGE_LAYOUTS[action.payload.key],action.payload)
      } else {
        // Otherwise, we just have the payload to work with
        theObject = action.payload
      }

      // If there's no id on the object, generate one
      if (typeof theObject.id === "undefined")
        theObject["id"] = getNewKey()

      // Validate the new object, or throw an error
      PageLayoutSchema(theObject)      

      yield put(worksheetLayoutSuccess(theObject))
  }
  

// Record updates to the doc
export function* updateWorksheetLayoutSaga(action) {
  //console.log("updateWorksheetLayoutSaga action",action)  // updateWorksheetLayoutSaga
  const isLoggedIn = yield select(state => state.auth.data.loggedIn)

  // Get the id for the instance
  let id = action.payload.id;
  if (!id && action.payload.key) {
      id = yield select(state => (state.worksheetLayouts.data.keys_to_ids[action.payload.key]))
  }
  if (!id) {
      console.log("Action yielding error: ", action)
      throw new Error("No id or valid key provided")
  }
  const uid = yield select(state => state.auth.data.user.uid)

  // Worksheet from state
  const worksheet = yield select(state => state.worksheetLayouts.data.worksheetLayouts[id])
  // Merge data from the action over top of the state data to produce a successData object
  const successData = merge({},worksheet, action.payload, 
    { 
      id: id,
      uid: uid,
    }
  )
  //yield put(syncWorksheetLayoutSuccess(successData)) 
  //console.log("successData: ",successData)

  if (isLoggedIn) {
      // Save changes
      

      yield call(
          rsf.firestore.setDocument,
          'worksheetlayout/'+ successData.id,
          successData
      );

  } else {
    yield put(syncWorksheetLayoutSuccess(successData)) 
  }
}

// Subscribe to the UI channel and send updates
export function* syncWorksheetLayoutSaga(uid) {
    while (true) {
      // console.log("Start cycle for syncWorksheetLayoutSaga")
      // Wait til we are logged in
      let isLoggedIn = yield select(state => state.auth.data.loggedIn)
      while (!isLoggedIn) {
          yield take(types.USER_HELLO)  // Wait for a new UI sync signal
          //console.log("   syncWorksheetLayoutSaga USER_HELLO")
          isLoggedIn = yield select(state => state.auth.data.loggedIn)
      }

      const uid = yield select(state => state.auth.data.user.uid)

      const colRef = firebase.firestore().collection("worksheetlayout")
      //const things = call(rsf.firestore.getCollection, colRef)
      //console.log("got colRef: ",colRef)

      const task = yield fork(
        rsf.firestore.syncCollection,
          colRef.where("uid", "==", uid),
          { 
              successActionCreator: syncWorksheetLayoutCollectionSuccess,
              failureActionCreator: apiError
          }
      )

      //console.log("SYNC UI TASK: ",task)

      yield take(types.USER_GOODBYE)

      //console.log("USER_GOODBYE: no longer syncing UI")

      yield cancel(task)
  }
}


// 