import { combineReducers } from 'redux'

import auth from './authReducer';
import ui from './uiReducer';
import log from './logReducer';
import user from './userReducer';
import problemBlocks from './problemBlocksReducer'
import problemBlockInstances from './problemBlockInstancesReducer'
import renderers from './rendererReducer'
import worksheetLayouts from './worksheetLayoutReducer'

  const reducer = combineReducers({
    log,
    auth,
    user,
    ui,
    problemBlocks,
    problemBlockInstances,
    renderers,
    worksheetLayouts
  })
  
  export default reducer;