
import React from 'react';
import InHeader from './App/Header/InHeader';

const myText = "<p>Have fun using this app. Please let bored children go do something else and come back to this when it seems more interesting.</p>";

class TermsOfService extends React.Component {
    render(){
        return (
            <InHeader title="Terms of Service">
                <h2>Terms of Service</h2>
                <div dangerouslySetInnerHTML={{__html: myText}}>
                </div>
                <p>
                As the site gets developed for you to create original work, we'll find someone to help us write the terms properly. Basically:
                Your work is your work, unless you choose to make it public through a "publish" option. For example, you might design a new problem,
                worksheet or whole course, and just keep it to yourself. That will be yours. If you decide to publish something, anyone can use and
                incorporate it into their worksheets. We aren't going to make the rules a lot more complicated than that, but I suspect there
                is a more formal way to write such things.
                </p>
                <p>This website itself (unlike your user-created content) is copyright (c) 2019 WorksheetWorkshop.com. All rights reserved.</p>
            </InHeader>
        );
    }
}

export default TermsOfService;