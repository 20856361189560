import React from 'react';
import PropTypes from "prop-types";
import Worksheet from './Worksheet';
import { DEFAULT_PROBLEM_BLOCK_MATRIX_LAYOUT} from '../../constants/layoutconstants';
import {DEFAULT_PAGE_LAYOUT} from '../../constants/layoutconstants';
import _ from 'lodash';

// Layout key, initial layout data, problemRendererName
const TheWorksheet = (props) => {

    const { layout, problemRendererName } = props;
    const {problems} = props; // For redux update
  
    const justLayout = props.pageLayout || DEFAULT_PAGE_LAYOUT;
    const additionalWorksheetInfo = 
        {
            context: {
                school : "Worksheet Workshop"
                // course, unit, section
                // teacher
                // classroom
                // school
            },
            publication : {
                is_published: true,
                original_publisher_uid : null,
                publication_permission_tags : ["everyone"],
                date_published :  new Date('2019-04-10T00:00:00')
            },
            blocks: [
                {
                    // Data from the parent block (block settings that apply to every instance of the block)
                    //problem_generator_settings: problemBlockWorksheet.problemBlock.generator_settings,
                    //problem_block: problemBlockWorksheet.problemBlock,
                    problem_renderer_name: problemRendererName,
                    // The problem set lives in the block instance
                    problems: problems, //problemBlockWorksheet.problems,
                    // Layout type: matrix is a rectangular matrix
                    layout_type: layout.layout_type,
                    // Layouts live in the block instances
                    layout_data: layout
                }
            ],
            version: '0.0.0.1'
        };
    
    //console.log("additionalWorksheetInfo: ",additionalWorksheetInfo)
    const pageLayout1 = _.merge({},justLayout,additionalWorksheetInfo)
    //console.log("Page layour for Worksheet: ",pageLayout1)
    let {pageLayout, ...otherProps} = props;

    return (
        <div>
            <Worksheet pageLayout={pageLayout1} {...otherProps} />
        </div>
    );
};

TheWorksheet.propTypes = {
    layoutKey : PropTypes.string,
    initialLayoutData : PropTypes.object,
    problemRendererName : PropTypes.string,
    problemBlockInstanceKey : PropTypes.string,
    worksheetKey : PropTypes.string,
};


// Same approach for defaultProps too
TheWorksheet.defaultProps = {
    initial_layout_data: DEFAULT_PROBLEM_BLOCK_MATRIX_LAYOUT
};

export default TheWorksheet
