import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux';
import { logout } from '../../../redux/actions'

import * as routes from '../../../constants/routes';
import { withRouter, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
//import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// For Style overrides
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import NavigationDrawer from './NavigationDrawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



const StyledHeaderIconButton = styled(IconButton)`
  && {
    color: white;
  }
`;

const drawerWidth = 250;

const styles = theme => ({
  root: {
	//	display: 'flex',
//		height:'100%',
//		width:'100%',
		height: '100%',
		width: '100%',
		position: 'relative',
		top: 0,
		left: 0,
		//overflow:'auto'
  },
  appBar: {
		zIndex: theme.zIndex.drawer + 201,
		flexWrap: 'wrap',
		width: '100%'
	},
	rightMenu: {
		zIndex: 20000 //theme.zIndex.drawer +20 //+ 301,
	},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
});


export class Header extends Component {

	state = {
		redirect: null,
		anchorEl: null,
		sideDrawerOpen: false
	};

	handleChange = (event) => {
		this.setState({ auth: event.target.checked });
	};

	handleMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleLogin = () => {
		this.setState({ redirect: routes.SIGN_IN });
	};

	handleLogout = () => {
		this.props.logout();
		this.setState({ redirect: routes.HOME });
	};

	handleProfile = () => {
		this.setState({ redirect: routes.HOME });
	};

	handleChangePassword = () => {
		this.setState({ redirect: routes.CHANGE_PASSWORD });
	};

	toggleDrawer = (open) => () => {
		this.setState({
			sideDrawerOpen: open
		});
	};

	onChangeTabs = (event, newValue) => {
		if (!this.props.headerTabsCallback) {
			if (process.env.NODE_ENV === 'development') console.log("No callback to change tabs")
			return;
		}
		this.props.headerTabsCallback(newValue);
	}

	render() {
		const auth = this.props.auth.data.loggedIn; 

		const { anchorEl, redirect } = this.state;
		const { classes, children} = this.props;
		const open = Boolean(anchorEl);

		if (redirect) {
			return <Redirect push to={redirect} />;
		}

		const headerText = this.props.headerText || "Worksheet Workshop";
		const suppressPadding = this.props.suppressPadding || false;
		const headerTabs = this.props.headerTabs || null;
		const headerTabsValue  = this.props.headerTabsValue;

		return (
			<div className={classes.root}>
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton
							styles={{ marginLeft: -12, marginRight: 20 }}
							color="inherit"
							aria-label="Menu"
							onClick={this.toggleDrawer(true)}
						>
							<MenuIcon />
						</IconButton>

						<Typography variant="h6" color="inherit" style={{ flex: 1, marginLeft: 4 }}>
							{headerText}
						</Typography>
						{auth && (
							<div>
								<StyledHeaderIconButton
									aria-owns={open ? 'menu-appbar' : undefined}
									aria-haspopup="true"
									onClick={this.handleMenu}
								>
									<AccountCircle />
								</StyledHeaderIconButton>
								<Menu
									style={{zIndex: 20000}}
									className={classes.rightMenu}
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
									open={open}
									onClose={this.handleClose}
								>
									<MenuItem onClick={this.handleProfile}>Profile</MenuItem>
									<MenuItem onClick={this.handleChangePassword}>Change Password</MenuItem>
									<MenuItem onClick={this.handleLogout}>Sign Out</MenuItem>
								</Menu>
							</div>
						)}
						{!auth && (
							<Button color="inherit" onClick={this.handleLogin}>
								Login
							</Button>
						)}
					</Toolbar>
					{headerTabs && 
						<AppBar position="static" color="default">
						<Tabs onChange={this.onChangeTabs} value={headerTabsValue} style={{width:"100%"}} indicatorColor="primary" textColor="primary" color='white'>
							{headerTabs.map(tabTitle => (<Tab label={tabTitle} key={tabTitle} />))}
						</Tabs>
						</AppBar>
					}
				</AppBar>
				<NavigationDrawer isOpen={this.state.sideDrawerOpen} toggleDrawer={this.toggleDrawer} tabsInHeader={headerTabs ? true : false} />
				{!suppressPadding &&
					<div className={classes.content}>
						<div className={classes.toolbar} />
						{children}
					</div>
				}
				{suppressPadding && 
					<div >
					<div className={classes.toolbar} />
					{children}
					</div>
			}
			</div>
		);
	}
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};
Header = withStyles(styles)(Header)


const mapStateToProps = (state) => ({
    auth : state.auth
})

const mapDispatchToProps = { logout}

export default compose(
	withRouter,
	connect(
	  mapStateToProps,
	  mapDispatchToProps
	)
  )(Header);