//import './stylesinit.js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { App } from './components/App';

//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

unregister();
//registerServiceWorker();
