// Credit: https://github.com/richardhealy/react-redux-saga-firebase/blob/master/src/reducers/index.js
import { types } from '../actions'
//import { strings } from '../strings';
import produce from 'immer'
import { FETCH_STATUS } from '../../constants/store'

const initialState = {
    status: FETCH_STATUS.UNINITIALIZED,
    error: null,
    data: {
        log: []
    }
}

const logReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.API_ERROR:
        case types.LOG:
            newState = produce(state, draft => {
                draft.status = FETCH_STATUS.READY
                draft.error = null
                draft.data.log.push({
                    timestamp: Date.now(),
                    message: action.payload.message
                })
            })
            return newState
        case types.USER_GOODBYE:
                return initialState
        case types.CLEAR_LOG:
            return {
                ...initialState,
                status: FETCH_STATUS.READY,
            }
        default:
            return state;
    }
}

export default logReducer;
