import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux';
import { loginWithEmail, registerWithEmail, authErrorViewed } from '../../redux/actions'
import { FETCH_STATUS } from '../../constants/store'

import { withRouter, Redirect } from 'react-router-dom';
import 'typeface-roboto';
import styled from 'styled-components';


import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';


import FormControl from '@material-ui/core/FormControl';
//import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

import * as routes from '../../constants/routes';
import { withSnackbar } from 'notistack';

const StyledInputDiv = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  background: #eff3f9;
  margin: 0 auto;
  min-width: 250px;
`;

const byPropKey = (propertyName, value) => () => ({
	[propertyName]: value
});

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null,
	showPassword: false,
	redirect: '',
	screen_mode: 'sign-in'
};


class SignInForm extends Component {

	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}

	static getDerivedStateFromProps(props, state) {
		if (
			props.auth.status === FETCH_STATUS.ERROR &&
			props.auth.errorData && 
			props.auth.errorData.viewed === false
		) {
			// If there's an unviewed auth error, show it and mark it "viewed"
			//alert(props.auth.errorData.message)
			props.enqueueSnackbar(props.auth.errorData.message, {
				variant: 'warning',
				autoHideDuration: 5000,
			});
			props.authErrorViewed()
		}
		return null
	}

	loginWithEmailAndPassword = (email, password) => {
		this.props.loginWithEmail(email, password)
	}

    createWithEmailAndPassword = (email, password) => {
		this.props.registerWithEmail(email, password)
	}

	onSubmit = (event) => {
		const { email, password } = this.state;
		if (this.state.screen_mode === 'sign-in') this.loginWithEmailAndPassword(email, password);
		else this.createWithEmailAndPassword(email, password);

		event.preventDefault();
	};


	handleChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value });
	};

	handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	handleClickShowPasssword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};

	handleRedirectTo = (target) => {
		this.setState({ redirect: target });
	};

	handleToggleMode = () => {
		const newMode = this.state.screen_mode === 'sign-in' ? 'sign-up' : 'sign-in';
		this.setState({ screen_mode: newMode });
	};

	resetPassword = () => {
		this.handleRedirectTo(routes.PASSWORD_FORGET)
	}


	render() {
		// const { email, password, password2, error } = this.state;
		const { email, password, password2 } = this.state;
		const sessionPending = (this.props.auth.status === FETCH_STATUS.PENDING)


//		if (process.env.NODE_ENV === 'development') console.log("Sign-In Form")    //*&*

		// If state says I should redirect somewhere...
		if (typeof this.state.redirect === 'string' && this.state.redirect.length > 0) {
			return <Redirect push to={this.state.redirect} />;
		}

		// Already logged in? Redirect to Home page
		if (this.props.auth.data.loggedIn) {
			return <Redirect push to={routes.HOME} />;
		}

		const isInvalid =
			this.state.screen_mode === 'sign-in'
				? password === '' || email === ''
				: password !== password2 || password === '' || email === '';

        return (
			<StyledInputDiv>

				<div>
					<div>
						<Grid container flexgrow={1}>
							<Grid item xs={12}>
								<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
									<FormControl fullWidth style={{"paddingBottom":14}}>
										<InputLabel htmlFor="email-simple">
											Email
										</InputLabel>
										<Input
											id="email-simple"
											value={email}
											fullWidth
											onChange={(event) => this.setState(byPropKey('email', event.target.value))}
										/>
									</FormControl>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
									<FormControl fullWidth style={{"paddingBottom":14}}>
										<InputLabel htmlFor="password">
											Password
										</InputLabel>
										<Input
											fullWidth
											id="adornment-password"
											type={this.state.showPassword ? 'text' : 'password'}
											value={this.state.password}
											onChange={this.handleChange('password')}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														onClick={this.handleClickShowPasssword}
														onMouseDown={this.handleMouseDownPassword}
													>
														{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
							</Grid>

							{this.state.screen_mode === 'sign-up' && (
								<Grid item xs={12}>
									<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
										<FormControl fullWidth style={{"paddingBottom":24}}>
											<InputLabel htmlFor="password2" fullWidth>
												Confirm Password
											</InputLabel>
											<Input
												fullWidth
												id="adornment-password"
												type={this.state.showPassword ? 'text' : 'password'}
												value={this.state.password2}
												onChange={this.handleChange('password2')}
											/>
										</FormControl>
									</Grid>
								</Grid>
							)}


							{this.state.screen_mode === 'sign-in' && (
								<Grid item xs={12}>
									<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
										<FormControl fullWidth style={{"paddingTop": 22, "paddingBottom":14}}>
											<Button
												fullWidth
												onClick={this.onSubmit}
												size="medium"
												variant="contained"
												color="primary"
												disabled={sessionPending || isInvalid}
											>
												<FontAwesomeIcon icon={faSignInAlt} /> &nbsp; Sign In
											</Button>
										</FormControl>
									</Grid>
								</Grid>
							)}

							{this.state.screen_mode === 'sign-up' && (
								<Grid item xs={12}>
									<Grid container direction={'column'} justify={'flex-start'} spacing={1} >
										<FormControl fullWidth style={{"paddingBottom":14}}>
											<Button
												fullWidth
												onClick={this.onSubmit}
												size="medium"
												variant="contained"
												color="primary"
												disabled={sessionPending || isInvalid}
											>
												<FontAwesomeIcon icon={faUserPlus} /> &nbsp; Sign Up
											</Button>
										</FormControl>
									</Grid>
								</Grid>
							)}

							{/*
								this.props.auth.status === FETCH_STATUS.ERROR 
							(
                                <Grid item xs={12}>
                                    <Grid container direction={'column'} justify={'center'} spacing={1}>
                                        <StyledErrorText style={{"paddingBottom":12}}>{this.props.auth.error}</StyledErrorText>
                                    </Grid>
                                </Grid>  
                            ) */}

							{this.state.screen_mode === 'sign-in' && (
								<Grid item xs={12}>
									<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
										<Grid item xs={12}>
											<FormControl fullWidth>
												<Button
													fullWidth
													size="medium"
													color="primary"
													disabled={sessionPending}
													onClick={this.handleToggleMode}
												>
													<u>Create a new account</u>
												</Button>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControl fullWidth>
												<Button
													fullWidth
													size="medium"
													color="primary"
													disabled={sessionPending}
													onClick={this.resetPassword}
												>
													<u>Forgot your password?</u>
												</Button>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
							)}

							{this.state.screen_mode === 'sign-up' && (
								<Grid item xs={12}>
									<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
										<FormControl fullWidth>
											<Button
												fullWidth
												size="medium"
												color="primary"
												disabled={sessionPending}
												onClick={this.handleToggleMode}
											>
												<u>Sign In with Email</u>
											</Button>
										</FormControl>
									</Grid>
								</Grid>
							)}
						</Grid>
					</div>
				</div>
			</StyledInputDiv>
		);
	}
}


const mapStateToProps = (state) => ({
    auth : state.auth
})

const mapDispatchToProps = { loginWithEmail, registerWithEmail, authErrorViewed }

export default compose(
	withRouter,
	connect(
	  mapStateToProps,
	  mapDispatchToProps
	)
  )(withSnackbar(SignInForm));