import React from 'react';
import 'typeface-roboto';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
// For context, because this object get remounted by its parents
//import hash from 'object-hash'
import { withRouter } from 'react-router-dom';
import FormTextField from './JSONFormFields/JSONFormTextField'
import _has from 'lodash/has';
import _xor from 'lodash/xor';
import _isEqual from 'lodash/isEqual'
import { formPropsToArray } from '../../services/formHelpers'

export const styles = (theme) => ({
    mainCard: {
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //color: 'white',
        padding: 0,
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
    },
    root: {
        flexGrow: 1,
    },
    rootMobile: {
        flexGrow: 1, //height: '100%',
    },
    textFieldRoot: {
        //marginTop: 0,
        padTop: 0,
        // padLeft: 20,
        // padRight: 20,
        //        marginBottom: 30,
        marginTop: 10,
        marginBottom: 10,
        //width: '100%',
        minWidth: 100
    },
    fieldDivRoot: {
        marginLeft: 20,
        marginRight: 20
    },
    paperRoot: {
        padding: 16,
    },
    paperCardRoot: {
        maxWidth: 400,
    },
    paperCardMain: {
        padding: 0
    },
    labelRoot: {
        fontWeight: "bold",
        color: "black",
        width: '100%',
        shrink: false
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    actions: {
        display: 'flex',
        //backgroundColor : theme.palette.grey["200"],
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    }
})

const stripMeta = (obj) => {
    const keys = Object.keys(obj)
    const keepKeys = keys.filter(k => k[0]!=="_")
    let finalObj = {}
    keepKeys.forEach(k => finalObj[k]=obj[k])
    return finalObj
}

const SettingsForm = props => {
    //console.log("Settings Form props: ", props);
    const {
        classes,
        values, // { name, email, password, confirmPassword },
        //errors,
        //touched,
        //handleChange,
        isValid,
        isValidating,
        isSubmitting,
        dirty,
        //setFieldTouched
        setFieldValue,
        setFieldTouched,
        initialValues
    } = props;
    //const { formData } = props;

    const {schema} = props
    // Calculate isDirty without including metadata values
    const isDirty = !(_isEqual(stripMeta(initialValues), stripMeta(values)))
    
    // Get layoutGroups that are supposed to be visible when we're on the advanced setting
    let whenDetailSettingsVisible = _has(schema, "metadata.layoutGroups.whenDetailSettingsVisible") ?
        schema.metadata.layoutGroups.whenDetailSettingsVisible : [];
    // Get layoutGroups that are supposed to be visible when we're not on the advanced setting
    let whenDetailSettingsNotVisible = _has(schema, "metadata.layoutGroups.whenDetailSettingsNotVisible") ?
        schema.metadata.layoutGroups.whenDetailSettingsNotVisible : [];
    // See if the lists have any differences
    const visibilityListsAreDifferent = (_xor(whenDetailSettingsVisible, whenDetailSettingsNotVisible).length > 0)

    // Currently expanded? (no effect if there's no button -- which happens when there's only one layout group)
    let expanded = values.hasOwnProperty("_detailSettingsVisible") ? values["_detailSettingsVisible"] : false;
    // Get the list of currently-visible layout groups
    let visibleLayoutGroups = expanded ? whenDetailSettingsVisible : whenDetailSettingsNotVisible
    
    // Produce formData
    const formData = formPropsToArray(schema, visibleLayoutGroups)

    const toggleDetailSettingsVisible = (e) => {
        const dsv = !values["_detailSettingsVisible"]
        setFieldValue("_detailSettingsVisible", dsv, false)
        setFieldTouched("_detailSettingsVisible",false)
        e.preventDefault()
    }

    /*
    console.log("***** ")
    console.log("disabled={!dirty || isSubmitting || isValidating || !isValid || (props.readonly)}")
    console.log("!dirty:", !dirty)
    console.log("!isDirty:", !isDirty)
    
    console.log("isSubmitting:", isSubmitting)
    console.log("isValidating:", isValidating)
    console.log("!isValid:", !isValid)
    console.log("props.readonly:", props.readonly)
    console.log("visibilityListsAreDifferent:", visibilityListsAreDifferent)
    console.log("expanded:", expanded)
    console.log("values: ", values)
    console.log("errors: ", errors)
    */

    return (
        <form onSubmit={props.handleSubmit} onBlur={props.handleBlur} >


            <CardContent className={classes.cardcontent}>
                <Grid container spacing={2} direction="row" alignItems="flex-start" justify="flex-start"  >
                    {
                        formData.map(q => (
                            <FormTextField id={"main_question_" + q.name} key={q.name} q={q} {...props} />
                        ))
                    }
                </Grid>
            </CardContent>
            {(visibilityListsAreDifferent || isDirty) &&
                <CardActions className={classes.actions} disableSpacing>
                    {isDirty &&
                        <Button
                            aria-label="Apply new settings"
                            fullWidth
                            size="medium"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isDirty || isSubmitting || isValidating || !isValid || (props.readonly)}
                            style={{ marginRight: 15 }}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />  &nbsp; Apply New Settings
                            </Button>
                    }
                    {visibilityListsAreDifferent &&
                        <Tooltip title={expanded ? "Show fewer options" : "Show more options"} aria-label="More options">
                            <IconButton
                                key="expandButton"
                                id="expandButton"
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={toggleDetailSettingsVisible}
                                aria-expanded={expanded}
                                aria-label="Show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </CardActions>
            }

        </form>

    );
};

export default withRouter(withStyles(styles)(SettingsForm));

