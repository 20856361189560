import XRegExp from 'xregexp';

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Pick a random value, weighting each choice by the weight in the same position
export const getRandomValue_Weighted = (values, weights) => {
  let cummulative_weights_inclusive = []
  let total_weights = 0
  for (let i = 0; i < weights.length; i++) {
    total_weights += weights[i]
    cummulative_weights_inclusive.push(total_weights)
  }
  const r = getRandomInt(1, total_weights)
  for (let i = 0; i < weights.length; i++) {
    if (r <= cummulative_weights_inclusive[i]) {
      return values[i];
    }
  }
  throw new Error('getRandomValue_Weighted failed to ID a value');
  //if (process.env.NODE_ENV === 'development') console.log("cummulative_weights_inclusive: ", cummulative_weights_inclusive)
  //if (process.env.NODE_ENV === 'development') console.log("total_weights: ", total_weights)
}


// Sampler
export const array_of_n_random_unique_ints_between_a_and_b_inclusive = (n, a, b) => {
  const possible_problems = b - a + 1
  let chooser = []
  for (let i = 0; i < possible_problems; i++) {
    chooser.push({ choice: i + a, order: Math.random() })
  }
  const randomOrderedPossibleProblemArray = chooser.sort((y, z) => Number(y.order) - Number(z.order))

  let result = []
  for (let i = 0; i < n; i++) { result.push(randomOrderedPossibleProblemArray[i].choice) }
  return result
}


/**
 * Parse a ratio provided as a string, into numerator and denominator. 0 returns the same as 0/1 and 1 returns the same as 1/1 
 * @param {string} r - Should be 0, 1 or a ratio: "0" = None, "1" = All, "1/2" = Half, "3/4" = 3 in 4, etc.
 */
export const parseRatio = (r) => {
  var myRegexp = /^(0|1)$|^([0-9]+)\/([0-9]+)$/g;
  var match = myRegexp.exec(r);
  let numerator, denominator;
  if (match === null) {
    // eslint-disable-next-line
    if (process.env.NODE_ENV === 'development') console.log(`Bad parameter to parseRatio(). Parameter must match RegEx: ^(0|1)$|^([0-9]+)\/([0-9]+)$ but you provided:`, r);
    // eslint-disable-next-line
    throw new Error(`Bad parameter to parseRatio(). Parameter must match RegEx: ^(0|1)$|^([0-9]+)\/([0-9]+)$`);
  }
  if (typeof match[1] !== 'undefined') {
    numerator = parseInt(match[1], 10);
    denominator = 1;
  } else {
    numerator = parseInt(match[2], 10);
    denominator = parseInt(match[3], 10);
  }

  if (denominator === 0) {
    throw new Error("Bad parameter to parseRatio(). Denominator can't be zero.")
  }

  return {
    numerator: numerator,
    denominator: denominator,
  }
}


// Take an array of numeric arrays. Sort them and remove
// ranges that are already covered
const _cleanNumericRange = (arrRanges) => {
  if (!Array.isArray(arrRanges)) throw new Error("Invalid parameter to _cleanNumericRange")
  const result = arrRanges;

  // Now sort them
  result.sort(function (a, b) {
    if (a[0] === b[0]) return a[1] - b[1];
    return a[0] - b[0]
  })

  let newResult = [];
  // Now remove/shorten ranges to the right that are already covered on the left
  let prevRight;
  for (let i = 0; i <= result.length - 1; i++) {
    if (i === 0) {
      newResult.push(result[i]);
      prevRight = result[i][1];
      continue;
    }
    const left = result[i][0];
    const right = result[i][1];
    // Skip this on if its range is totally covered by the prior range
    if (prevRight >= right) continue;
    // merge with previous range if  prevRight >= left
    if (prevRight >= left) {
      newResult[newResult.length - 1][1] = right;
      prevRight = right;
      continue;
    }
    newResult.push(result[i]);
    prevRight = result[i][1];
  }
  return newResult;
}

// Credit for this one: https://stackoverflow.com/questions/6323417/how-do-i-retrieve-all-matches-for-a-regular-expression-in-javascript
export const matches = (text, pattern) => ({
  [Symbol.iterator]: function* () {
    const clone = new RegExp(pattern.source, pattern.flags);
    let match = null;
    do {
      match = clone.exec(text);
      if (match) {
        yield match;
      }
    } while (match);
  }
});

// Does it look anything like a numeric range?  (12,13-15,768-900, etc.)
// This does not check to make sure the numbers actually make sense, and 
// it only allows positive numbers without decimals right now
export const isNumericRange = (str) => {
  return /(?!^,)^((^|,)([0-9]+|(?:[0-9]+-[0-9]+)))+$/.test(str);
}

// Parse a string like "12,13-15, 25-26,31", allowing negatives and decimals
// and cleaning up by remiving white space, putting the results in order
// and removing duplication in the ranges
export const parseNumericRange = (strRange, allowNegatives = false, allowDecimals = false) => {
  const str = strRange.replace(/\s+/gi, "").split(",")
  let result = [];
  let matchNumber;
  if (!allowDecimals && !allowNegatives) matchNumber = '([0-9]+)';
  if (allowDecimals && !allowNegatives) matchNumber = `([0-9]*\\.{0,1}[0-9]+)`;
  if (!allowDecimals && allowNegatives) matchNumber = '(-?[0-9]+)';
  if (allowDecimals && allowNegatives) matchNumber = '(-?[0-9]*\\.{0,1}[0-9]+)';
  const matchExpression = `(?:^${matchNumber}-${matchNumber}$)|(?:^${matchNumber}$)`;
  const mType = XRegExp(matchExpression, 'g');
  str.forEach(s => {
    const match = XRegExp.exec(s, mType, "one");
    if (match !== null) {
      //console.log(match)
      const a = parseFloat(match[1] !== undefined ? match[1] : match[3]);
      const b = parseFloat(match[2] !== undefined ? match[2] : match[3]);
      result.push([Math.min(a, b), Math.max(a, b)])
    }
  })
  const final = _cleanNumericRange(result);
  //console.log("ParseNumericRange == ", final)

  return final;
}


// Takes an array of numerical range arrays and converts them into 
//   a string like "1-3,5,19-20"
//   Negatives and decimals *are* allowed
// This function will sort ranges and remove duplication in the range
export const numericRangeToString = (arrRanges) => {
  const arr = _cleanNumericRange(arrRanges);
  let result = "";
  arr.forEach(r => {
    //console.log(r);
    if (result.length > 0) result = result + ",";
    result = result + r[0].toString();
    if (r[1] > r[0]) {
      result = result + "-" + r[1].toString();
    }
  })
  return result;
}



/*  THIS version is more efficient but requires lookbehinds, which are not allowed in some browsers

export const parseNumericRange = (strRange, allowNegatives = false, allowDecimals = false) => {
const str = strRange.replace(/\s+/gi, "")
let result = [];
let reSingle, rePair;
if (!allowDecimals && !allowNegatives) {
   reSingle = '(?<=^|,)([0-9]+)(?=,|$)'
   rePair = '(?<=^|,)([0-9]+)-([0-9]+)(?=,|$)'
}
if (allowDecimals && allowNegatives) {
  rePair = '(?<=^|,)(-?[0-9]*\.{0,1}[0-9]+)-(-?[0-9]*\.{0,1}[0-9]+)(?=,|$)'
   reSingle = '(?<=^|,)(-?[0-9]*\.{0,1}\[0-9]+)(?=,|$)'
}
if (allowDecimals && !allowNegatives) {
   rePair = '(?<=^|,)([0-9]*\.{0,1}[0-9]+)-([0-9]*\.{0,1}[0-9]+)(?=,|$)'
   reSingle = '(?<=^|,)([0-9]*\.{0,1}[0-9]+)(?=,|$)'
}
if (!allowDecimals && allowNegatives) {
   rePair = '(?<=^|,)(-?[0-9]+)-(-?[0-9]+)(?=,|$)'
   reSingle = '(?<=^|,)(-?[0-9]+)(?=,|$)'
}
const nType = XRegExp(reSingle,'g');
const mType = XRegExp(rePair,'g');
const pushSingle = (m) => {
  const a = parseFloat(m[1]);
  result.push([a,a])
}
const pushPair = (m) => {
  const a = parseFloat(m[1]);
  const b = parseFloat(m[2]);
   result.push([Math.min(a,b),Math.max(a,b)])
}
XRegExp.forEach(str, nType, pushSingle);
XRegExp.forEach(str, mType, pushPair);

const final = _cleanNumericRange(result);
console.log("ParseNumericRange == ", final)

return final;
}
*/