import React, { Component } from 'react';
import * as routes from '../../constants/routes';
import { withRouter, Redirect } from 'react-router-dom';

import 'typeface-roboto';
import InHeader from '../App/Header/InHeader';

import Intro from './Intro';

const INITIAL_STATE = {
	redirect: ''
};

class Home extends Component {


  constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}
  
	changePassword = () => {
    this.setState({redirect : routes.CHANGE_PASSWORD})
  }


	render() {
		// If state says I should redirect somewhere...
		if (typeof this.state.redirect === 'string' && this.state.redirect.length > 0) {
			return <Redirect push to={this.state.redirect} />;
		}

		return (
				<InHeader>

        <div style={{"maxWidth": "400px"}}>
					<Intro />				
        </div>
				<br/>

				
			</InHeader>
		);
	}
}

export default withRouter(Home);
