//import isEqual from 'lodash.isequal';
//import memoize from 'lodash.memoize'
//import Interpreter from '@code-dot-org/js-interpreter/interpreter';

class Renderers{
    constructor() {
        this._dynamicClasses = {}
    }

    reset = () => {
        this._dynamicClasses = {}
    }

    // Returns true if a renderer with that key has already been initialized
    isRendererInitialized = (key) => {
        return this._dynamicClasses[key] !== undefined
    }

    initializeRenderer = (key, theClass) => {
        if (this.isRendererInitialized(key))
            return;
        
            // SAFETY checks if theClass type is text; 
            // then build it...
            //console.log("INIT class: ",key)

            this._dynamicClasses[key] = theClass
    }

    getRenderer = (key) => {
        const theClass = this._dynamicClasses[key]
        if (typeof theClass === "undefined") {
            console.log("Class not initialized for key: ",key)
            console.log("   Classes: ",this._dynamicClasses)
            return null
        }
        //console.log("Returning renderer: ",theClass)
        return theClass        
    }
}
export let renderers = new Renderers();

