import React from 'react';
import AdditionSetup from '../ProblemTypeSetupClasses/Addition';
import ProblemPage from './ProblemPage';
import {POSITION_IN_PARENT} from '../../constants/layoutconstants';

const MathPage = (props) => {
    const generatorInitialSettings = {
        digitsMin: 2,
        digitsMax: 3,
        addition_carrying: '1/2',
        batch_size: 36
    }
    const defaultLayout = {
        rows : 5,
        columns: 5,
        showAnswers: false,
        padTop: 50,
        position_eq_in_parent: POSITION_IN_PARENT.TOPMIDDLE
    }
    const ProblemSettings = props => <AdditionSetup {...props}>{props.children}</AdditionSetup>

    return <ProblemPage 
        key="ADDITION"
        problemType = 'ADDITION'
        title="Addition: Add it up!"
        mobileTitle="Addition"
        generatorInitialSettings = {generatorInitialSettings}
        defaultLayout = {defaultLayout}
        problemRendererName= "ww.ThreeLineArithmetic"
        SettingsForm = {ProblemSettings}
    />
}  

export default MathPage;
