import React from 'react';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import 'typeface-roboto';
import FormFieldCardParent from './FormFieldCard_Parent';

class ArraySizePicker extends React.Component {
    constructor(props) {
        super(props);
        //if (process.env.NODE_ENV === 'development') console.log("props: ",this.props)

        this.state = {
            values: props.values || [4,5],
            cardTitle: this.props.cardTitle || "Pass cardTitle prop",
            handleChange: this.props.handleChange,
            detailText: this.props.detailText,
            unselectedColor: this.props.unselectedColor || "#f2f2f2",
            selectedColor: this.props.selectedColor || "#80CBC4",
            maxRows: this.props.maxRows || 6,
            maxCols: this.props.maxCols || 6,
            squareEdgeLength: this.props.squareEdgeLength || 25,
            padValue: (this.props.padValue !== undefined ? this.props.padValue : 10),
          };
    }

    
    // detailText can be a string, but may also be a function that gets passed the current values
    resolveDetailToString = () => {
        if (typeof this.state.detailText === "function") 
            return this.state.detailText(this.state.values);
        if (typeof this.state.detailText === "string") 
            return this.state.detailText;
        if (typeof this.state.detailText === "number") 
            return this.state.detailText.toString(10);
        if (typeof this.state.detailText !== "undefined")
            return  this.state.detailText.toString();
        return null;
    }


    onClick = (e) => {
        const r = parseInt(e.target.getAttribute("data-row"));
        const c = parseInt(e.target.getAttribute("data-col"));
        this.setState({values: [r,c]});
        return ((typeof this.state.handleChange === "function") ? this.state.handleChange({values: [r,c]}) : null)
    }

    render() {
        let clickIt = (event) => this.onClick(event);

        const maxR = this.state.maxRows;
        const maxC = this.state.maxCols;
        //let r = this.state.values[0] || 1;
        //let c =this.state.values[1] || 1;
        const side = this.state.squareEdgeLength;
        const pad = this.state.padValue; 
        //if (process.env.NODE_ENV === 'development') console.log("pad ", pad)
        //if (process.env.NODE_ENV === 'development') console.log(this.state)
        const totalWidth = side*maxC + 2*pad;
        const totalHeight = side*maxR + 2*pad;
        const placeX = (r,c) => {
            return pad + (side * (c-1))
        }
        const placeY = (r,c) => {
            return pad + (side * (r-1))
        }
        const responsiveFillColor = (r, c) => {
            return (r <= this.state.values[0] && c <= this.state.values[1]) ? 
                this.state.selectedColor : this.state.unselectedColor
        }

        const makeRec = (row, col) => {
            const keyText = row.toString(10)+"-"+col.toString(10);
            const o =
                <rect 
                    key={"arszpk-rect-"+keyText}
                    id={"rect-"+keyText} 
                    data-row={row.toString(10)}
                    data-col={col.toString(10)}
                    x={placeX(row,col).toString(10)}
                    y={placeY(row,col).toString(10)}
                    width={side.toString(10)} height={side.toString(10)}
                    style={
                        {stroke:"#000000", 
                        fill:responsiveFillColor(row, col), 
                        strokeWidth:1}} 
                    onClick={clickIt}  
                    />
            return o;
        }


        const makeRecs = () => {
            let myGrid = [];
            for (let i = 1; i <= maxR; i++) { 
                for (let j = 1; j <= maxC; j++) { 
                    myGrid.push(makeRec(i, j));
                }
            }
            return myGrid;
        }
            // tabIndex="0"   to get foxus
            // *&* TODO: get focus., highlight
        return (
            <FormFieldCardParent cardTitle={this.state.cardTitle} detailText={this.resolveDetailToString()} >
                <div style={{ marginLeft: 0, marginRight: 0, marginTop: 8, marginBottom: 0, flexGrow: 1} }>
                <Grid container spacing={3} style={{alignContent:"center"}}>
                    <Grid item xs={1}>
                        <div style={{ textAlign: 'left', verticalAlign: 'center' }}>
                        <svg width={totalWidth.toString(10)} height={totalHeight.toString(10)}   >
                            {makeRecs()}
                        </svg>
                        </div>

                    </Grid>
                </Grid>
                </div>
            </FormFieldCardParent>
        )
    }
}


export default ArraySizePicker;



/*
<a xlink:href="#0">
<circle cx="20" cy="25" r="5" data-Name="shape 1" data-tabindex="0" />

//                         <rect id="rect1" x="10" y="10" width="25" height="25"
// style={{stroke:"#000000", fill:"#f2f2f2", strokeWidth:3}} onClick={clickIt}  />
*/