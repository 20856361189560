import React from 'react';
import 'typeface-roboto';
import SwipeableViews from 'react-swipeable-views';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

/*  NOTE: this won't pass Jest - says theme is undefined.
const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex'
	},
	rootPaperHidden: {
		backgroundColor: 'transparent',
		//backgroundColor: 'lightblue',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		height: '100%'
	},
	toolbar: theme.mixins.toolbar,
	rootGridContainer: {
		flexGrow: 1,
		height: '100%',
		width: "100vw",
        backgroundColor: 'white',
	}
}));*/

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex'
	},
	rootPaperHidden: {
		backgroundColor: 'transparent',
		//backgroundColor: 'lightblue',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		height: '100%'
	},
	toolbar: theme.mixins.toolbar,
	rootGridContainer: {
		flexGrow: 1,
		height: '100%',
		width: "100vw",
        backgroundColor: 'white',
	}
})


const childWrapper = (child, theme, classes) => {
	if (process.env.NODE_ENV === 'development') console.log('Theme: ', theme);
	return (

		<div>
                    <CssBaseline />
			
			<Grid item xs={12}>
                <div style={{paddingTop: "60px"}}> 
                {child}
                </div>
			</Grid>
		</div>
	);
};

const PageContainer = (props) => {
	const { isMobile, title } = props;

	const { tabIndex, setTabIndexCallback, classes, theme } = props;
//	const classes = useStyles();
//	const theme = useTheme();
	const children = props.children;

	if (isMobile)
		return (

			<Grid container spacing={0} className={classes.rootGridContainer}>
            
				<SwipeableViews
                    containerStyle={{ backgroundColor: 'white', 		
                        position: "static",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0, 
                        height: '85vh'}}  // '100%'
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tabIndex}
					onChangeIndex={setTabIndexCallback}
				>
					{React.Children.map(children, (child, i) => {
						return childWrapper(child, theme, classes);
					})}
				</SwipeableViews>
			</Grid>

		);
	else
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4" gutterBottom>
						{title}
					</Typography>
				</Grid>
				{children}
			</Grid>
		);
};

export default withStyles(styles, { withTheme: true })(PageContainer);

/*
				<SwipeableViews
					containerStyle={{ backgroundColor: 'white', height: '100vh' }}  // '100%'
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tabIndex}
					onChangeIndex={setTabIndexCallback}
				>
					{React.Children.map(children, (child, i) => {
						return childWrapper(child, theme, classes);
					})}
                </SwipeableViews>
                

<div dir={theme.direction} style={{ padding: 8 * 3 }}>
             {<TabContainer dir={theme.direction}>Item One</TabContainer>
             <TabContainer dir={theme.direction}>Item Two</TabContainer>
             <TabContainer dir={theme.direction}>Item Three</TabContainer>}
*/

/*
children, (child, i) => {
                    if (process.env.NODE_ENV === 'development') console.log("i, tabIndex: ",i, tabIndex)

                if (i !== tabIndex) return
                return (
                    
                        child
                )
                })}
                </div>

                */
