import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux';
import { passwordForget } from '../../redux/actions'
import { FETCH_STATUS } from '../../constants/store'


import { withRouter, Redirect } from 'react-router-dom';
import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import KeyIcon from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { validateEmail } from '../../logic/validators'

import AlertDialog from '../utils/AlertDialog';

import * as routes from '../../constants/routes';

import { StyledAuthPageDiv, StyledInputDiv, StyledErrorText } from './styles';
import InHeader from '../App/Header/InHeader';

const byPropKey = (propertyName, value) => () => ({
	[propertyName]: value
});

const styles = theme => ({
	button: {
	  margin: theme.spacing(1),
	},
	leftIcon: {
	  marginRight: theme.spacing(1),
	},
	rightIcon: {
	  marginLeft: theme.spacing(1),
	},
	iconSmall: {
	  fontSize: 20,
	},
  });

const INITIAL_STATE = {
	email: '',
    redirect: '',
    login_when_done: false,
    alert: false,
    alert_title: "Email Sent",
    alert_text: "Instructions to reset your password have been sent to your email address. Check your your email and follow those instructions to log in.",
    alert_button: "Okay",
    alert_step: 0
};


class ResetPasswordForm extends Component {

	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}
	

	handleChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value });
	};

	handleMouseDownPassword = (event) => {
		event.preventDefault();
    };

	handleRedirectTo = (target) => {
		this.setState({ redirect: target });
    };
    
    resetPassword = () => {
		this.props.passwordForget(this.state.email)

        this.setState({
            alert: true,
            alert_title: "Email Sent",
            alert_text: "Instructions to reset your password were sent to your email address. After you reset your password you can log in.",
            alert_button: "Okay",
            alert_step: 1,
            login_when_done: true
        }) 
    }

    cancel = () => {
        this.handleRedirectTo(routes.SIGN_IN)
    }

    onCloseAlert = () => {
        this.setState({
            alert : false,
            alert_step: 0
         })
    }

    doAlert = () => {
        return <AlertDialog isOpen={true} title={this.state.alert_title} text={this.state.alert_text} okayText={this.state.alert_button} onCloseAlert={this.onCloseAlert}/>
    }


	render() {
		const { classes } = this.props;
        const { email } = this.state;

        if (this.state.login_when_done && (this.props.auth.status === FETCH_STATUS.READY) && this.state.alert_step > 0) 
             return this.doAlert();

        // Wait for alert to close prior to redirect
        if (this.state.alert === false) {
            // If state says I should redirect somewhere...
            if (typeof this.state.redirect === 'string' && this.state.redirect.length > 0) {
                return <Redirect push to={this.state.redirect} />;
            }
            
            // Already logged in? Redirect.
            if (this.props.auth.data.loggedIn) {
                return <Redirect push to={routes.HOME} />;
            }
            
            if (this.state.login_when_done && this.props.auth.status === FETCH_STATUS.READY && !this.alert) 
            return <Redirect push to={routes.SIGN_IN} />;
        }


		return (
            <InHeader>
			<StyledAuthPageDiv>
				<Grid container>
					<Grid item xs={12}>
						<Grid container alignItems={'center'} direction={'column'} justify={'center'}>
							<Grid item>
								<Typography variant="h5" gutterBottom={false}>
									<b>Reset Password</b>
								</Typography>
							</Grid>
							<StyledInputDiv>
								<div>
									<form>
										<Grid container flexgrow={1}>

											<Grid item xs={12}>
												<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
													<Typography variant="body2" style={{ "paddingTop": 18, "paddingBottom": 14 }}>
                                                        Enter your email address to reset your password.
                                                    </Typography>
												</Grid>
											</Grid>

                                            <Grid item xs={12}>
                                                <Grid container direction={'column'} justify={'flex-start'} spacing={1}>
                                                    <FormControl fullWidth style={{ "paddingBottom": 14 }}>
                                                        <InputLabel htmlFor="email-simple">
                                                            Email
										                </InputLabel>
                                                        <Input
                                                            id="email-simple"
                                                            value={email}
                                                            fullWidth
                                                            onChange={(event) => this.setState(byPropKey('email', event.target.value))}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

											<Grid item xs={5}>
												<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
													<FormControl
														fullWidth
														style={{ paddingTop: 22, paddingBottom: 14 }}
													>
														<Button className={classes.button}
															fullWidth
															size="medium"
															variant="contained"
                                                            color="primary"
                                                            onClick={this.cancel}
														>
															<KeyIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
															Cancel
														</Button>
													</FormControl>
												</Grid>
											</Grid>

                                            <Grid item xs={2}>
                                            </Grid>

											<Grid item xs={5}>
												<Grid container direction={'column'} justify={'flex-start'} spacing={1}>
													<FormControl
														fullWidth
														style={{ paddingTop: 22, paddingBottom: 14 }}
													>
														<Button
															className={classes.button}
															fullWidth 
															size="medium"
															variant="contained"
                                                            color="primary"
                                                            onClick={this.resetPassword}
															disabled={  
																this.props.auth.status === FETCH_STATUS.PENDING ||
                                                                this.props.auth.data.loggedIn ||
                                                                !validateEmail(email)
															}
														>
															<KeyIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
															Reset
														</Button>
													</FormControl>
												</Grid>
											</Grid>

											{this.props.auth.status === FETCH_STATUS.ERROR && (
												<Grid item xs={12}>
													<Grid container direction={'column'} justify={'center'} spacing={1}>
														<StyledErrorText style={{ paddingBottom: 12 }}>
															{this.props.auth.error}
														</StyledErrorText>
													</Grid>
												</Grid>
											)}
										</Grid>
									</form>
								</div>
							</StyledInputDiv>
						</Grid>
					</Grid>
				</Grid>
			</StyledAuthPageDiv>
            </InHeader>
		);
	}
}
ResetPasswordForm = withStyles(styles)(ResetPasswordForm);

const mapStateToProps = (state) => ({
    auth : state.auth
})

const mapDispatchToProps = { passwordForget }

export default compose(
	withRouter,
	connect(
	  mapStateToProps,
	  mapDispatchToProps
	)
  )(ResetPasswordForm);
