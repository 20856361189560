// Credit: https://github.com/richardhealy/react-redux-saga-firebase/blob/master/src/reducers/index.js
import { types } from '../actions'
//import { strings } from '../strings';
import { FETCH_STATUS } from '../../constants/store'


const initialState = {
  status: FETCH_STATUS.UNINITIALIZED, 
  error : null,
  data: {
      loggedIn : false,
      user: null
  },
  errorData: {},
  successData: {
    actionGroup: '',
    viewed: true
  },
}

const authReducer = (state = initialState , action) => {

  let actionGroup;
  switch (action.type) {
    case types.PASSWORD_CHANGE.SUCCESS:
    case types.PASSWORD_CHANGE.FAILURE:
        actionGroup = "password change"
        break
    case types.LOGIN.SUCCESS:
    case types.LOGIN.FAILURE:
        actionGroup = "login"
        break
    case types.LOGIN_WITH_EMAIL.SUCCESS:
    case types.LOGIN_WITH_EMAIL.FAILURE:
        actionGroup = "login"
        break
    case types.REGISTER_WITH_EMAIL.SUCCESS:
    case types.REGISTER_WITH_EMAIL.FAILURE:
        actionGroup = "register"
        break
    case types.CREATE_USER.SUCCESS:
    case types.CREATE_USER.FAILURE:
        actionGroup = "create user"
        break
    case types.LOGOUT.SUCCESS:
    case types.LOGOUT.FAILURE:
        actionGroup = "logout"
        break
    default:
        actionGroup = "n/a"
  }

  switch (action.type) {
    case types.PASSWORD_CHANGE.REQUEST:
    case types.LOGIN.REQUEST:
    case types.LOGIN_WITH_EMAIL.REQUEST:
    case types.REGISTER_WITH_EMAIL.REQUEST:
    case types.LOGOUT.REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.PENDING,
        //error: null,
      }
    case types.PASSWORD_CHANGE.SUCCESS:
    case types.LOGIN.SUCCESS:
    case types.LOGIN_WITH_EMAIL.SUCCESS:
    case types.CREATE_USER.SUCCESS:
      return {
        ...state,
        status: FETCH_STATUS.READY,
        data : Object.assign({},state.data,{
            loggedIn: true
        }),
        successData : {
          actionGroup : actionGroup,
          viewed : false
        }      
      }
    case types.PASSWORD_CHANGE.FAILURE:
    case types.LOGIN.FAILURE:
    case types.LOGIN_WITH_EMAIL.FAILURE:
    case types.REGISTER_WITH_EMAIL.FAILURE:
    case types.CREATE_USER.FAILURE:
    case types.LOGOUT.FAILURE:
      // Don't push an error if it's just coming from canceling the popup.
      if (action.payload.error.code && action.payload.error.code === "auth/popup-closed-by-user") { 
        return {
          ...state,
          status: FETCH_STATUS.READY,
          errorData: {}
        }
      }
      return {
          ...state,
          status: FETCH_STATUS.ERROR,
          errorData: {
            error : action.payload.error,
            message: action.payload.error.message || action.payload.error.code || "Error: " + actionGroup,
            actionGroup : actionGroup,
            viewed: false
          },
          //error: action.payload.error.message,
        }
    case types.AUTH_ERROR_VIEWED:
        // Auth errors are cleared after viewing
        return {
          ...state,
          status: FETCH_STATUS.READY,
          errorData: initialState.errorData
        }
      case types.AUTH_SUCCESS_VIEWED:
          return {
            ...state,
            successData: Object.assign(
              {},
              state.successData,
              {viewed : true}
            )
          }
    case types.LOGOUT.SUCCESS:
      return {
        ...initialState,
        status: FETCH_STATUS.READY,
        successData: {
          ...initialState.successData,
          actionGroup: actionGroup,
          viewed : true,
        }
      }

    case types.SYNC_USER:
      return {
        ...state,
        status: FETCH_STATUS.READY,
        //error: null,
        data : {
            loggedIn: action.payload.user != null,
            user: action.payload.user
        }
      }
    default:
      return state
  }
}

export default authReducer;
