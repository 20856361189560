import React from 'react';
import SubtractionSetup from '../ProblemTypeSetupClasses/Subtraction';
import ProblemPage from './ProblemPage';

const MathPage = (props) => {
    const generatorInitialSettings = {
        digitsMin: 2,
        digitsMax: 3,
        subtract_borrowing: "1/2",
        batch_size: 55
    }
    const defaultLayout = {
        rows : 5,
        columns: 4,
        showAnswers: false,
        vbWidth: 650,
        vbHeight: 800,
    }
    const ProblemSettings = props => <SubtractionSetup {...props}>{props.children}</SubtractionSetup>

    return <ProblemPage 
        key="SUBTRACTION"
        problemType = 'SUBTRACTION'
        title="Subtraction: Take it away!"
        mobileTitle="Subtraction"
        generatorInitialSettings = {generatorInitialSettings}
        defaultLayout = {defaultLayout}
        problemRendererName= "ww.ThreeLineArithmetic"
        SettingsForm = {ProblemSettings}
    />
}  

export default MathPage;
