import React from 'react';
import 'typeface-roboto';
import { Formik } from 'formik';
import { connect } from 'formik';
import SettingsForm from '../BiggerBits/JSONSettingsRenderer';

import flatten from 'flat';

const unflatten = flatten.unflatten;

// Transform the settings to the form we need 
//    them in for our form widgets
const shape_settings_for_form = (settings) => {
    const res = flatten(settings, {delimiter: "___"});
    //console.log("shape_settings_for_form settings ", res)
    return res
}

// Transform settings to the shape they are used 
//    everywhere except the form inputs
const shape_settings_for_data = (settings) => {
    const res = unflatten(settings, {delimiter: "___"});
    //console.log("shape_settings_for_data ", res)
    return res
}


const SettingsWidget = (props) => {
    const {validationSchema, formData} = props;

    const callOnChange = (values) => {
        if (typeof props.onChange !== "function") {
            return;
        }
        // Use either the default or a custom function to transform form data into settings data
        if (typeof props.shape_settings_for_data !== "function") {
            props.onChange(shape_settings_for_data(values))
        } else
        {
            props.onChange(props.shape_settings_for_data(values))
        }
    }
    
    // Use either the default or a custom function to transform settings data into form data
    let initSettings;
    if (typeof props.shape_settings_for_form !== "function") {
        initSettings = shape_settings_for_form(props.initSettings)
    } else
    {
        initSettings = props.shape_settings_for_form(props.initSettings)
    }

    const readonly = (props.readonly !== undefined && props.readonly ? true : false)
   // console.log("readonly: ",readonly)

    return (
        <div style={{verticalAlign: "top", display: "inline-block"}}>
            <Formik
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={initSettings}
                enableReinitialize
                mapPropsToValues
                onSubmit={(values, { setSubmitting }) => {
                    callOnChange(values);
                    setSubmitting(false);
                }}
                validationSchema={validationSchema}
                render={props => <SettingsForm formData={formData} readonly={readonly} {...props} />}
            />
        </div>
    );
}

export default connect(SettingsWidget);


/* EXAMPLE USAGE

import React from 'react';
import 'typeface-roboto';
import { withStyles } from '@material-ui/core/styles';
import JSONFormContainer from '../BiggerBits/JSONFormContainer';
import * as yup from 'yup';

import flatten from 'flat';

const unflatten = flatten.unflatten;

const formDataHeader = {
    questions: [
        {
            name: 'page_header___default_svg_settings___title',
            label: "Title",
            helperText: 'Worksheet Title',
            layoutOptions: { xs: 12, sm:12 },
        },
        {
            name: 'page_header___default_svg_settings___nameText',
            label: "Name Label",
            helperText: 'Example: Nombre',
            layoutOptions: { xs: 12 },
        },
        {
            name: 'page_header___default_svg_settings___dateText',
            label: "Date Label",
            helperText: 'Example: Fecha',
            layoutOptions: { xs: 12 },
        },
    ],
}

const validationSchemaHeader = yup.object().shape({

        page_header___default_svg_settings___title: yup.string()
                    .required( 'Required: Title' ),
        page_header___default_svg_settings___dateText: yup.string()
                    .required( 'Required: Date Text' ),
        page_header___default_svg_settings___nameText: yup.string()
                    .required( 'Required: Name Text' ),
             
    });

// Transform the settings to the form we need 
//    them in for our form widgets
const shape_settings_for_form = (settings) => {
    const res = flatten(settings, {delimiter: "___"});
    console.log("ALT shape_settings_for_form settings ", res)
    return res
}

// Transform settings to the shape they are used 
//    everywhere except the form inputs
const shape_settings_for_data = (settings) => {
    const res = unflatten(settings, {delimiter: "___"});
    console.log("ALT shape_settings_for_data ", res)
    return res
}


const SettingsWidget = (props) => {
    return (
        <JSONFormContainer 
            formData = {formDataHeader}
            validationSchema = {validationSchemaHeader}
            shape_settings_for_form = {shape_settings_for_form}
            shape_settings_for_data = {shape_settings_for_data}
            {...props} 
        />
    )
}

export default SettingsWidget;



*/