import React, { Component } from 'react';
import 'typeface-roboto';
//import JSONFormContainer from '../BiggerBits/JSONFormContainer';
//import * as yup from 'yup';


//import './App.css';
// Edit json
import JSONEditorDemo from '../BiggerBits/JSONEditorReact/JSONEditorReact';

/*
const formDataHeader = {
    questions: [
        {
            name: 'page_header___default_svg_settings___title',
            label: "Title",
            helperText: 'Worksheet Title',
            layoutOptions: { xs: 12, sm:12 },
        },
        {
            name: 'page_header___default_svg_settings___nameText',
            label: "Name Label",
            helperText: 'Example: Nombre',
            layoutOptions: { xs: 12 },
        },
        {
            name: 'page_header___default_svg_settings___dateText',
            label: "Date Label",
            helperText: 'Example: Fecha',
            layoutOptions: { xs: 12 },
        },
    ],
}*/

class App extends Component {
  state = {
    json: {
      'array': [1, 2, 3],
      'boolean': true,
      'null': null,
      'number': 123,
      'object': {'a': 'b', 'c': 'd'},
      'string': 'Hello World.'
    }
  };

  render() {
    return (
      <div className="app">
        <h3>JSONEditor React demo</h3>
        <div className="contents">
          <div className="menu">
            <button onClick={this.updateTime}>
              Create/update a field "time"
            </button>
          </div>
          <JSONEditorDemo
              json={this.state.json}
              onChangeJSON={this.onChangeJSON}
          />
          <div className="code">
            <pre>
              <code>
                {JSON.stringify(this.state.json, null, 2)}
              </code>
            </pre>
          </div>
        </div>
      </div>
    );
  }

  onChangeJSON = (json) => {
    this.setState({ json });
  };

  updateTime = () => {
    const time = new Date().toISOString();

    this.setState({
      json: Object.assign({}, this.state.json, { time })
    })
  };
}

export default App;




/*
const validationSchemaHeader = yup.object().shape({

        page_header___default_svg_settings___title: yup.string()
                    .required( 'Required: Title' ),
        page_header___default_svg_settings___dateText: yup.string()
                    .required( 'Required: Date Text' ),
        page_header___default_svg_settings___nameText: yup.string()
                    .required( 'Required: Name Text' ),
             
    });


const SettingsWidget = (props) => {
    return (
        <JSONFormContainer 
            formData = {formDataHeader}
            validationSchema = {validationSchemaHeader}
            {...props} 
        />
    )
}

export default SettingsWidget;
*/
