import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import 'typeface-roboto';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArraySizePicker from './formwidgets/ArraySizePicker';
import WorksheetLayoutSettings from '../LayoutSettingsWidgets/WorksheetLayoutSettings';


const styles = (theme) => ({
	root: {
		width: '100%'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	alert :	 {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
});

const detailTextForWorksheetDimensions = (values) => {
	return '' + values[0].toString(10) + ' rows by ' + values[1].toString(10) + ' columns';
};

class BlockLayoutSettings extends React.Component {
	//worksheetLayoutSettings

	blockKey = this.props.blockKey;
	blockInstanceKey = this.props.blockInstanceKey;
	layoutKey = this.props.layoutKey;
	defaultLayout = this.props.defaultLayout;


	onUpdateValuesForWorksheetDimensions = (newValuesObject) => {
		const rows = newValuesObject.values[0];
		const columns = newValuesObject.values[1];
		this.props.updateLayout_for_ProblemBlockInstanceByKey(this.blockInstanceKey, { rows: rows, columns: columns })
	};

	onChangeHeader = (newValues) => {
		// console.log("CHANGE new values: ",newValues);
		//this.worksheetStore.setPageLayoutDoc(newValues);
		this.props.worksheetLayoutSetCustomLayout(newValues);
	}

	logInToChangeSettings = (newValues) => {
		alert("Log in to change settings")
	}	

	render() {

		const { classes, theme } = this.props;
		//const problemBlock = getProblemBlockByKey(this.props.blockKey,this.props.problemBlocks)
		//const problemBlockInstance = getProblemBlockInstanceByKey(this.props.blockInstanceKey, this.props.problemBlockInstances)
		const isLoggedIn = this.props.isLoggedIn
		const worksheetLayoutSettings = this.props.worksheetLayout
		const layout = this.props.blockInstanceLayout 
		const rows = layout['rows'];
		const columns = layout['columns'];

		const handleChangeCheck = (name) => (event) => {
			switch (name) {
				case 'showAnswers':
					this.props.updateLayout_for_ProblemBlockInstanceByKey(this.props.blockInstanceKey, { showAnswers: event.target.checked })
					break;
				default: 
					break;
			}
		};


/*
		const clickNewWorksheetTitle = () => {
			this.worksheetStore.updateDefaultPageLayout (
				{
					page_header : {
						default_svg_settings : {
							title: 'TITLE ' + Math.random()
						}

				}
			})
		}
		*/

		return (
			<div>
				<ArraySizePicker
					cardTitle="Worksheet Dimensions?"
					detailText={detailTextForWorksheetDimensions}
					handleChange={this.onUpdateValuesForWorksheetDimensions}
					squareEdgeLength={25}
					padValue={0}
					selectedColor={theme.palette.primary['300']}
					values={[rows, columns]}
				/>

				<div style={{ marginLeft: 0, marginRight: 0, minWidth: 250 }}>
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<Typography className={classes.heading}>Worksheet Header</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							{!isLoggedIn && 
								<div>
									<WorksheetLayoutSettings readonly initSettings={worksheetLayoutSettings} onChange={this.logInToChangeSettings} />
									<br />
									<Typography color={"error"} className={classes.alert}>Log in to change these settings</Typography>
								</div>
							}
							{isLoggedIn && 
								<WorksheetLayoutSettings initSettings={worksheetLayoutSettings} onChange={this.onChangeHeader} />
							}
						</ExpansionPanelDetails>
					</ExpansionPanel>


					<div style={{ marginTop: 15 }}>
						<FormControlLabel
							control={
								<Switch
									checked={layout['showAnswers']}
									onChange={handleChangeCheck('showAnswers')}
									value="showAnswers"
									color="primary"
								/>
							}
							label="Show Answers"
						/>
					</div>
				</div>
			</div>
		);
	}
}

BlockLayoutSettings = withStyles(styles, { withTheme: true })(BlockLayoutSettings);

export default BlockLayoutSettings
