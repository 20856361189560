import React from 'react';
import 'typeface-roboto';
import { parseNumericRange } from '../../MathStuff/math_generators/utils';
import { Formik } from 'formik';
import { connect } from 'formik';
import SettingsForm from '../BiggerBits/JSONSettingsRendererV2';
//import * as yup from 'yup';
import { multiplication } from './problemTypeSettingsFixture'
import { getYup, formDefaultValues } from '../../services/formHelpers'
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import _has from 'lodash/has';
//import _xor from 'lodash/xor';

//const numericRangeExp = /(?!^,)^((^|,)([0-9]+|(?:[0-9]+-[0-9]+)))+$/;
//const simpleNumericRangeExp = /^(([0-9]+|(?:[0-9]+-[0-9]+)))$/;


export const styles = (theme) => ({
    mainCard: {
        padding: 0,
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
    },

})



// Transform the settings to the form we need 
//    them in for our form widgets
const shape_settings_for_form = (settings) => {
    const { xDecimals, yDecimals } = settings;
    const res = Object.assign({}, settings, {
        xDecimals: xDecimals[0] === xDecimals[1] ? xDecimals[0].toString(10) : xDecimals[0].toString(10) + "-" + xDecimals[1].toString(10),
        yDecimals: yDecimals[0] === yDecimals[1] ? yDecimals[0].toString(10) : yDecimals[0].toString(10) + "-" + yDecimals[1].toString(10),
    })
    //    console.log("shape for form res", res);
    return res
}

// Transform settings to the shape they are used 
//    everywhere except the form inputs
const shape_settings_for_data = (settings) => {
    //console.log("settings ", settings)
    const res = Object.assign({}, settings, {
        xDecimals: parseNumericRange(settings.xDecimals, false, false)[0],
        yDecimals: parseNumericRange(settings.yDecimals, false, false)[0],
    })
    //    console.log("shape for data res", res);
    return res
}

const getInitialValues = (initSettings, defaultSettings, schema ) => {
    const initialValues0 = typeof initSettings === "object" ? initSettings : defaultSettings
    if (initialValues0.hasOwnProperty("_detailSettingsVisible"))
        return shape_settings_for_form(initialValues0)

    // See if there's a schema field re: detailSettingsVisibleByDefault
    const dsv = _has(schema, "metadata.layoutGroups.detailSettingsVisibleByDefault") ?
        schema.metadata.layoutGroups.detailSettingsVisibleByDefault : false;

    // Update initialValues with metadata field
    const initialValues = Object.assign({
        "_detailSettingsVisible" : dsv,
    }, initialValues0)

    return shape_settings_for_form(initialValues)
}

class Multiplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailSettingsVisible: false,
        }
    }


    schema = multiplication;

    initialValues = getInitialValues(this.props.initSettings, formDefaultValues(this.schema), this.schema)

    callOnChange = (values) => {
        if (typeof this.props.onChange !== "function") {
            console.log("No change handler provided to form")
            return;
        }
        let {_detailSettingsVisible, ...values2} = values;
        this.props.onChange(shape_settings_for_data(values));
    }

    render() {
        const { classes } = this.props;

        const schema = this.schema;
        return (
            <div style={{ verticalAlign: "top", display: "inline-block" }}>
                <Card elevation={0} classes={{ root: classes.mainCard }}>
                    <Formik
                        
                        validateOnChange={false}
                        validateOnBlur={true}
                        initialValues={this.initialValues}
                        enableReinitialize
                        mapPropsToValues
                        onSubmit={(values, { setSubmitting }) => {
                            this.callOnChange(values);
                            setSubmitting(false);
                        }}
                        validationSchema={getYup(schema)}
                        render={props => <SettingsForm
                            key={this.props.key}
                            schema={schema}
                            {...props} />}
                    />
                </Card>
            </div>
        );
    }
}


Multiplication = withStyles(styles, { withTheme: true })(Multiplication);
export default connect(Multiplication);

