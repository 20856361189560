import React from 'react';
import 'typeface-roboto';
import * as constants from '../../constants/mathconstants';
import FormFieldCardRangeSlider from '../MathPages/formwidgets/FormFieldCard_RangeSlider'; 
import FormFieldSelect from '../MathPages/formwidgets/FormFieldSelect';
import {parseRatio} from '../../MathStuff/math_generators/utils';

class Subtraction extends React.Component {
    constructor(props) {
        super(props);

        let digits = [2,3];
        let borrowing = constants.ALWAYS_NEVER_MIX.MIX;
        if (typeof props["initSettings"]["digitsMin"]==="number") 
            digits[0] = props["initSettings"]["digitsMin"];
        if (typeof props["initSettings"]["digitsMax"]==="number") 
            digits[1] = props["initSettings"]["digitsMax"];

        const subtract_borrowing = props.initSettings.subtract_borrowing;
        //typeof props.initSettings.subtract_borrowing === "string" ? props.initSettings.subtract_borrowing : "0";
        
        const subtraction_borrowing_ratio = parseRatio(subtract_borrowing);
        if (subtraction_borrowing_ratio.numerator === 0) 
            borrowing = constants.ALWAYS_NEVER_MIX.NEVER;
        if (subtraction_borrowing_ratio.numerator === subtraction_borrowing_ratio.denominator) 
            borrowing = constants.ALWAYS_NEVER_MIX.ALWAYS;

        this.state = {
            onChange : props.onChange,
            digits:  digits,
            borrowing: borrowing,
            batch_size : props.initSettings.batch_size || 55,
          };
    }

    makeSettingsObject = (stateObject) => {
        let settings = {
            digitsMin: stateObject.digits[0],
            digitsMax: stateObject.digits[1],
            subtract_borrowing: "0",
            batch_size : stateObject.batch_size,
        }

        switch(stateObject.borrowing) {
            case constants.ALWAYS_NEVER_MIX.MIX: 
                settings.subtract_borrowing = "1/2"
                break
            case constants.ALWAYS_NEVER_MIX.ALWAYS:
                settings.subtract_borrowing = "1"
                break
            default:
                settings.subtract_borrowing = "0"
        }
        return settings;
    }


    callOnChange = () => {
        if (typeof this.state.onChange !== "function") {
            return;
        }
        const newSettings = this.makeSettingsObject(this.state);
        this.state.onChange(newSettings);
    }

    digits_onUpdateValue = (e) => {
        this.setState({
          digits: e,
        }, this.callOnChange);
      }

    digits_detailText = (values) => {
        return "Problems with " + 
            values[0].toString(10)  + 
                ((values[0] === values[1]) ? " digit numbers" : 
                "-" + values[1].toString(10) + " digit numbers")
    }

    borrowing_onChange = (newValue) => {
        this.setState({
            borrowing: newValue,
          }, this.callOnChange);
    }

    borrowing_detailText = (value) => {
        switch(value) {
            case constants.ALWAYS_NEVER_MIX.ALWAYS:
                return "All problems feature borrowing"
            case constants.ALWAYS_NEVER_MIX.MIX:
                return "Some problems feature borrowing"
            case constants.ALWAYS_NEVER_MIX.NEVER:
                return "Problems do not feature borrowing"
            default:
                return null
        }
    }

    // Return Problem Set data from State
    getProblemsetData = () => {
      return  {
        type: constants.EQUATION_TYPES.SUBTRACTION,
        digitRange: this.state.digits,
        borrowing: this.state.borrowing
    }}

    always_never_mix_selections = [
        {value: constants.ALWAYS_NEVER_MIX.MIX, label: "Sometimes"},
        {value: constants.ALWAYS_NEVER_MIX.ALWAYS, label: "Always"},
        {value: constants.ALWAYS_NEVER_MIX.NEVER, label: "Never"},
    ]
    
    render() {
        return (
            <React.Fragment>
                <FormFieldCardRangeSlider 
                    {...this.props}
                    onValuesUpdated={this.digits_onUpdateValue} 
                    detailText={this.digits_detailText} 
                    values={this.state.digits} 
                    cardTitle="Digits?" 
                    min={1} max={6}
                    snap snapPoints={[1, 2, 3, 4, 5, 6]}
                />
                <FormFieldSelect
                    value={this.state.borrowing} 
                    handleChange={this.borrowing_onChange}
                    cardTitle="Borrowing?" 
                    detailText={this.borrowing_detailText}
                    selectOptions={this.always_never_mix_selections}
                /> 
                </React.Fragment>        
        );
    }
} 

export default Subtraction;


