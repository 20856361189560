import {getRandomInt, parseRatio, array_of_n_random_unique_ints_between_a_and_b_inclusive} from './utils'
import ProblemGeneratorClass from './generator_wrapper';

export default class Subtraction extends ProblemGeneratorClass {
  constructor(settings, errorHandler=undefined) {
    const initialSettings = settings || {
      digitsMin: 2,
      digitsMax: 3,
      subtract_borrowing: "1/2",
      batch_size: 55   // This batch size inludes each single-digit subtraction problem
    } ;  
    const problemType = "SUBTRACTION";
    super(problemType, initialSettings, _makeBatch, errorHandler); 
  }
}

/*  Make  a batch of single digit subtraction problems
    The function produces unique problems in each batch, and there are only 55 possibilities,
    so batch size is limited to 55. Additionally, "borrowing" is always set to false for basic one
    digit subtraction problems.
*/
function _makeBatchSingleDigits(settings) {
  let {batch_size} = settings;
  //batch_size = Math.max(batch_size, 55);
//  const subtract_borrowing = false;  // Because these are one digit problems

  let result_array = []
  let myProbs;
  if (batch_size <= 55) {
      myProbs = array_of_n_random_unique_ints_between_a_and_b_inclusive (settings.batch_size, 0,54);
  } else {
    myProbs = Array.from({length: settings.batch_size}, (v, k) => (k)%55); 
  }

  
    // Label possible problems 0 through 54
    // They are 0, [0]                    0
    //          1, [0, 1]                 1, 2
    //          2, [0, 1, 2]              3, 4, 5
    //          3, [0, 1, 2, 3]           6, 7, 8, 9
    //          4, [0, 1, 2, 3, 4]        10, 11, 12, 13, 14
    //          5, [0, 1, 2, 3, 4, 5]     15, 16, 17, 18, 19, 20
    //          ...                       ...
    //          9, [0,1,2,3,4,5,6,7,8,9]  45, 46, 48, 49, 50, 51, 52, 53, 54
    // Test case: Problem 18 should be x=5, y=3 i.e., 5 - 7

  const x_below = [0, 2, 5, 9, 14, 20, 27, 35, 44, 54]
  let x = 0, y = 0

  for (let i=0; i < batch_size; i++) {
    const a = myProbs[i]    // a is now a problem number (0 through 54)
    let left_side = 0
    let j = 0
    while (x_below[j] < a) { 
      left_side = x_below[j]+1
      j++ 
    }
    x = j
    y = a - left_side
    result_array.push ({x: x, y: y})
  }

  let result2 = []
  for (let i = 0; i < batch_size; i++) {
      const x = result_array[i].x
      const y = result_array[i].y
      result2.push (
        {
          data: {
            x: x, 
            y: y, 
          }, 
          answer_data : {
            answer : x - y
          },
          ai_factors : {
            maxDigits: Math.max(x.toString(10).length, y.toString(10).length),
            hasBorrowing: false,
          },
          display_factors : 
            {
              problemSet_maxDigits: settings.digitsMax,
            },
        })
  }

  return result2
}

function _makeBatch(settings) {
  const { digitsMin, digitsMax, batch_size, subtract_borrowing } = settings;
 // if (process.env.NODE_ENV === 'development') console.log("Making a batch of subtraction. Settings: ",settings)

  // If it's 1 digit subtraction
  if (digitsMin === 1 && digitsMax === 1) return _makeBatchSingleDigits(settings)

  let orderedProblemArray = []
  let borrowing = false
  let dMin = parseInt(digitsMin, 10)
  let dMax = parseInt(digitsMax, 10)
  // Get the ratio provided into an object containing numerator and denominator as integers
  const borrowing_ratio = parseRatio(subtract_borrowing)

  let xFinal = ""
  let yFinal = ""
  let borrowDigit = -1

  for (let i = 0; i < batch_size; i++) {
    xFinal = ""
    yFinal = ""
    // Decide if we want Borrowing for this subtraction problem
    // So, e.g., if num = 2 and den = 7, we want a pattern like: true, true, false, false, false, false, false to be repeated
    // The order of problems will be randomized later (once they get an id)
    borrowing = (i % borrowing_ratio.denominator < borrowing_ratio.numerator)  

    // Select the number of digits for "x" and "y"
    //   (this prevents having 100x more chance of getting a 6 digit than a 4 digit number, e.g.)

    // If we are borrowing, the minimum digits for "X" must be 2 or greater
    let min_x_digits_for_this_problem = (borrowing) ? Math.max(2, dMin) : dMin

    let digsX = ((dMin === dMax) ? dMin : 
       getRandomInt(min_x_digits_for_this_problem, Math.max(dMax,min_x_digits_for_this_problem))
     );
    // Make Y (the bottom) is not longer than X
    let digsY = ((dMin === dMax) ? dMin : getRandomInt(dMin, digsX));
    // Decide if we want Borrowing for this subtraction problem

    // In case I'm Borrowing, decide which digit to make sure borrowing happens (0 = rightmost)
    // CANNOT borrow in leftmost digit of "x", the top number. Otherwise, can borrow in any digit
    // of "y", the bottom number
//    borrowDigit = borrowing ? getRandomInt(0, Math.min(digsY-2)) : -1
    if (borrowing) {
      // if digX is 2 (e.g., 25 - 18), borrowing has to be in 0 place, not 1
      if (digsY === digsX) {
        borrowDigit = getRandomInt(0, Math.min(digsY-2))
      } else {
        borrowDigit = getRandomInt(0, Math.min(digsY-1))
      }
    }

    for (let i = 0; i <= (Math.max(digsX, digsY)-1); i++){

      let x_digit = "" 
      let x_val = 0
      if (i <= (digsX-1)) {    // We don't need another "x" digit so leave it blank
        let left = 0, right=9
        if (i === (digsX-1)) {
          if (borrowing) { left = 2} else { left = 1}
        }   // The leftmost digit can't be zero, so random is 1-9
        if (borrowing && borrowDigit === i) {right = 8}  // If this is the borrowing column, the X digit cannot be 9
        x_val = getRandomInt(left,right)
        x_digit = x_val.toString(10)
      }

      let y_digit = "" 
      if (i <= (digsY-1)) {    // We don't need another "x" digit so leave it blank
        let left = 0, right=9
        
        if (i === (digsY-1)) {
          // Logic for the leftmost digit of y

          // The leftmost digit can't be zero (unless it is a 1 digit number), so random is 1-9
          if (digsY > 1) { left = 1 }   

          // We want to make sure we don't get a negative number
          if (digsX === digsY) {
            if (borrowing) { right = x_val - 1} else { right = x_val }
          }
        } else {
          // Logic for all digits to the right of the leftmost digit of Y
          if (borrowing  && borrowDigit === i) {  
            left = Math.max(left, x_val+1)     // Shore up the leftmost digit to at least 1 more than x_val
          } 
          if (!borrowing) {    // If not borrowing for this problem, make sure each digit is less than or equal to the X digit above it
            right = Math.min(right, x_val)
          }
        }
        y_digit = getRandomInt(left,right).toString(10)
      }
      xFinal = x_digit + xFinal
      yFinal = y_digit + yFinal
    }
    let ai_factors = {
      maxDigits: Math.max(xFinal.length, yFinal.length),
      hasBorrowing: borrowing,
    }
    let x = parseInt(xFinal,10);
    let y = parseInt(yFinal,10);
    let answer = x - y;

    orderedProblemArray.push (
      {
        data: {
          x: x, 
          y: y,
        }, 
        answer_data : {
          answer : answer
        },
        ai_factors : ai_factors,
        display_factors : 
          {
            problemSet_maxDigits: digitsMax,
          },
      }) 
  }  

  return orderedProblemArray;
}




