import React from 'react';
import Grid from '@material-ui/core/Grid';
import 'typeface-roboto';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormFieldCardParent from './FormFieldCard_Parent';


export default class FormFieldCard_Select extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            cardTitle: this.props.cardTitle || "Pass cardTitle prop",
            handleChange: this.props.handleChange,
            detailText: this.props.detailText,
            selectOptions: this.props.selectOptions   // Must be array of objects; each object has value and label
          };
    }

    handleChange = (e) => {
        this.setState({
          value: e.target.value,
        });
       return ((typeof this.state.handleChange === "function") ? this.state.handleChange(e.target.value) : null)
      }
    
    // detailText can be a string, but may also be a function that gets passed the current values
    resolveDetailToString = () => {
        if (typeof this.state.detailText === "function") 
            return this.state.detailText(this.state.value);
        if (typeof this.state.detailText === "string") 
            return this.state.detailText;
        if (typeof this.state.detailText === "number") 
            return this.state.detailText.toString(10);
        if (typeof this.state.detailText !== "undefined")
            return  this.state.detailText.toString();
        return null;
    }


    render() {

        const menuitemz = this.state.selectOptions.map((o) => 
            <MenuItem key={this.state.cardTitle + "-"+ o.value} value={o.value}>{o.label}</MenuItem>
        )

        return (
            <FormFieldCardParent cardTitle={this.state.cardTitle} detailText={this.resolveDetailToString()} >
                <div style={{ marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0, flexGrow: 1 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{ marginLeft: 0, MarginRight:0 }}>
                            <FormControl style = {{width:"100%"}}>
                            <Select 
                                value={this.state.value}
                                input={<Input id={this.state.cardTitle} />} 
                                onChange={this.handleChange}
                            >
                                {menuitemz}
                            </Select>
                            </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </FormFieldCardParent>
        )
    }
}
