import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import brown from '@material-ui/core/colors/brown';
import cyan from '@material-ui/core/colors/cyan';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import lightBlue from '@material-ui/core/colors/lightBlue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import orange from '@material-ui/core/colors/orange';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';


export const randomPalette = () => {
    const x = Math.floor((Math.random() * 19) + 1);

    switch (x) {
        case 1:
            return amber;
        case 2:
            return blue;
        case 3:
            return blueGrey;
        case 4:
            return brown;
        case 5:
            return cyan;
        case 6:
            return deepOrange;
        case 7:
            return deepPurple;
        case 8:
            return green;
        case 9:
            return grey;
        case 10:
            return indigo;
        case 11:
            return lightBlue;
        case 12:
            return lightGreen;
        case 13:
            return lime;
        case 14:
            return orange;
        case 15:
            return pink;
        case 16:
            return purple;
        case 17:
            return red;
        case 18:
            return teal;
        case 19:
            return yellow;
        default: 
            return blue;
    }
}