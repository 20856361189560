import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'

class GoogleAnalytics extends Component {
  componentDidMount () {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    )
  }

  componentDidUpdate ({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search)
    }
  }

  logPageChange (pathname, search = '') {
    const page = pathname + search
    const { location } = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })

    const env = process.env; 
    if (env.NODE_ENV === 'development')
//        if (process.env.NODE_ENV === 'development') console.log("GA test: setting pageview: ",page)
    ReactGA.pageview(page)
  }

  render () {
    return null
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
}

const RouteTracker = () =>
  <Route component={GoogleAnalytics} />

const init = (options = {}) => {

  const env = process.env; 
  const isGAEnabled = !!env.REACT_APP_GAKEY

  if (isGAEnabled && ReactGA !== undefined) {
    ReactGA.initialize(
      env.REACT_APP_GAKEY, {
        testMode: process.env.NODE_ENV === 'test',
        debug: env.REACT_APP_GA_DEBUG === 'true',
        ...options
      }
    )
  }

  return isGAEnabled
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init
}

// CREDITS: https://vanja.gavric.org/blog/integrate-google-analytics-with-react-router-v4/
