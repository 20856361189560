import React, { useState } from 'react';
// MUI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// MUI Cards
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// MUI Lists
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// Icons
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// My components
import InHeader from '../App/Header/InHeader'
import ElementDetails from './ElementDetails'
import EditOptionsJSON from './EditOptionsJSON'
// Button
import { firebaseApp as firebase } from '../../firebase/rsf'
import PDFButton from '../ConnectedBits/PDFButton';



const styles = theme => ({
    faIcon: {
        fontSize: 18,
        margin: 0,
        padding: 0
        // padding if needed (e.g.,  2)
        // margin if needed
    },
    actions: {
        display: 'flex',
        minHeight: "60px"
    },
    cardButtonRight: {
        marginLeft: 'auto',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
})



const Workshop = (props) => {
    const { classes } = props;
    const [variables, setVariables] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [addMenuState, setAddMenuState] = useState(null);

    function handleListItemClick(event, index) {
        setSelectedIndex(index);
    }

    const handleAddMenu = (event) => {
        console.log("event, event.currentTarget: ", event, event.currentTarget)
        setAddMenuState(event.currentTarget);
    };

    const handleCloseAddMenu = () => {
        setAddMenuState(null);
    };


    const handleHelloTest = () => {
        const helloCall = firebase.functions().httpsCallable('helloCall');
        helloCall({ html: "strawberry cheesecake" }).then(function (result) {
            var text = result.data.text;
            console.log("Got result: ", text)
        }).catch(function (error) {
            console.log("Error: ", error.message)
        });
    }

    const AddMenu = () => {
        const open = Boolean(addMenuState);
        return (
            <Menu
                id="long-menu"
                style={{ zIndex: 20000 }}
                anchorEl={addMenuState}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                onClose={handleCloseAddMenu}
            >
                <MenuItem onClick={addNumber}>A number</MenuItem>
                <MenuItem onClick={addText}>A word or phrase</MenuItem>
            </Menu>
        )
    }

    const addNumber = () => {
        setVariables([
            ...variables,
            {
                id: variables.length,
                type: "number",
                value: Math.random() * 100
            }
        ]);
    };

    const addText = () => {
        setVariables([
            ...variables,
            {
                id: variables.length,
                type: "text",
                value: "This text"
            }
        ]);
    };

    const ListItemVariable = (props) => {
        const { item: v } = props;
        return (
            <ListItem
                button
                selected={selectedIndex === v.id}
                onClick={event => handleListItemClick(event, v.id)}
            >
                <ListItemText primary={v.value} />
            </ListItem>
        )
    }

    // jsoneditor
    const OptionsForGenerator = () => {
        return (
            <Card>
                <CardContent>
                    <EditOptionsJSON />
                </CardContent>
            </Card>
        )
    }

    const VariableList = () => {
        const item = variables.find(i => i.id === selectedIndex)
        //const open = Boolean(addMenuState);
        return (
            <Card>
                <CardActions>
                    <Typography variant="h6">Problem Elements</Typography>
                    <IconButton
                        className={classes.cardButtonRight}
                        aria-label="Add an element"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleAddMenu}>
                        <FontAwesomeIcon icon={faPlus} className={classes.faIcon} />
                    </IconButton>
                    < AddMenu id="long-menu" />
                </CardActions>
                <CardContent>
                    <List>
                        {variables.map(v => (
                            <ListItemVariable key={v.id} item={v} />
                        ))}
                    </List>
                </CardContent>
                {item !== undefined && <ElementDetails item={item} />}
            </Card>
        )
    }

    // jsoneditor
    const PDFRenderTester = () => {
        return (
            <Card>
                <CardContent>
                    <PDFButton html="Goofy" />
                </CardContent>
            </Card>
        )
    }


    return (
        <InHeader title="Workshop">
            <OptionsForGenerator />
            <VariableList />
            <h4>Test PDF Renderer</h4>
            <PDFRenderTester />

        </InHeader>
    )
}

export default withStyles(styles)(Workshop);

/*
<div className={classes.root}>
<List component="nav" aria-label="Main mailbox folders">
  <ListItem
    button
    selected={selectedIndex === 0}
    onClick={event => handleListItemClick(event, 0)}
  >
    <ListItemIcon>
      <InboxIcon />
    </ListItemIcon>
    <ListItemText primary="Inbox" />
  </ListItem>
  */


