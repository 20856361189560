import produce from 'immer'
import { types } from '../actions'
import { FETCH_STATUS } from '../../constants/store'
import { getNewKey } from '../../firebase/getNewKey'


import { PageLayoutSchema } from '../schemas';
import { merge } from 'lodash';

/* 
    WorksheetLayouts 
*/

const initialState = {
    status: FETCH_STATUS.UNINITIALIZED,
    error: null,
    data: {
        worksheetLayouts: {},  // Dict of layouts, by ID
        keys_to_ids: {},  // Get the ID from the key
    }
}


const reducer = (state = initialState, action) => {
    let theId;
    let thisObject;
    let theKey;
    let newState;
    switch (action.type) {
        case types.WORKSHEET_LAYOUT_SET.REQUEST:
        case types.WORKSHEET_LAYOUT_INITIALIZE:
            return {
                ...state,
                status: FETCH_STATUS.PENDING,
            }
        case types.WORKSHEET_LAYOUT_READY:
            return {
                ...state,
                status: FETCH_STATUS.READY,
            }
        case types.WORKSHEET_LAYOUT_SET.SUCCESS:
            /* 
                This manages:
                    - Add a totally new ProblemBlock
                    - Update an existing ProblemBlock
                    - Manage key/id dictionary for ProblemBlocks
                    - Validates all ProblemBlock object updates against schema            
            */
            newState = produce(state, draft => {
                //console.log("Success reducer. Action: ",action)
                //console.log("  Draft: ",JSON.stringify(draft))

                // If a key is provided in the action, see if there's an ID for it already
                if (action.payload.key) {
                    theKey = action.payload.key;
                    theId = state.data.keys_to_ids[action.payload.key]
                }

                // If no ID yet, see if there's an ID provided in the action
                if (theId === undefined && action.payload.id) {
                    theId = action.payload.id
                }

                // If still no ID, create one
                if (!theId) {
                    theId = getNewKey()
                    thisObject = {}
                } else {
                    // If there already an object in Redux with that ID, get it
                    //   Otherwise, make a skeleton object to merge in
                    thisObject = state.data.worksheetLayouts[theId] || {};
                }

                const newObject = merge({}, thisObject, action.payload, { id: theId })

                // Validate against object schema
                PageLayoutSchema(newObject)
                // Save object to draft
                draft.data.worksheetLayouts[theId] = newObject
                // If there's a key, set the key/id dict
                if (theKey)
                    draft.data.keys_to_ids[theKey] = theId
                draft.status = FETCH_STATUS.READY
                draft.error = null
            })
            return newState;
        case types.SYNC_WORKSHEET_LAYOUT.SUCCESS:
            //console.log("worksheetlayout sync success: ",action.payload)
            newState = produce(state, draft => {
                theId = action.payload.id;
                PageLayoutSchema(action.payload)
                // Save object to draft
                draft.data.worksheetLayouts[theId] = action.payload
                draft.status = FETCH_STATUS.READY
                draft.error = null
            })
            return newState;
        case types.USER_GOODBYE:
        case types.WORKSHEET_LAYOUT_CLEAR_DATA:
            return {
                ...initialState,
                status: FETCH_STATUS.READY,
            }
        case types.SYNC_WORKSHEET_LAYOUT_COLLECTION.SUCCESS:
            //console.log("Syncing worksheet layouts")
            newState = produce(state, draft => {
                draft.data.worksheetLayouts = {}
                draft.data.keys_to_ids = {}
                action.payload.docs.forEach(doc => {
                    const data = doc.data()
                    //console.log("   New data: ",data)
                    // Validate document structure
                    PageLayoutSchema(data)
                    // Set data for this item
                    draft.data.worksheetLayouts[data.id] = data
                    // Set keys to id's crosswalk item
                    if (typeof data.key === "string") {
                        draft.data.keys_to_ids[data.key] = data.id
                    }
                })
                // Save object to draft
                draft.status = FETCH_STATUS.READY
                draft.error = null
            })
            return newState;
        default:
            return state;
    }
}

export default reducer;

/*
        case types.SYNC_WORKSHEET_LAYOUT_COLLECTION.SUCCESS:
      //console.log("Syncing worksheet layouts")
      newState = produce(state, draft => {
          draft.data.worksheetLayouts = {}
          draft.data.keys_to_ids = {}
          action.payload.docs.map(doc => {
              const data = doc.data()
              //console.log("   New data: ",data)
              // Validate document structure
              PageLayoutSchema(data)
              // Set data for this item
              draft.data.worksheetLayouts[data.id] = data
              // Set keys to id's crosswalk item
              if (typeof data.key === "string") {
                  draft.data.keys_to_ids[data.key] = data.id
              }
          })
          // Save object to draft
          draft.status = FETCH_STATUS.READY
          draft.error = null
      })
      return newState;
      */