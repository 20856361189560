//import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/functions'
import ReduxSagaFirebase from 'redux-saga-firebase';
import {config} from './firebaseConfig';

let firebaseApp;

if(firebase.apps && firebase.apps.length > 0) {
    firebaseApp = firebase.apps[0]
  } else {
        firebaseApp = firebase.initializeApp(config);
        if (process.env.NODE_ENV !== 'production') {
          console.log("ENV=",process.env.NODE_ENV)
          console.log("  Using simulated cloud functions. If nto running, execute 'firebase emulators:start --only functions'")
          firebaseApp.functions().useFunctionsEmulator('http://localhost:5001') 
        }
  }

const rsf = new ReduxSagaFirebase(firebaseApp);

export { firebaseApp };


export default rsf;