import React from 'react';
import * as routes from '../../constants/routes';
import InHeader from '../App/Header/InHeader';
import { Link } from 'react-router-dom';

const About = () => {

    return (
        <InHeader>
        <div>
				<h4>About this project</h4>
				<p>Right now you can use this site to print off some arithmetic worksheets. Hold your applause, because 
					there's more coming!
				</p>
			<Link to={routes.TERMS_OF_SERVICE}>Terms of Service</Link>
        </div>
        </InHeader>
    )
}

export default About;

/*
				<p>
				Our vision is for a space where it's easy to design all sorts of math problems &amp; worksheets, and organize your worksheets into
				courses, units and sections, or however you like to organize them. 
				You'll be able to share your designs (with the world, with a classroom or with a few friends), but you won't have to. 
				</p>
				<p>
				Right now there are just a few supported problem types, but we'll get it set up pretty soon where you can design your own and problems, 
				as well as your own renderers, and share them. I'll explain more about that later. Maybe I'll even stick a "blog post" widget here instead of this 
				lame hard-coded markup. Maybe. 
				</p>
				<p>
				Anyway my thinking is, 
				1. It can be fun and interesting for kids to design their own problems or worksheets to share with friends or with a classroom, 
				without having to share with Larger Internet. Teaching is a great way to learn, and setting kids up with a near way to 
				design teaching materials to share with their class might be a cool way to plug a gifted kid into a real challenge that keeps her connected to the class. 
				Is that right? Honestly, I don't really know. You'll have to let me know how it goes, what works best, what you want to see more of. I'll set up a Slack
				channel or something for folks who want to share experience and ideas.
				2. Wouldn't it be cool for teachers to be able to develop, organize and (if they want to) share creative and truly original class materials in some way that 
				is fun to use, dynamic and easy to customize? 
				</p>
				<p>We're on it! Hope it helps. </p>
			*/