export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-reset';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const CHANGE_PASSWORD = '/pw-update'
export const TERMS_OF_SERVICE = '/tos'
export const ARITHMETIC_ADD = '/arithmetic_add'
export const ARITHMETIC_SUBTRACT = '/arithmetic_subtract'
export const ARITHMETIC_MULTIPLY = '/arithmetic_multiply'
export const ABOUT = '/about'
export const WORKSHOP = '/workshop'
export const TESTER = '/tester'