// Login flow stuff -- credit to https://github.com/richardhealy/react-redux-saga-firebase/blob/master/src/sagas/index.js

export const sayHello = () => ({
    type: "HELLO_REACT",
    data: "this is not the data you are looking for"
})


/*
const RequestConstants = (name) => {
  return ({
      [name] : {
          REQUEST: name + '.REQUEST',
          SUCCESS: name + '.SUCCESS',
          FAILURE: name + '.FAILURE'
      }
  })
} */

export const types = {
    LOG : 'LOG',
    CLEAR_LOG : 'CLEAR_LOG',
    LOGIN: {
      REQUEST: 'LOGIN.REQUEST',
      SUCCESS: 'LOGIN.SUCCESS',
      FAILURE: 'LOGIN.FAILURE'
    },
    LOGIN_WITH_EMAIL: {
      REQUEST: 'LOGIN_WITH_EMAIL.REQUEST',
      SUCCESS: 'LOGIN_WITH_EMAIL.SUCCESS',
      FAILURE: 'LOGIN_WITH_EMAIL.FAILURE'
    },
    LOGOUT: {
      REQUEST: 'LOGOUT.REQUEST',
      SUCCESS: 'LOGOUT.SUCCESS',
      FAILURE: 'LOGOUT.FAILURE'
    },
    REGISTER_WITH_EMAIL: {
      REQUEST: 'REGISTER_WITH_EMAIL.REQUEST',
      SUCCESS: 'REGISTER_WITH_EMAIL.SUCCESS',
      FAILURE: 'REGISTER_WITH_EMAIL.FAILURE'
    },
    CREATE_USER: {
      SUCCESS: 'CREATE_USER.SUCCESS',
      FAILURE: 'CREATE_USER.FAILURE'
    },
    PASSWORD_CHANGE: {
      REQUEST: 'PASSWORD_CHANGE.REQUEST',
      SUCCESS: 'PASSWORD_CHANGE.SUCCESS',
      FAILURE: 'PASSWORD_CHANGE.FAILURE'
    },
    PASSWORD_FORGET: {
      REQUEST: 'PASSWORD_FORGET.REQUEST',
      SUCCESS: 'PASSWORD_FORGET.SUCCESS',
      FAILURE: 'PASSWORD_FORGET.FAILURE'
    },
    AUTH_SUCCESS_VIEWED: 'SUCCESS_VIEWED',
    AUTH_ERROR_VIEWED: 'AUTH_ERROR_VIEWED',
    SYNC_USER: 'SYNC_USER',
    USER_HELLO: 'USER_HELLO',  
    USER_GOODBYE: 'USER_GOODBYE' ,
    LOAD_USER: {
      REQUEST: 'LOAD_USER.REQUEST',
      SUCCESS: 'LOAD_USER.SUCCESS',
      FAILURE: 'LOAD_USER.FAILURE'
    },
    CLEAR_USER: 'CLEAR_USER',
    SYNC_UI: {
      REQUEST: 'SYNC_UI.REQUEST',
      SUCCESS: 'SYNC_UI.SUCCESS',
      FAILURE: 'SYNC_UI.FAILURE'
    },
    UI_HELLO: 'UI_HELLO',
    UI_SET_DATA: 'UI_SET_DATA',
    UI_SET_RANDOM_COLOR_THEME: 'UI_SET_RANDOM_COLOR_THEME',
    RESET_UI : 'RESET_UI',
    PROBLEM_BLOCK_SET: {
      REQUEST: 'PROBLEM_BLOCK_SET.REQUEST',
      SUCCESS: 'PROBLEM_BLOCK_SET.SUCCESS',
      FAILURE: 'PROBLEM_BLOCK_SET.FAILURE',
      READY: 'PROBLEM_BLOCK_SET.READY'
    },
    PROBLEM_BLOCK_CLEAR_DATA : 'PROBLEM_BLOCK_CLEAR_DATA',
    PROBLEM_BLOCK_INSTANCE_SET: {
      REQUEST: 'PROBLEM_BLOCK_INSTANCE_SET.REQUEST',
      SUCCESS: 'PROBLEM_BLOCK_INSTANCE_SET.SUCCESS',
      FAILURE: 'PROBLEM_BLOCK_INSTANCE_SET.FAILURE'
    },
    PROBLEM_BLOCK_INSTANCE_INITIALIZE: "PROBLEM_BLOCK_INSTANCE_INITIALIZE",
    PROBLEM_BLOCK_INSTANCE_CLEAR_DATA : 'PROBLEM_BLOCK_INSTANCE_CLEAR_DATA',
    PROBLEM_BLOCK_INSTANCE_NEW_SAMPLE : 'PROBLEM_BLOCK_INSTANCE_NEW_SAMPLE',
    PROBLEM_BLOCK_INSTANCE_RANDOMIZE : 'PROBLEM_BLOCK_INSTANCE_RANDOMIZE',
    PROBLEM_BLOCK_INSTANCE_UPDATE_LAYOUT : 'PROBLEM_BLOCK_INSTANCE_UPDATE_LAYOUT',
    INITIALIZE_RENDERER: {
      REQUEST: 'INITIALIZE_RENDERER.REQUEST',
      SUCCESS: 'INITIALIZE_RENDERER.SUCCESS',
      FAILURE: 'INITIALIZE_RENDERER.FAILURE'
    },
    WORKSHEET_LAYOUT_CLEAR_DATA: 'WORKSHEET_LAYOUT_CLEAR_DATA',
    WORKSHEET_LAYOUT_SET: {
      REQUEST: 'WORKSHEET_LAYOUT_SET.REQUEST',
      SUCCESS: 'WORKSHEET_LAYOUT_SET.SUCCESS',
      FAILURE: 'WORKSHEET_LAYOUT_SET.FAILURE'
    },
    SYNC_WORKSHEET_LAYOUT : {
      SUCCESS: 'SYNC_WORKSHEET_LAYOUT.SUCCESS',
      FAILURE: 'SYNC_WORKSHEET_LAYOUT.FAILURE'
    },
    WORKSHEET_LAYOUT_INITIALIZE : 'WORKSHEET_LAYOUT_INITIALIZE',
    WORKSHEET_LAYOUT_READY : 'WORKSHEET_LAYOUT_READY',
    SYNC_WORKSHEET_LAYOUT_COLLECTION : {
      SUCCESS: 'SYNC_WORKSHEET_LAYOUT_COLLECTION.SUCCESS',
    },
    API_ERROR : 'API_ERROR',
  };


  export const logger = (data) => {
    return {
    type: types.LOG,
    payload: {message : data}
    }
  }
  
  export const login = () => ({
    type: types.LOGIN.REQUEST
  });
  
  export const loginSuccess = credential => ({
    type: types.LOGIN.SUCCESS,
    payload : {
      credential
    }
  });
  
  export const loginFailure = (error) => ({
    type: types.LOGIN.FAILURE,
    payload: {
      error
    },
  });
  
  export const loginWithEmail = (email, password) => ({
    type: types.LOGIN_WITH_EMAIL.REQUEST,
    payload : {
      email,
      password
    }
  });
  
  export const loginWithEmailSuccess = credential => ({
    type: types.LOGIN_WITH_EMAIL.SUCCESS,
    payload: {
      credential
    }
  });
  
  export const loginWithEmailFailure = error => ({
    type: types.LOGIN_WITH_EMAIL.FAILURE,
    payload: {
      error
    },
  });
  
  export const registerWithEmail = (email, password) => ({
    type: types.REGISTER_WITH_EMAIL.REQUEST,
    payload : {
      email,
      password
    }
  });
  
  export const registerWithEmailSuccess = credential => ({
    type: types.REGISTER_WITH_EMAIL.SUCCESS,
    credential
  });
  
  export const registerWithEmailFailure = error => ({
    type: types.REGISTER_WITH_EMAIL.FAILURE,
    payload: {
      error
    },
  });
  
  export const passwordChange = (password) => ({
    type: types.PASSWORD_CHANGE.REQUEST,
    payload : {
      password
    }
  });
  
  export const passwordChangeSuccess = credential => ({
    type: types.PASSWORD_CHANGE.SUCCESS
  });
  
  export const passwordChangeFailure = error => ({
    type: types.PASSWORD_CHANGE.FAILURE,
    payload: {
      error
    },
  });
  
  export const passwordForget = (email) => ({
    type: types.PASSWORD_FORGET.REQUEST,
    payload : {
      email
    }
  });
  
  export const passwordForgetSuccess = credential => ({
    type: types.PASSWORD_FORGET.SUCCESS
  });
  
  export const passwordForgetFailure = error => ({
    type: types.PASSWORD_FORGET.FAILURE,
    payload: {
      error
    },
  });
  
  export const logout = () => ({
    type: types.LOGOUT.REQUEST
  });
  
  export const logoutSuccess = () => ({
    type: types.LOGOUT.SUCCESS
  });
  
  export const logoutFailure = error => ({
    type: types.LOGOUT.FAILURE,
    payload: {
      error
    },
  });
  
  export const syncUser = user => ({
    type: types.SYNC_USER,
    payload: {
      user
    }
  });

  export const authErrorViewed = () => ({
    type: types.AUTH_ERROR_VIEWED
  });

  export const authSuccessViewed = () => ({
    type: types.AUTH_SUCCESS_VIEWED
  });

  export const userHello  = () => ({
    type: types.USER_HELLO
  })

  export const userGoodbye  = () => ({
    type: types.USER_GOODBYE
  })

  export const syncUi  = () => ({
    type: types.SYNC_UI.REQUEST
  })

  export const syncUiSuccess  = (data) => {
    let rez;
    if (typeof data.data === 'function')
      rez = data.data()
    else
      rez = data;
    //console.log("syncUiSuccess: ",rez)
    return {
      type: types.SYNC_UI.SUCCESS,
      payload: rez
    }
}

  export const uiHello  = () => ({
    type: types.UI_HELLO
  })

  export const syncUiFailure  = () => ({
    type: types.SYNC_UI.FAILURE
  })

  export const resetUiData  = () => ({
    type: types.RESET_UI
  })

  export const setUiData = (data) => ({
    type: types.UI_SET_DATA,
    payload : {
      ...data
    }
  });

  export const setRandomColorTheme = () => ({
    type: types.UI_SET_RANDOM_COLOR_THEME,
  });

  export const setProblemBlock = (data) => ({
    type: types.PROBLEM_BLOCK_SET.REQUEST,
    payload: data
  });  

  export const setProblemBlockSuccess =  (data) => ({
      type: types.PROBLEM_BLOCK_SET.SUCCESS,
      payload : data
  });

  export const setProblemBlockReady = () => ({
    type: types.PROBLEM_BLOCK_SET.READY,
  });
  
  export const clearProblemBlockData = () => ({
    type: types.PROBLEM_BLOCK_CLEAR_DATA,
  });

  // We need to be able to find the ProblemBlock from this action
  //   So either there should be data with a reference to the problemBlock, or
  //   a problemBlockKey or problemBlockId must be supplied
  export const setProblemBlockInstance = (data) => ({
    type: types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST,
    payload : {
      ...data,
    }
  });  


  export const setProblemBlockInstanceSuccess =  (data) => ({
    type: types.PROBLEM_BLOCK_INSTANCE_SET.SUCCESS,
    payload : data
  });
  
  export const clearProblemInstanceBlockData = () => ({
    type: types.PROBLEM_BLOCK_INSTANCE_CLEAR_DATA,
  });

  export const initializeProblemBlockInstance  =  (key = null, problemBlockKey = null, layout, problemCount) => ({
    type: types.PROBLEM_BLOCK_INSTANCE_INITIALIZE,
    metadata: {
      key,
      problemBlockKey
    },
    payload : {
      key,
      layout,
      problemCount
    }
  });



  export const initializeRenderer =  (key = null) => ({
    type: types.INITIALIZE_RENDERER.REQUEST,
    payload : {
      key
    }
  });

  export const initializeRendererSuccess =  (data) => ({
    type: types.INITIALIZE_RENDERER.SUCCESS,
    payload : {
      ...data
    }
  });

  export const updateLayout_for_ProblemBlockInstanceByKey = (key, newLayout) => {
    //console.log("updateLayout_for_ProblemBlockInstanceByKey actioncreator",newLayout)
    const ac = {
      type : types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST,
      payload : {
        key, 
        layout : newLayout,
      }
    }
    if (typeof newLayout.rows === "number" && typeof newLayout.columns === "number")
      ac.payload["problemCount"] = newLayout.rows * newLayout.columns

     return ac
}

export const randomizeProblemBlockInstance  =  (key = null, id = null) => ({
  type: types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST,
  payload: {
    id : id,
    key : key
  },
  metadata : {
    randomizeProblems : true
  }
});

export const newSampleFromProblemBlockInstance  =  (key = null, id = null) => ({
  type: types.PROBLEM_BLOCK_INSTANCE_SET.REQUEST,
  payload: {
    id : id,
    key : key
  },
  metadata : {
    newSampleProblem : true
  }
});

export const initializeWorksheetLayout =  (data) => ({
  type: types.WORKSHEET_LAYOUT_INITIALIZE,
  payload : {
    ...data
  }
});

export const setWorksheetLayout =  (data) => ({
  type: types.WORKSHEET_LAYOUT_SET.REQUEST,
  payload : {
    ...data
  }
});

export const worksheetLayoutSetCustomLayout =  (data) => ({
  type: types.WORKSHEET_LAYOUT_SET.REQUEST,
  metadata : {
    customLayout : true
  },
  payload : {
    ...data
  }
});

export const worksheetLayoutSuccess =  (data) => ({
  type: types.WORKSHEET_LAYOUT_SET.SUCCESS,
  payload : {
    ...data
  }
});

export const clearWorksheetLayoutData = () => ({
  type: types.WORKSHEET_LAYOUT_CLEAR_DATA,
});


export const syncWorksheetLayoutSuccess  = (data) => ({
  type: types.SYNC_WORKSHEET_LAYOUT.SUCCESS,
  payload: data
})


export const syncWorksheetLayoutFailure  = () => ({
  type: types.SYNC_WORKSHEET_LAYOUT.FAILURE
})

export const syncWorksheetLayoutCollectionSuccess  = (data) => ({
  type: types.SYNC_WORKSHEET_LAYOUT_COLLECTION.SUCCESS,
  payload: data
})

export const apiError  = (data) => ({
  type: types.API_ERROR,
  payload: {
    message: data
  }
})
