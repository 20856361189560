import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import './PrintableWorksheet.css'
import ReactGA from 'react-ga';


/* Paper types
    A5, A5 landscape
    A4, A4 landscape
    A3, A3 landscape
    letter, letter landscape
    legal, legal landscape
*/

const makeCss = () => {
//    const paperType = "A4";
//    const orientation = "landscape"
    const paperType = "letter";
    const orientation = "portrait"
    const marginUnits = "in"
    const marginTop = "0"
    const marginLeft = "0"
    const marginRight = "0"
    const marginBottom = "0"
    const imageHeight = "9.0in"

    /*
        `margin-top: ${marginTop}${marginUnits}; `+
    `margin-bottom: ${marginBottom}${marginUnits}; `+
    `margin-left: ${marginLeft}${marginUnits}; `+
    `margin-right: ${marginRight}${marginUnits}; `+
    */

    let pageStyle=
    `@media print{@page {`+
    `size: ${paperType} ${orientation}; `+
    `margin-top: ${marginTop}${marginUnits}; `+
    `margin-bottom: ${marginBottom}${marginUnits}; `+
    `margin-left: ${marginLeft}${marginUnits}; `+
    `margin-right: ${marginRight}${marginUnits}; `+
    `}`+
    `body { margin: 0.75in}` +
    `}`+
    `@media print{svg {height: ${imageHeight}}}`

    /*
    let pageStyle=
        `@media print{@page {`+
        `size: ${paperType} ${orientation}; `+
        `margin-top: ${marginTop}${marginUnits}; `+
        `margin-bottom: ${marginBottom}${marginUnits}; `+
        `margin-left: ${marginLeft}${marginUnits}; `+
        `margin-right: ${marginRight}${marginUnits}; `+
        `}}`+
        `@media print{svg {height: ${imageHeight}}}`
    */

    return pageStyle;
}

class ComponentToPrint extends Component {
    render() {
        //if (process.env.NODE_ENV === 'development') console.log("Props to print object: ", this.props)
        const TheContent = this.props.content ? this.props.content : null;
      return (
        <div className="hidden-not-print">
             <TheContent />
        </div>
      )
    }
}

const handleBeforePrint = () => {
    ReactGA.event({
        category: 'Worksheets',
        action: 'Printout'
      });
}


export default class PrintableWorksheet extends React.Component {
    render() {
        const trigger = typeof this.props.trigger === "function" ? this.props.trigger : () => <button>Print</button>

        return (
            <div>
            <ReactToPrint
        
                onBeforePrint={handleBeforePrint}
                copyStyles = {false}
                trigger={
                    trigger
                }
                content={() => this.componentRef}
                pageStyle={makeCss()} //"@media print{@page {size: A4 landscape; margin-top: 1in}}" 
            />
            <ComponentToPrint ref={el => (this.componentRef = el)} {...this.props} />
            </div>
        );
    }
  }
