//import { createSelector } from 'reselect'
import {FETCH_STATUS} from '../../constants/store';

export const getAuthStatus = state => {
  return state.auth.status
};

export const getAuthIsReady = state => {
  return (state && state.auth && state.auth.status && state.auth.status === FETCH_STATUS.READY)
};

export const getIsLoggedIn = state => {
  return (state.auth.data.loggedIn === true)
};