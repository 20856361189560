import produce from 'immer'
import { types } from '../actions'
import { FETCH_STATUS } from '../../constants/store'
import { getNewKey } from '../../firebase/getNewKey'
import merge from "lodash/merge";
import {  ProblemBlockSchemav2 as ProblemBlockSchema} from '../schemas';

/* 
    ProblemBlocks are metadata that can be used to 
    generate a block of problems. 
*/

/*
    // Data comes in as an array of these objects
    export const ProblemBlockSchema = struct({
        id: 'string',
        problem_type: 'string',
        generator_settings: 'object',
        problem_count: 'number'
    });
*/

const initialState = {
    status: FETCH_STATUS.UNINITIALIZED, 
    error : null,
    data: {
        problemBlocks : {},  // Dict of blocks, by ID
        keys_to_ids : {},  // Get the ID from the key
    }
}

const reducer = (state = initialState , action) => {
  let theId;  
  let thisBlock;
  let theKey;
  let newState;
  switch (action.type) {
    case types.PROBLEM_BLOCK_SET.REQUEST:
        return {
            ...state,
            status: FETCH_STATUS.PENDING, 
        } 
    case types.PROBLEM_BLOCK_SET.READY:
            return {
                ...state,
                status: FETCH_STATUS.READY, 
            } 
    case types.PROBLEM_BLOCK_SET.SUCCESS:
        /* 
            This manages:
                - Add a totally new ProblemBlock
                - Update an existing ProblemBlock
                - Manage key/id dictionary for ProblemBlocks
                - Validates all ProblemBlock object updates against schema            
        */
         newState = produce(state, draft => {
            //console.log("DRAFT at SUCCESS: ",JSON.stringify(draft))
            // If a key is provided in the action, see if there's an ID for it already
            if (action && action.payload && action.payload.key) {
                theKey = action.payload.key;
                theId = state.data.keys_to_ids[action.payload.key]
            }
            // If no ID yet, see if there's an ID provided in the action
            if (action && action.payload && action.payload.id) 
            {
                theId = action.payload.id
                if (!theKey)
                    theKey = state.data.problemBlocks[theId].key
            }
            
            // If still no ID, create on
            if (!theId) theId = getNewKey()

            // If there already an object in Redux with that ID, get it
            //   Otherwise, make a skeleton object to merge in
            thisBlock = state.data.problemBlocks[theId] || {instances: []};

            // Update the draft object by merging the prior version (or a blank object) to the payload
            //     the merging the ID on top of that
            const newBlock = merge({},thisBlock,action.payload, {id : theId, key: theKey});  //{id: theId, key: theKey})

            // Validate against object schema
            ProblemBlockSchema(newBlock)

            // Save object to draft
            draft.data.problemBlocks[theId] = newBlock 

            // If there's a key, set the key/id dict
            draft.data.keys_to_ids[theKey] = theId
            
            // SUCCESS and READY split into two actions for this one because
            //    we want to wait for the READY action from the ProblemBlockInstance saga
            // draft.status = FETCH_STATUS.READY
            draft.error = null
        })
        //console.log("   newState after update: ",JSON.stringify(newState))
        return newState;
    case types.USER_GOODBYE:
            return initialState
    case types.PROBLEM_BLOCK_CLEAR_DATA:
        return {
            ...initialState,
            status: FETCH_STATUS.READY, 
        }
    // When an INSTANCE is saved, we want to add it to the list of instances for a given Problem Block
    case types.PROBLEM_BLOCK_INSTANCE_SET.SUCCESS:
            const instanceId = action.payload.id;
            // If there's no problemBlockId it's an update to an existing instance
            if (!action.payload.problemBlockId)
                return state;
            const blockId = action.payload.problemBlockId                
            if (state.data.problemBlocks[blockId]["instances"].includes(instanceId)) 
            {
                //console.log("Instance of ProblemBlock is already listed")
                return state;
            }
            // Update the state by adding to the list of BlockID instances
            newState = produce(state, draft => {
                draft.data.problemBlocks[blockId]["instances"].push(instanceId)
            })
            return newState;
    default:
        return state;
  }
}

export default reducer;

