import React from 'react';
import 'typeface-roboto';
import WorksheetComponent from './WorksheetComponent';
import './Worksheet.css';
//import PropTypes from 'prop-types';
//import Interpreter from '@code-dot-org/js-interpreter/interpreter';
//import { withStyles } from '@material-ui/core/styles';
import  DOMPurify  from 'dompurify';

function WorksheetBlock(props) {
//    const { vbWidth = 250, vbHeight = 250, headerHeight, headerRenderer, headerOptions } = props;
    // containerAspectRatio_H_over_W={800/700}
	return (
		<svg width="100%" y={0}>
			<WorksheetComponent  {...props} />
		</svg>
	);
}
//export { WorksheetBlock };


function PageHeader(props) {
    const {pageLayout} = props;

    let src;

    const headerWidth = pageLayout.dimensions["width"] || 700;
    const headerHeight = pageLayout.page_header.height || 150;

    if (!pageLayout.page_header.customSvg) {
        const {
            title,
            title_yPadding,
            name_date_yPadding,
            name_date_outerPadding,
            nameText,
            dateText,
            includeNameText,
            includeDateText
        } = pageLayout.page_header.default_svg_settings;

        const headerText = title || "Worksheet Workshop";
        const padY = name_date_yPadding;
        const padX2 = (headerWidth - 650)/2;
        const padX = name_date_outerPadding;
        const padYheader = title_yPadding;

        src = `<svg width="${(headerWidth).toString(10)}" height="${(headerHeight).toString(10)}" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"> 
            <g class="layer">
                <title>Layer 1</title>
                <text fill="#000000" font-family="serif" font-size="50" id="svg_3" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-width="0" text-anchor="middle" x="${(316.83594 + padX2).toString(10)}" xml:space="preserve" y="${(77.75 + padYheader).toString(10)}">${headerText}</text>`

        if (includeNameText) {
            src = src + 
                `<text fill="#000000" font-family="serif" font-size="20" id="svg_4" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-width="0" text-anchor="middle" x="${(51.5 + padX + padX2).toString(10)}" xml:space="preserve" y="${(128.90625 + padY).toString(10)}">${nameText || "Name:"}</text>
                <line fill="none" id="svg_5" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-width="2" x1="${(87 + padX + padX2).toString(10)}" x2="${(252 + padX + padX2).toString(10)}" y1="${(130.34375 + padY).toString(10)}" y2="${(130.34375 + padY).toString(10)}"/>`
        }
        if (includeDateText) {
            src = src + 
                `<text fill="#000000" font-family="serif" font-size="20" id="svg_8" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-width="0" text-anchor="middle" x="${(430.83 - padX + padX2).toString(10)}" xml:space="preserve" y="${(128.25 + padY).toString(10)}">${dateText || "Date:"}</text>
                <line fill="none" id="svg_7" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-width="2" x1="${(460.33 - padX + padX2).toString(10)}" x2="${(625.33 - padX + padX2).toString(10)}" y1="${(130.34375 + padY).toString(10)}" y2="${(130.34375 + padY).toString(10)}"/>`
        }
        src = src + `  </g></svg>`;
    } else {
        src = pageLayout.page_header.customSvg
    }

        return (
            <svg x={0} 
              width={headerWidth} 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(src) }} /> 
        );
    }



export default class Worksheet extends React.Component {

    /*
	constructor(props) {
        super(props);
//        if (process.env.NODE_ENV === 'development') console.log("Worksheet Props: ",props)
    }*/
    
    

	PageBlocks = (props) => {
        const {blockWidth, blockHeight} = props;
		const { pageLayout } = props;
		//console.log("Pagelayout: ",pageLayout)
		return pageLayout.blocks.map(function(p, index) {
			return <WorksheetBlock key={'block-' + index} blockWidth={blockWidth} blockHeight={blockHeight} {...p} />;
		});
    };

	render() {
		const {pageLayout} = this.props;   
	//	if (process.env.NODE_ENV === 'development') console.log ("Worksheet props",this.props);
        const pageWidth = pageLayout.dimensions["width"] || 700;
        const pageHeight = pageLayout.dimensions["height"] || 900;
        const headerHeight = pageLayout.page_header.height || 150;

		return (
			<svg className={'ww-print-page'} width="100%" viewBox={`0 0 ${pageWidth} ${pageHeight}`}>
                <g>
                    <PageHeader pageLayout={pageLayout} />
                    <svg x={0} y={headerHeight} width={pageWidth} height={pageHeight-headerHeight} id="worksheet-body">
                        <g>
                            <this.PageBlocks blockWidth={pageWidth} blockHeight={pageHeight-headerHeight} pageLayout={pageLayout} />
                        </g>
                    </svg>
                </g>
			</svg>
		);
	}
}

//                     <svg x={0} width={innerWidth} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.headerSrc) }} /> 


/*
const styles = (theme) => ({
	svg: {
		background: 'lightgray'
	},
	labeloutline: {
		fill: 'white',
		stroke: 'black'
	},
	nameText: {
		fontFamily: 'Arial Black',
		fontSize: '18px'
	}
});
*/

