import {getRandomInt, parseRatio} from './utils'
//import {Equation} from './Equation'
//import uuidv4 from 'uuid/v4';
//import {getNewKey} from '../../firebase/getNewKey';
import ProblemGeneratorClass from './generator_wrapper';

export default class Addition extends ProblemGeneratorClass {
  constructor(settings, errorHandler=undefined) {
    const initialSettings = settings || {
      digitsMin: 2,
      digitsMax: 3,
      addition_carrying: "1/2",
      batch_size: 36
    } ;  
    const problemType = "ADDITION";
    super(problemType, initialSettings, _makeBatch, errorHandler); 
  }
}


function _makeBatch(settings) {
  let {digitsMin, digitsMax, addition_carrying, batch_size} = settings;

  let dMin = parseInt(digitsMin, 10)
  let dMax = parseInt(digitsMax, 10)
  // Get the ratio provided into an object containing numerator and denominator as integers
  const addition_carrying_ratio = parseRatio(addition_carrying)

  let orderedProblemArray = []
  let xFinal = ""
  let yFinal = ""
  let carrying;
  for (let i = 0; i < batch_size; i++) {
    // Select the number of digits for "x" and "y"
    //   (this prevents having 100x more chance of getting a 6 digit than a 4 digit number, e.g.)
    let digsX = ((dMin === dMax) ? dMin : getRandomInt(dMin, dMax));
    let digsY = ((dMin === dMax) ? dMin : getRandomInt(dMin, dMax));

    // Decide if we want Carrying for this addition problem
    // So, e.g., if num = 2 and den = 7, we want a pattern like: true, true, false, false, false, false, false to be repeated
    carrying = (i % addition_carrying_ratio.denominator < addition_carrying_ratio.numerator)  

    // Initial X and Y values, which we will adjust for the Carrying constraint
    const x0_str = getRandomInt(Math.pow(10,digsX-1),Math.pow(10,digsX)-1).toFixed(0)
    const y0_str = getRandomInt(Math.pow(10,digsY-1),Math.pow(10,digsY)-1).toFixed(0)
    let x0_prefix = "", x0_suffix=x0_str
    let y0_prefix = "", y0_suffix=y0_str
    if (digsX > digsY){
      x0_prefix = x0_str.substr(0,digsX - digsY)
      x0_suffix = x0_str.substr(digsX - digsY,digsX)
    }
    if (digsY > digsX){
      y0_prefix = y0_str.substr(0,digsY - digsX)
      y0_suffix = y0_str.substr(digsY - digsX,digsY)
    }

    // If we are carrying, decide which digit(s) to make SURE we carry on; the others can be random
    let carry_on = -1
    if (carrying) {
      carry_on = getRandomInt(0,Math.min(digsX, digsY)-1)
    }
    xFinal = ""
    yFinal = ""
    
    // Count backwards from the number of digits of the smaller of X or Y, back to 0
    for (let j = Math.min(digsX, digsY)-1; j >= 0; j--) {
      let x_char_int = parseInt(x0_suffix.substr(j,1),10)
      let y_char_int = parseInt(y0_suffix.substr(j,1),10)

      // If we are supposed to have carrying on this digit and we don't have it, pick a random total 10-18;
      //  then pick a random "x" that can lead to that total; and have "y" make up the rest
      if (carrying && carry_on === j && (x_char_int + y_char_int < 10)) {
        // Select a random total for the 2 digits, 10 to 18
        const v_total = getRandomInt(10,18)
        x_char_int = getRandomInt(v_total - 9, 9)
        y_char_int = v_total - x_char_int
      }

      // If we are NOT supposed to have carrying in this digit, and we do .. pick a total 0 to 9
      if (!carrying && (x_char_int + y_char_int >= 10)) {
        // We have to make sure we don't lose a digit by setting the leftmost digit to "0"
        const xMin = (x0_prefix === "" && j === 0) ? 1 : 0
        const yMin = (y0_prefix === "" && j === 0) ? 1 : 0
        const v_total = getRandomInt(xMin+yMin,9)
        x_char_int = getRandomInt(xMin, v_total-yMin)
        y_char_int = v_total - x_char_int
      }

      // Build the final strings from right to left
      xFinal = x_char_int.toFixed(0).concat(xFinal)
      yFinal = y_char_int.toFixed(0).concat(yFinal)
    }  // end carrying logic
    // Now we need to add parts of the problem that were not included in the "Carrying" loop
    // e.g., if it was 13,567 + 358 we need to put the "!3" on the left of x
    xFinal = x0_prefix + xFinal
    yFinal = y0_prefix + yFinal

    let ai_factors = {
      maxDigits: Math.max(xFinal.length, yFinal.length),
      hasCarrying: carrying,
    }

    let x = parseInt(xFinal,10);
    let y = parseInt(yFinal,10);
    let answer = x + y;

    orderedProblemArray.push (
      {
        data: {
          x: x, 
          y: y, 
          }, 
        answer_data : {
          answer : answer
        },
        ai_factors : ai_factors,
        // Meta data that ProblemDisplayClass may want access to.
        // In this case, to generate problem SVGs with even widths
        //   not based on the max digits of a given problem, but 
        //   for the whole set
        display_factors : 
          {
            problemSet_maxDigits: digitsMax,
          },
      }) 
  }

  return orderedProblemArray;
}