import React from 'react';
import 'typeface-roboto';
import * as constants from '../../constants/mathconstants';
import FormFieldCardRangeSlider from '../MathPages/formwidgets/FormFieldCard_RangeSlider'; 
import FormFieldSelect from '../MathPages/formwidgets/FormFieldSelect';
import {parseRatio} from '../../MathStuff/math_generators/utils';

class Addition extends React.Component {
    constructor(props) {
        super(props);

        //if (process.env.NODE_ENV === 'development') console.log("Addition setup page - props: ",props)
        let digits = [2,3];
        let carrying = constants.ALWAYS_NEVER_MIX.MIX;
        if (typeof props["initSettings"]["digitsMin"]==="number") 
            digits[0] = props["initSettings"]["digitsMin"];
        if (typeof props["initSettings"]["digitsMax"]==="number") 
            digits[1] = props["initSettings"]["digitsMax"];
        
        const carrying_ratio = typeof props.initSettings.addition_carrying === "string" ? props.initSettings.addition_carrying : "1/2";
        const addition_carrying_ratio = parseRatio(carrying_ratio)
        if (addition_carrying_ratio.numerator === 0) 
            carrying = constants.ALWAYS_NEVER_MIX.NEVER;
        if (addition_carrying_ratio.numerator === addition_carrying_ratio.denominator) 
            carrying = constants.ALWAYS_NEVER_MIX.ALWAYS;

        this.state = {
            onChange : props.onChange,
            digits:  digits,
            carrying: carrying,
            batch_size : props.initSettings.batch_size || 36,
          };
    }

    makeSettingsObject = (stateObject) => {
        let settings = {
            digitsMin: stateObject.digits[0],
            digitsMax: stateObject.digits[1],
            addition_carrying: "0",
            batch_size : stateObject.batch_size,
        }

        switch(stateObject.carrying) {
            case constants.ALWAYS_NEVER_MIX.MIX: 
                settings.addition_carrying = "1/2"
                break
            case constants.ALWAYS_NEVER_MIX.ALWAYS:
                settings.addition_carrying = "1"
                break
            default:
                settings.addition_carrying = "0"
        }
        return settings;
    }

    /*
    componentDidUpdate = (prevProps, prevState) => {
        if (typeof this.state.onChange !== "function") {
            return;
        }
        const newSettings = this.makeSettingsObject(this.state);
       // this.state.onChange(newSettings);
        
        if (!isEqual(this.makeSettingsObject(prevState)),newSettings) {
        //    this.onChange(newSettings);
            this.state.onChange(newSettings);
        }   
    }
    */

    callOnChange = () => {
        if (typeof this.state.onChange !== "function") {
            return;
        }
        const newSettings = this.makeSettingsObject(this.state);
        this.state.onChange(newSettings);
    }

    digits_onUpdateValue = (e) => {
        this.setState({
          digits: e,
        }, this.callOnChange);
      }

    digits_detailText = (values) => {
        return "Problems with " + 
            values[0].toString(10)  + 
                ((values[0] === values[1]) ? " digit numbers" : 
                "-" + values[1].toString(10) + " digit numbers")
    }

    carrying_onChange = (newValue) => {
        this.setState({
            carrying: newValue,
          }, this.callOnChange);
    }

    carrying_detailText = (value) => {
        switch(value) {
            case constants.ALWAYS_NEVER_MIX.ALWAYS:
                return "All problems feature carrying"
            case constants.ALWAYS_NEVER_MIX.MIX:
                return "Some problems feature carrying"
            case constants.ALWAYS_NEVER_MIX.NEVER:
                return "Problems do not feature carrying"
            default:
                return null
        }
    }

    // Return Problem Set data from State
    getProblemsetData = () => {
      return  {
        type: constants.EQUATION_TYPES.ADDITION,
        digitRange: this.state.digits,
        carrying: this.state.carrying
    }}

    always_never_mix_selections = [
        {value: constants.ALWAYS_NEVER_MIX.MIX, label: "Sometimes"},
        {value: constants.ALWAYS_NEVER_MIX.ALWAYS, label: "Always"},
        {value: constants.ALWAYS_NEVER_MIX.NEVER, label: "Never"},
    ]
    
    render() {
        return (
            <div key="selection-form">
                <FormFieldCardRangeSlider 
                    {...this.props}
                    key="select-digits"
                    onValuesUpdated={this.digits_onUpdateValue} 
                    detailText={this.digits_detailText} 
                    values={this.state.digits} 
                    cardTitle="Digits?" 
                    min={1} max={6}
                    snap snapPoints={[1, 2, 3, 4, 5, 6]}
                />
                <FormFieldSelect
                    key="select-carrying"
                    value={this.state.carrying} 
                    handleChange={this.carrying_onChange}
                    cardTitle="Carrying?" 
                    detailText={this.carrying_detailText}
                    selectOptions={this.always_never_mix_selections}
                /> 
                </div>        
        );
    }
} 

export default Addition;


