import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faHammer } from '@fortawesome/free-solid-svg-icons'

import { faBinoculars } from '@fortawesome/free-solid-svg-icons'

import * as routes from '../../../constants/routes';
import { withRouter, Redirect } from 'react-router-dom';

const drawerWidth = 250;

const styles = (theme) => {
	return {
		root: {
			display: 'flex'
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0
		},
		drawerPaper: {
			width: drawerWidth
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		toolbar: theme.mixins.toolbar,
		list: {
			width: 250
		},
		fullList: {
			width: 'auto'
		},
		listItemSelected: {
			background:
				'linear-gradient(to right, ' + theme.palette.primary[100] + ',' + theme.palette.primary[100] + ')',
			cursor: 'default'
		},
		listItemRoot: {
			'&:hover': {
				background:
					'linear-gradient(to right, ' + theme.palette.primary[400] + ',' + theme.palette.primary[400] + ')'
			}
		},
		listItemTextSelected: {
			fontWeight: 'bold'
		},
		faIcon: {
      fontSize: 18,
      margin: 0,
      padding:0
			// padding if needed (e.g., theme.spacing(2))
			// margin if needed
		},
		muiIcon: {
      fontSize: 18,
      margin: 0,
      padding:0,
			// padding if needed
			// margin if needed
		}
	};
};

class NavigationDrawer extends React.Component {
	state = {
		redirect: null
	};

	handleGo = (go_to) => {
		this.setState({ redirect: go_to });
	};

	render() {
		const { classes, toggleDrawer, isOpen } = this.props;
		const redirect = this.state.redirect;
		const { pathname } = this.props.location;
		// If there are tabs, the AppBar is taller and we need to make more space for it
		const tabsInHeader = this.props.tabsInHeader || false;

		// Redirect to another page?
		if (redirect) {
			return <Redirect push to={redirect} />;
		}

		// Format a list item in the Navigation Drawer
		const NavListItem = (props) => {
			const { text, icon, navTarget } = props;
			if (navTarget !== pathname) {
				return (
					<ListItem
						button
						key={text}
						onClick={() => {
							this.handleGo(navTarget);
						}}
						selected={navTarget === pathname}
						classes={{ root: classes.listItemRoot }}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={text} />
					</ListItem>
				);
			} else {
				return (
					<ListItem
						button
						key={text}
						selected={navTarget === pathname}
						classes={{ selected: classes.listItemSelected }}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={text} classes={{ primary: classes.listItemTextSelected }} />
					</ListItem>
				);
			}
		};


		const sideList = (
			<div className={classes.list}>
				<List>
					<NavListItem text="Home" icon={<FontAwesomeIcon icon={faHome} className={classes.faIcon} fixedWidth  />} navTarget={routes.HOME} />
				</List>
				<Divider />
				<List>
					<NavListItem text="Addition" icon={<FontAwesomeIcon icon={faPlus} className={classes.faIcon} fixedWidth  />} navTarget={routes.ARITHMETIC_ADD} />
					<NavListItem text="Subtraction" icon={<FontAwesomeIcon icon={faMinus} className={classes.faIcon} fixedWidth  />} navTarget={routes.ARITHMETIC_SUBTRACT} />
					<NavListItem text="Multiplication" icon={<FontAwesomeIcon icon={faTimes} className={classes.faIcon} fixedWidth  />} navTarget={routes.ARITHMETIC_MULTIPLY} />
				</List>
				<Divider />
				<List>
					{
						(process.env.NODE_ENV === 'development') && 
						<NavListItem text="Workshop" icon={<FontAwesomeIcon icon={faHammer} className={classes.faIcon} fixedWidth  />} navTarget={routes.WORKSHOP} />
					}
					<NavListItem text="About" icon={<FontAwesomeIcon icon={faBinoculars} className={classes.faIcon} fixedWidth  />} navTarget={routes.ABOUT} />
				</List>
			</div>
		);

		return (
			<Drawer
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper
				}}
				open={isOpen}
				onClose={toggleDrawer(false)}
			>
				<div tabIndex={0} role="button" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
					<div className={classes.toolbar} />
					{tabsInHeader && <div className={classes.toolbar} />}
					{sideList}
				</div>
			</Drawer>
		);
	}
}

NavigationDrawer.propTypes = {
	classes: PropTypes.object.isRequired
};

NavigationDrawer = withRouter(NavigationDrawer);

export default withStyles(styles)(NavigationDrawer);

/*
					<NavListItem
						text="Terms of Service"
						icon={<FontAwesomeIcon icon={faFileSignature} className={classes.faIcon} fixedWidth  />}
						navTarget={routes.TERMS_OF_SERVICE}
					/>
					*/